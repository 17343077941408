<template>
  <div>
    <div class="association">
      <div class="explainL">
        <span>当前位置：</span>
        <span class="routeL">首页></span>
        <span> 咨询师详情></span>
        <div class="partHot">
          <div class="imgH">
            <img
              :src=" baseUrl+ infor.avatarName"
              alt=""
              class="littleImg"
            />
          </div>
          <div class="worrH">
            <div class="nameH">{{ infor.nickname }}</div>
            <div class="priceH">
              {{ infor.department }}/{{ infor.professionalTitle }}
            </div>
            <div class="priceH2">{{ infor.description }}</div>
            <div
              class="followButton"
              v-show="!infor.collectStatus"
              @click="followHim()"
            >
              关注TA
            </div>
            <div
              class="followButtons"
              v-show="infor.collectStatus"
              @click="leaveHim()"
            >
              取消关注
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="brief">
      <div class="rightPart">
        <div class="headR">更多资料</div>
        <div class="littleTitle">
          <img src="../../assets/img/renzheng.png" alt="" srcset="" />
          <span class="wordLl">平台认证资质</span>
        </div>
        <div class="wordHtml" v-html="infor.qualification"></div>
        <div class="border1"></div>
        <div class="littleTitle">
          <img src="../../assets/img/shanchang.png" alt="" srcset="" />
          <span class="wordLl">擅长领域</span>
        </div>
        <div class="showB">
          <div
            class="buttonY"
            v-for="(item, index) in infor.domainSet"
            :key="index"
          >
            {{ item.domain }}
          </div>
        </div>
        <div class="border1"></div>
        <div class="littleTitle">
          <img src="../../assets/img/shoufei.png" alt="" srcset="" />
          <span class="wordLl">收费标准</span>
        </div>
        <div class="wordSale">
          <span>线下咨询</span><span class="priceW">{{ infor.price }}</span>
        </div>
        <div class="wordSale">
          <span>在线咨询 </span><span class="priceW">会员免费</span>
        </div>
        <div class="border1"></div>
        <div class="littleTitle">
          <img src="../../assets/img/pingtai.png" alt="" srcset="" />
          <span class="wordLl">平台保障</span>
        </div>
        <div class="wordHtml">咨询师资料100%真实</div>
        <div class="wordHtml">来访者隐私安全</div>
        <div class="wordHtml">支持取消预约并退款</div>
        <div class="wordHtml">平台严格审核资质</div>
      </div>
      <div class="leftPart">
        <div class="chooseTitle">
          <div
            class="answers"
            @click="check(1)"
            :class="[type == 1 ? 'active' : '']"
          >
            TA的回答
          </div>
          <div class="dashedLine"></div>
          <div
            class="answers"
            @click="check(2)"
            :class="[type == 2 ? 'active' : '']"
          >
            预约须知
          </div>
        </div>
        <div v-show="type == 1">
          <div class="yuxues" v-for="(item, index) in answerList" :key="index">
            <div class="littleTitle">
              <span class="wordLl11">{{ item.createTime }} 回答了</span>
            </div>
            <div class="word11">
              <div class="title11s">{{ item.problem.title }}</div>
              <div class="littleDetails">
                {{ item.problem.content }}
              </div>
            </div>
          </div>
        </div>

        <div class="yuxue" v-show="type == 2">
          <div class="littleTitle">
            <img src="../../assets/img/yuyue.png" alt="" srcset="" />
            <span class="wordLl1">预约须知</span>
          </div>
          <div class="word11">
            <div class="title11">关于变更预约</div>
            <div class="littleDetail">
              为了保证对咨询认真的态度和效果，咨询需按时到场，如果因为不可抗力需要变更或取消已协商好的咨询预约，请务必提前24小时联络咨询师；否则咨询将如约进行，费用不退。
            </div>
          </div>
          <div class="word12">
            <div class="title11">关于爽约/迟到</div>
            <div class="littleDetail">
              为了保证对咨询认真的态度和效果，咨询需按时到场，如有迟到，按事先约定的时间默认咨询开始并扣费，不予补时。
            </div>
          </div>
        
          <div class="littleTitle">
            <img src="../../assets/img/zixun.png" alt="" srcset="" />
            <span class="wordLl1">咨询预约</span>
          </div>
          <div class="word11">
            <div class="title11">
              工作时间: {{ infor.duration }}
              <span class="tips"
                >（如要进行线下预约，请咨询客服约定具体时间）</span
              >
            </div>
            <div class="title11">
              线下预约:
              <img
                src="../../assets/img/kefu.png"
                alt=""
                class="kefuImg"
                @click="haveTalk()"
              /><span class="wordHotline">热线咨询：</span
              ><span class="phoneNum">{{ infor.phone }}</span>
            </div>
          </div>
          <div class="word11">
            <div class="title11">
              工作时间: {{ infor.duration }}
              <span class="tips"
                >（如要进行线下咨询，请扫二维码）</span
              >
            </div>
            <div class="title11">
              在线咨询：
              <img src="../../assets/img/qrcode.png" alt="" class="kefuImg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //弹窗聊天界面 -->
    <div class="chat-box show-netcall-box" id="chatBox" v-show="chatShow">
      <div class="title" id="chatTitle">
        <img
          :src="baseUrl + infor.avatarName"
          width="56"
          height="56"
          class="radius-circle img"
          id="headImg"
        />
        <span id="nickName">{{ infor.nickname }}</span>
        <div
          class="cloudMsg tc radius4px"
          data-record-id=""
          id="cloudMsg"
          @click="closeChat()"
        >
          关闭
        </div>
      </div>
      <div class="netcall-box" id="netcallBox">
        <div class="netcall-mask hide">
          <div class="netcallTip"></div>
        </div>
        <div class="top hide">
          <!-- <span class="transferAudioAndVideo switchToAudio" id="switchToAudio">切换音频</span>
							<span class="transferAudioAndVideo switchToVideo" id="switchToVideo">切换视频</span> -->
          <span
            class="fullScreenIcon toggleFullScreenButton"
            id="toggleFullScreenButton"
            title="切换全屏"
            >&nbsp;</span
          >
        </div>
      </div>
      <div id="whiteboard"></div>
      <div class="chat-content box-sizing" id="chatContent" ref="middle">
        <!-- 聊天记录 -->
        <div
          class="item"
          v-for="(item, index) in chatInfo"
          :key="index"
          :class="item.flow == 'out' ? 'item-me' : 'item-you'"
        >
          <img
            src="../../assets/img/default-icon.png"
            alt=""
            class="img j-img"
          />
          <div class="msg msg-text j-msg">
            <div class="box">
              <div class="cnt">
                <div class="f-maxWid" v-if="item.file == null">
                  {{ item.content }}
                </div>
                <img :src="item.file.url" alt="" v-if="item.file != null" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="chat-editor box-sizing" id="chatEditor" data-disabled="1">
        <input
          v-model="sendMeesage"
          id="messageText"
          class="chat-btn msg-input box-sizing radius5px p2p"
          rows="1"
          autofocus="autofocus"
          maxlength="500"
          placeholder="写下您的问题..."
        />
        <span class="chat-btn msg-type" id="chooseFileBtn">
          <input
            type="file"
            style="display: none"
            ref="img"
            @change="sendImagemessage"
            id="fileId"
          />
          <a class="icon icon-file" type="file" @click="choiceImg">
            <img src="../../assets/img/submitP.png" alt=""
          /></a>
        </span>

        <a class="radius5px absule" id="sendBtn" @click="sendmessage">
          <img src="../../assets/img/sendMessage.png" alt="" class="sendImg"
        /></a>
        <form action="#" id="uploadForm">
          <input
            multiple="multiple"
            type="file"
            name="file"
            id="uploadFile"
            class="hide"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SDK from "../../../NIM_Web_NIM_v9.2.0";
import { getUrl } from "../../utils";

export default {
  name: "index",
  data() {
    return {
      chatShow: false,
        baseUrl:getUrl(),
      id: "",
      infor: {},
      hotList: [],
      consultList: [],
      keyWord: "",
      oldPass: "",
      newPass: "",
      page: 1,
      userId: "",
      pageIndex: 1,
      pageSize: 5,
      pageTotal: 0,
      sendMeesage: "",
      newPassCopy: "",
      account: "",
      token: "",
      chatInfo: [],
      answerList: [],
      type: "2",
      userids: this.$cookie.get("useridList"),

      loginState: false,
    };
  },
  created() {
    this.getData();
    // this.initSDK();
  },
  methods: {
    initSDK() {
      this.nim = SDK.getInstance({
        debug: true,
        //appkey固定
        appKey: "ece4c0440f2dcb27b2d6e99c2ce53152",
        //账号
        account: this.account,
        token: this.token,
        // privateConf: {}, // 私有化部署方案所需的配置
        onconnect: this.onConnect, // 链接已经建立(登录成功)
        onwillreconnect: this.onWillReconnect,
        ondisconnect: this.onDisconnect,
        onerror: this.onError,
        // 用户名片
        onmyinfo: this.onMyInfo,
        onupdatemyinfo: this.onUpdateMyInfo,
        onusers: this.onUsers,
        onupdateuser: this.onUpdateUser,
        // // 会话
        // syncSessionUnread: true,
        onsessions: this.onSessions,
        onupdatesession: this.onUpdateSession,
        // 同步完成
        onsyncdone: this.onSyncDone,
        onofflinemsgs: this.onOfflineMsgs,
      });
    },
    followHim() {
      if (this.loginState == true) {
        this.$http({
          url: this.$http.adornUrl("/consultDoctorCollect/add"),
          method: "post",
          data: this.$http.adornData({
            consultDoctorId: this.userId,
            userId: this.userids,
          }),
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message.success("关注成功");
            this.infor.collectStatus = true;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("请先登录账户");
      }
    },
    leaveHim() {
      this.$http({
        url: this.$http.adornUrl("/consultDoctorCollect/del"),
        method: "post",
        data: this.$http.adornData({
          consultDoctorId: this.userId,
          userId: this.userids,
        }),
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("取关成功");
          this.infor.collectStatus = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    closeChat() {
      this.chatShow = false;
    },
    onUpdateSession() {},
    haveTalk() {
      if (this.loginState == true) {
        window.scrollTo(0, 0);
        this.chatShow = true;
        this.nim.getHistoryMsgs({
          scene: "p2p",
          to: this.infor.accid,
          asc: true,
          done: getHistoryMsgsDone,
        });
        const that = this;
        function getHistoryMsgsDone(error, obj) {
          console.log(obj);
          if (!error) {
            console.log("获取历史记录", obj.msgs);
            const msgs = obj.msgs;
            that.chatInfo = msgs;
            that.$nextTick(() => {
              let middle = that.$refs["middle"];
              middle.scrollTop = middle.scrollHeight;
            });
            // that.$refs.common_chat.goEnd();
          }
        }
      } else {
        this.$message.error("请先登录账户");
      }
    },
    sendmessage() {
      var that = this;
      that.nim.sendCustomMsg({
        scene: "p2p",
        to: this.infor.accid,
        content: that.sendMeesage,
        done: function sendMsgDone(error, msg) {
          console.log(msg);
          that.chatInfo.push(msg);
          that.sendMeesage = "";
          that.$nextTick(() => {
            let middle = that.$refs["middle"];
            middle.scrollTop = middle.scrollHeight;
          });
        },
      });
    },
    onSessions(sessions) {
      console.log("ceshi");
      console.log("收到会话列表", sessions);
      // 这里使用sdk自带的方法合并会话列表重新赋值
    },
    choiceImg() {
      this.$refs.img.dispatchEvent(new MouseEvent("click"));
    },
    sendImagemessage(file) {
      var that = this;
      that.nim.sendFile({
        scene: "p2p",
        to: this.infor.accid,
        type: "image",
        /* eslint-disable */
        fileInput: fileId,
        done: function sendMsgDone(error, msg) {
          console.log(msg);
          that.chatInfo.push(msg);
          that.$nextTick(() => {
            let middle = that.$refs["middle"];
            middle.scrollTop = middle.scrollHeight;
          });
        },
      });
    },
    getData() {
      console.log(this.$cookie.get("useridList"))
      if (this.$cookie.get("useridList") == ''||this.$cookie.get("useridList") == null) {
        this.loginState = false;
      } else {
        this.loginState = true;
      }
      this.id = this.$route.query.id;
      this.userId = this.$route.query.userId;
      this.userIds = this.$cookie.get("useridList");
      console.log(this.$cookie.get("useridList"));
      this.$http({
        url: this.$http.adornUrl("/consultDoctor/getById"),
        method: "get",
        params: this.$http.adornParams({
          userId: this.userIds,
          id: this.id,
        }),
      }).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.infor = res.data.data;
        } else {
        }
      });
      this.$http({
        url: this.$http.adornUrl("/problemAnswer/listById"),
        method: "get",
        params: this.$http.adornParams({
          doctorId: this.userId,
        }),
      }).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.answerList = res.data.data.content;
        } else {
        }
      });
      this.$http({
        url: this.$http.adornUrl("/neteaseUser/getUser"),
        method: "get",
      }).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.account = res.data.data.accid;
          this.token = res.data.data.token;
          this.initSDK();
        } else {
        }
      });
    },
    gotodetail(item) {
      this.$http({
        url: this.$http.adornUrl("/consultDoctor/getById"),
        method: "get",
        params: this.$http.adornParams({
          id: item.id,
          userId: this.userIds,
        }),
      }).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
        } else {
        }
      });
    },
    handlePageChange(val) {
      this.page = val;
      this.getData();
    },
    check(data) {
      this.type = data;
    },
    changePassWord() {
      if (this.oldPass == "" || this.newPass == "" || this.newPassCopy == "") {
        this.$message.error("请输入密码");
      }
    },
  },
};
</script>

<style scoped>
.chat-box {
  background-color: #fff;
  position: absolute;
  top: 18%;
  left: 20%;
  box-shadow: 0px 4px 4px 1px rgba(14, 96, 219, 0.01);
}
.imgPart {
  float: left;
  margin-left: 22px;
  margin-top: 22px;
  width: 135px;
  height: 178px;
}
.wordPart {
  float: left;
  margin-left: 20px;
  width: 623px;
  height: 200px;
  margin-top: 22px;
}
.wordT {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  width: 92%;
  float: left;
  margin-top: 4px;
}
.doMain {
  width: 92%;
  float: left;
  margin-top: 20px;
  margin-left: -18px;
}
.domianItem {
  width: 83px;
  height: 32px;
  background: #f6f6f6;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
  text-align: center;
  float: left;
  margin-left: 18px;
}
.title {
    height: 74px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 0px 0px;
  line-height: 80px;
  color: #666;
  position: relative;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -moz-border-top-left-radius: 5px;
}
.title img {
  width: 56px;
  height: 56px;
  border: none;
  float: left;
  margin: 12px 10px 0 14px;
}
.wordJ {
  width: 92%;
  float: left;
  margin-top: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #222;
  line-height: 19px;
}
.nameWord {
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #1f7eec;
  width: 92%;
  float: left;
}
.morePart {
  width: 28px;
  height: 28px;
  background: #0ec2db;
  border-radius: 28px;
  opacity: 1;
  float: left;
  color: #fff;
  text-align: center;
  line-height: 28px;
  font-size: 20px;
}
.partConsult {
  width: 821px;
  height: 219px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-top: 22px;
}
.pagination {
  float: left;
  clear: both;
  margin-top: 20px;
  margin-left: 850px;
}
.yuxue {
  width: 859px;
  height: 632px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-top: 28px;
}
.yuxues {
  width: 859px;
  height: auto;
  padding-bottom: 24px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-top: 28px;
}
.answers {
  cursor: pointer;
  width: 48%;
  float: left;
  height: 67px;
  line-height: 67px;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.dashedLine {
  height: 31px;
  border: 1px dashed #e5e5e5;
  width: 0px;
  float: left;
  margin-top: 18px;
}
.chooseTitle {
  width: 296px;
  height: 67px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(14, 96, 219, 0.01);
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
}
.leftPart {
  width: 821px;
  min-height: 537px;

  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-left: 28px;
  float: left;
  margin-top: 22px;
}
.partHot {
  width: 1200px;
  height: 200px;
  margin-left: 18px;
}
.nameH {
  width: 100%;
  height: 40px;
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}
.lookDetail {
  width: 30%;
  height: 26px;
  float: left;
  margin-left: 10%;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #0ec2db;
  cursor: pointer;
}
.worrH {
  width: 840px;
  height: 200px;
  float: left;
  margin-left: 80px;
}
.priceH {
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;

  float: left;
}
.followButton {
  width: 88px;
  height: 32px;
  background: #f5c342;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #f5c342;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
  float: left;
  margin-top: 16px;
  cursor: pointer;
}
.followButtons {
  width: 88px;
  height: 32px;
  background: #ccc;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
  float: left;
  margin-top: 16px;
  cursor: pointer;
}
.priceH2 {
  width: 100%;
  height: 40px;
  height: 48px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 19px;
  margin-top: 7px;
  float: left;
}
.imgH {
  width: 160px;
  height: 160px;
  line-height: 100px;
  float: left;
  margin-left: 8px;
  margin-top: 20px;
}
.littleImg {
  width: 156px;
  height: 156px;
  border-radius: 156px;
}
.wordLl {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-left: 9px;
}
.tips {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-left: 20px;
}
.kefuImg {
  cursor: pointer;
  margin-left: 10px;
}
.wordHotline {
  margin-left: 150px;
}
.phoneNum {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666;
}
.title11 {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 10px;
}
.title11s {
  font-size: 20px;
  font-family: Abel-Regular, Abel;
  font-weight: 400;
  color: #444444;
  margin-top: 10px;
}
.littleDetail {
  width: 751px;
  height: 48px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin-top: 10px;
}
.littleDetails {
  width: 751px;
  height: auto;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 21px;
  margin-top: 10px;
  text-indent: 2rem;
}
.word11 {
  margin-top: 10px;
  width: 751px;
  height: auto;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-left: 54px;
}
.word12 {
  margin-top: 24px;
  width: 751px;
  height: 77px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-left: 54px;
}
.wordLl1 {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-left: 10px;
}
.wordLl11 {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-left: 10px;
}
.wordHtml {
  width: 277px;
  height: auto;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  opacity: 1;
  margin-left: 16px;
  padding-left: 34px;
}
.border1 {
  width: 277px;
  height: 0px;
  border-bottom: 1px solid #e5e5e5;
  margin-left: 16px;
  margin-top: 20px;
}
.showB {
  width: 277px;
  height: 46px;
  margin-left: 16px;
}
.buttonY {
  width: 83px;
  height: 32px;
  background: rgba(245, 195, 66, 0.2);
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #f5c342;
  line-height: 32px;
  text-align: center;
  float: left;
  margin-left: 16px;
}
.wordSale {
  width: 207px;
  height: 24px;
  opacity: 1;
  margin-left: 50px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.priceW {
  margin-left: 20px;
}
.littleTitle {
  width: 277px;
  height: 58px;
  line-height: 58px;
  opacity: 1;
  margin-left: 16px;
}
.headR {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
  letter-spacing: 1px;
  width: 277px;
  height: 58px;
  line-height: 58px;
  opacity: 1;
  margin-left: 16px;
  border-bottom: 1px solid #e5e5e5;
}
.rightPart {
  width: 309px;
  height: 724px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin-top: 22px;
  margin-left: 32px;

  float: left;
}
.searchPart {
  width: 477px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;

  float: left;
  margin-left: 160px;
  margin-top: 20px;
}
.sexWord {
  margin-left: 32px;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  float: left;
  margin-top: 26px;
}
.sexButton {
  width: 86px;
  height: 32px;
  background: rgba(229, 229, 229, 0.5);
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  float: left;
  margin-top: 22px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-left: 18px;
  cursor: pointer;
}
.headTitle {
  width: 1200px;
  height: 76px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  background: #ffffff;
}
.association {
  width: 100%;
  height: 260px;
  background-image: url(../../assets/img/detailH.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.explainL {
  height: 50px;
  line-height: 50px;
  width: 1080px;
  margin: 0 auto;
  text-align: left;
  color: #fff;
}
.routeL {
  cursor: pointer;
}
.brief {
  width: 1200px;
  margin: 20px auto 76px;
  overflow: hidden;
  padding: 0;
}
.gutter-box {
  background: #ffffff;
}
.gutter-box > ul > li {
  height: 44px;
  line-height: 44px;
  cursor: pointer;
}
.active {
  color: #0ec2db;
}
.boxL {
  padding: 15px;
}
.mainPart {
  width: 80%;
  margin-left: 8%;
  height: 420px;
  margin-top: 30px;
}
.partOne {
  width: 60%;
  height: 30px;

  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
  margin-top: 18px;
}
.partOne2 {
  width: 60%;
  margin-top: 10px;
  height: 40px;

  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.title {
  overflow: hidden;
  border-bottom: 1px solid #e5e5e5;
}
.title > h3 {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: #0ec2db;
  float: left;
  height: 40px;
  display: inline-block;
  border-bottom: 2px solid #0ec2db;
  margin-bottom: 0px;
}
article > p {
  line-height: 24px;
  text-align: left;
  text-indent: 30px;
}
.uls {
  text-align: center;
}
.xh {
  font-size: 18px;
  font-weight: 400;
  color: #0e60db;
}
.jj {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.jjs {
  border-bottom: 1px solid #e5e5e5;
}
.jj h3 {
  text-indent: 24px;
}
.jj p {
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  margin-bottom: 5px;
  text-indent: 24px;
}
.imgR {
  width: 135px;
  height: 178px;
  margin-right: 10px;
}
.imgXX {
  margin-top: -5px;
  margin-right: 5px;
}
</style>