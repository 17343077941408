<template>
    <div>
        <div class="association">
            <div class="explainL">
                <div class="explainL">
                <span>当前位置：</span>
                <span class="routeL">在线考试</span>
            </div>
            </div>
        </div>
        <div class="brief">
            <a-row :gutter="20">
                <a-col class="gutter-row gutter-box1" :span="3">
                    <div class="gutter-box ">
                        <ul class="uls">
                            <li :class="[type == 1 ? 'active':'']" @click="check('1')"> <img v-show="type == 1"  src="../../assets/img/Frame2.png" alt=""><img v-show="type == 2" src="../../assets/img/Frame4.png" alt="">当前考试</li>
                            <!-- <li :class="[type == 2 ? 'active':'']"  @click="history1('2')"> <img v-show="type == 1"  src="../../assets/img/Frame(1).png" alt=""><img v-show="type == 2" src="../../assets/img/Frame(3).png" alt="" ><span>历史考试</span> </li> -->
                        </ul>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="21">
                    <div class="exam boxL"  v-show="type == 1">
                        <div class="rule" v-for="(v,index) in PaperList" :key="index">
                            <p style="font-size:16px">{{v.name}}</p>
                            <p style="font-size:13px;margin-top: 20px; ">开始时间：{{v.startTime}}</p>
                            <p class="font">结束时间：{{v.endTime}}</p>
                            <p class="font">考试时长: {{v.duration}}分钟</p>
                            <div class="start" @click="dialogVisible1(v)">开始考试</div>
                        </div>
                    </div>
                    <div class="gutter-box boxL" v-show="type == 2">
                        <div class="exam" style="display:flex;flex-wrap: wrap;">
                          <div class="rules" >
                            <div class="rulel" v-for="(v,index) in PaperLists" :key="index" @click="history(v)">
                            <p style="font-size:16px">{{v.name}}</p>
                            <p style="font-size:13px;margin-top: 20px; ">开始时间：{{v.startTime}}</p>
                            <p class="font">结束时间：{{v.endTime}}</p>
                            <p class="font">考试时长：{{v.duration}}分钟</p>
                            <p class="font">得&#x3000;分&#x3000;：{{v.score}}分</p>
                            <div class="start">查看详情</div>
                            <div class="bgimg" v-show="v.score>=60">
                            <img src="../../assets/img/Rectangle 3048(1).png" alt="">
                            <p class="p">已通过</p>  
                            </div>
                            <div class="bgimg" v-show="v.score<60">
                            <img src="../../assets/img/Rectangle 3048(2).png" alt="">
                            <p class="p">未通过</p>  
                            </div>
                        </div>
                          </div>
                       
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
    export default {
        name: "index",
        data(){
            return{
                type:1,
                dialogVisible: false,
                PaperList:[],
                PaperLists:[],
                paperId:"",
                id:"",
                testId:"",
                testId1:"",
                typeData:this.$route.query.type,
            }
        },
        mounted(){
           this.DonePaper()
            // this.getType()
        },
        methods:{
            check(data){
                this.type = data;
                this.$router.push({ name: "examination" });
            },
            dialogVisible1(v){
                this.$router.push({ name: "paper" ,query:{
                    id:v.paperId,
                    testId1:v.id
                }});
            },
            history(v){
                this.$router.push({ name: "historypapers",query:{
                    paperId:v.paperId,
                    testId:v.id
                } });
            },
            getType(){
                if(this.typeData){
                    this.type = this.typeData
                    if(this.typeData == 2){
                        this.history1();
                    }
                }
            },
            DonePaper(){
                this.$http({
                    url: this.$http.adornUrl('/testUser/listByUndone'),
                    method: 'get',
                }).then((res) => {
                    console.log(res);
                   this.PaperList = res.data.data.list
                   this.PaperList.forEach(v=>{
                    this.paperId = v.paperId
                    this.testId1 = v.id
                 
                   })
               
                });
            },
            history1(){
                this.$http({
                    url: this.$http.adornUrl('/testUser/listByDone'),
                    method: 'get',
                }).then((res) => {
                    this.type = 2;
                   this.PaperLists = res.data.data.list
                });
            }
        }
    }
</script>

<style scoped>
    .association{
        background: #ffffff;
    }
    .explainL{
        height: 50px;
        line-height: 50px;
        width: 1080px;
        margin: 0 auto;
        text-align: left;
    }
    .routeL{
        cursor: pointer;
    }
    .brief{
        width: 1080px;
        margin: 20px auto 76px;
    }
    .exam{
        width: 896px;
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        flex-wrap: wrap;
        
    }
    .gutter-box1{
        background: #ffffff;
        
    }
    .gutter-box>ul>li{
        height: 44px;
        line-height: 44px;
        cursor: pointer;
    }
    .start{
        width: 100px;
        height: 30px;
        margin: 0 auto;
        border-radius: 50px;
        line-height: 30px;
        color: white;
        background: #10C1DC;
        text-align:center;
        margin-top:20px;
        /* font-weight:600 */

    }
    .active{
        font-weight: 400;
        color: #0EC2DB;
    }
    .boxL{
        padding: 0 10px;
    }
    article>p{
        line-height: 24px;
        text-align: left;
        text-indent:30px
    }
    .uls{
        margin-top: 10px;
        text-align: center;
    }
    .xh{
        font-size: 18px;
        font-weight: 400;
        color: #0E60DB;
    }
    .jj{
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    .jjs{
        border-bottom: 1px solid #E5E5E5;
    }
    .jj h3{
        text-indent:24px
    }
    .jj p{
        font-size: 14px;
        font-weight: 400;
        color: #444444;
        margin-bottom: 5px;
        text-indent:24px
    }
    .imgR{
        width: 135px;
        height: 178px;
        margin-right: 10px;
    }
    .imgXX{
        margin-top: -5px;
        margin-right: 5px;
    }
    .rule{
        width: 260px;
        height: 220px;
        padding: 20px;
        /* border: 1px solid #BDBDBD; */
        margin-bottom: 20px;
        margin-right: 20px;
        background:#fff;
        border-radius: 10px;
        cursor: pointer;
    }
    .rulel{
        width:285px;
        height: 250px;
        padding: 20px;
        background:#fff;
        /* margin-right: 20px; */
        margin-bottom: 20px;
        margin-right: 10px;
        cursor: pointer;
        position: relative;

    }
    .rules{
        width: 896px;
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        flex-wrap: wrap;
    }
    
   
    .bgimg{
        width: 59px;
        height: 56px;
        color: white;
        position: absolute;
        top: 0;
        right:0
        
    }
    .font{
        font-size:13px;
        margin-top: 10px
    }
    .p{
        height: 20px;
        position: absolute;
        top: 12px;
        left: 9px;
    }
    .bg1{
        background:red;
    }
</style>