<template>
    <div>
        <div class="association">
            <div class="explainL">
                <span>当前位置：</span>
                <span class="routeL">首页></span>
                <span>协会动态</span>
            </div>
        </div>
        <div class="brief">
            <h3>{{title}}</h3>
            <div class="time">{{createTime}}</div>
            <article v-html="content">

            </article>
            <div style="margin-bottom: 30px;">
                <a v-show="enclosure" :href="enclosure">下载链接</a>
            </div>
            <div v-if="prevArticleId > 0" class="art artL" @click="detailList(prevArticleId)">
                <span>上一篇：{{prevArticle}}</span>
            </div>
            <div v-else  class="art artL">
                没有上一篇
            </div>
            <div v-if="nextArticleId > 0" class="art artR" @click="detailList(nextArticleId)">
                <span>下一篇：{{nextArticle}}</span>
            </div>
            <div v-else class="art artR">
                没有下一篇
            </div>
        </div>
    </div>
</template>

<script>
    import { getQueryVariable } from "../../utils";

    export default {
        name: "index",
        data(){
            return{
                id:getQueryVariable('id'),
                title:'',
                createTime:'',
                content:'',
                nextArticle:'',
                prevArticle:'',
                nextArticleId:null,
                prevArticleId:null,
                enclosure:null,
            }
        },
        mounted() {
            this.getData(this.id)
        },
        methods:{
            //首次默认获取
            getData(id){
                //获取协会动态
                this.$http({
                    url: this.$http.adornUrl('/psychologyArticle/getById'),
                    method: 'get',
                    params: this.$http.adornParams({
                        'id': id,
                    })
                }).then((res) => {
                    if ( res.data.code == 200) {
                        this.title = res.data.data.title;
                        this.createTime = res.data.data.createTime;
                        this.content = res.data.data.content;
                        if(res.data.data.nextArticle){
                            this.nextArticle = res.data.data.nextArticle.title;
                            this.nextArticleId = res.data.data.nextArticle.id;
                        }else{
                            this.nextArticle = '';
                            this.nextArticleId = null;
                        }
                        if(res.data.data.prevArticle){
                            this.prevArticle = res.data.data.prevArticle.title;
                            this.prevArticleId = res.data.data.prevArticle.id;
                        }else{
                            this.prevArticle = '';
                            this.prevArticleId = null;
                        }
                        if(res.data.data.enclosure){
                            this.enclosure = res.data.data.enclosure;
                        }else{
                            this.enclosure = null;
                        }
                    }
                });
            },
            detailList(id){
                this.$router.push({ path: "/detail/index", query: { id: id } });
                this.getData(id);
            },
        }
    }
</script>

<style scoped>
    .art{
        display: inline-block;
    }
    .artL{
        float: left;
    }
    .artR{
        float: right;
    }
    .art>span{
        display: inline-block;
        width: 300px;
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis;/*隐藏后添加省略号*/
        white-space: nowrap;/*强制不换行*/
        cursor: pointer;
    }
    .association{
        background: #ffffff;
    }
    h3,.time{
        text-align: center;
    }
    .explainL{
        height: 50px;
        line-height: 50px;
        width: 1080px;
        margin: 0 auto;
        text-align: left;
    }
    .routeL{
        cursor: pointer;
    }
    .brief{
        width: 1080px;
        margin: 20px auto 76px;
        background: #ffffff;
        padding: 15px 15px 30px 15px;

    }
    .brief>h3{
        font-size: 20px;
        font-weight: 400;
        color: #000000;
    }
    .time{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
    }
    article{
        border-bottom: 1px solid #E8E8E8;
        /*margin-bottom: 30px;*/
    }
    article>p{
        text-indent:24px
    }
    article>p>img{
        margin: 0 auto;
    }
</style>