<template>
   <div>
    <div class="association">
            <div class="explainL">
                <span>当前位置：</span>
                <span class="routeL">首页 > </span>
                <span>学术年会 > </span>
                <span>活动通知 ></span>
            </div>
        </div>
        <div class="cont">
            <a-row :gutter="20">
                <a-col class="gutter-row" :span="24">
                    <div class="title">(中国) 华东心理卫生联盟学术交流会暨2022年安徽省心理卫生协会学术年会论文征集活动
                        <p>2023-02-01</p>
                    </div>
                   <div class="fenxiang">
                    <div style="float:right">
                        <span>分享至： &nbsp;</span>
                        <img src="../../assets/img/wbfx.png" alt=""> 
                        <span> &nbsp;微博 &nbsp;&nbsp;&nbsp; </span>
                        <img src="../../assets/img/wxfx.png" alt="">
                        <span> &nbsp;微信</span>
                    </div>
                   </div>
                </a-col>
                <a-col>
                    &nbsp;
                    <div style="border-bottom:1px solid #F0EDF0"></div>
                </a-col>
                <div class="boxL">
                    <article>
                    <p style="margin-top: 15px;">
                        为确保本次会议的学术水平，大会拟向各领域优秀学者征集会议报告、论文与会中工作坊(需填写工作坊申请书，包括:题目、讲者简介、 工作坊内容和形式)， 征文主题围绕会议主题，大会将组织专家对提交的论文进行筛选，并组织进行学术交流。会议将对入选的论文装订成论文集，优秀的论文将颁发获奖证书。</p>
                    <p>论文征集要求如下:</p>  
                    <p>1.论文篇幅</p>  
                    <p>中文论文篇幅控制在5000-10000字之间，同时附中英文双语摘要，中文摘要200-300字，英文摘要不超过600字(摘要结构要求:研究论文摘要需按照“目的、方法、结果、结论”的格式提交;综述及其它类型文章摘要需简明扼要地阐明主要观点)。</p>
                    <p>2、论文格式，</p>
                    <p>2.1题目，署名及层次格式、文字、字数要求</p>
                    <p>1)文稿采用A4幅面word文档，建议采用1.5倍行距编排。论文标题为小1号黑体，中文小标题为三号宋体，正文为小四号仿宋体,摘要.关键词、参考文献均为小四号仿宋;页脚应加注页码。</p>
                    <p>2)题目居中，署名及单位、城市、邮编标在题目下.</p>
                    <p>3)摘要和关键词，写在题目下、正文前。</p>
                    <p>4)论文的层次，要求统一。</p>
                    <p>2.2文稿和图稿及其他要求</p>
                    <p>1)准确使用标点符号，注意:标题、图题、表名后及公式后不用标点;阿拉伯数字的起止(范围)号用“-”,如:“20-30”，8%-10%”。</p>
                    <p>2)对正文中的某些问题需加以说明时，可用"呼应注”(也叫脚注) ，即在所要加注处的右上角标注“①、②....，同时在本页末留出位置，划一横线与正文隔开，在横线下注明"①、②......</p>
                    <p>3)外文字母注意将大小写分清楚。</p>
                    <p>4)数字(统计数、各种计量及图表编号等各种顺序号)均用阿拉伯数字，世纪、年代。月日和时刻均用阿拉伯数字,并一概用全称。</p>
                    <p>5)表格、公式、样图均要加编号，每篇论文加注流水号，例如：图1、图2，表1、表2。</p>
                    <p>6)照片要求清晰、层次分明，电子文件应为jpg和tif格式。</p>
                    <p>7)参考文献的项目要列全，例如: [1]某某某某某,等.文献名称.杂志名称.2015,13(10):947-953.</p>
                    <p>3.其他要求</p>
                    <p>3.1来稿请在文中注明作者姓名、工作单位、通讯地址、电子邮箱、 联系电话。文责自负。</p>
                    <p>3.2文章最后需附上第一作者简短的个人简历(不超过200字) .</p>
                    <p>3.3来稿均不予退稿，请自留底稿。</p>
                    <p>参加论文征集的个人和单位务必在2023年2月28日之前将论文.申报表及相关材料电子版发送至协会秘书处邮箱ahxh2018@126.com，标题格式为: 2022 年年会论文+作者单位名称+姓名；已发送过的不需要重新发送。</p>
                </article>
                </div>
                
            </a-row>
        </div>
   </div>
 </template>
 
 <script>
     export default {
        data(){
            return { 
                radio:3
            }
        }
 };
 </script>
 
 <style scoped>
 *{
    padding: 0;
    margin: 0;
 }
 
 .association{
        background: #ffffff;
    }
    .explainL{
        height: 50px;
        line-height: 50px;
        width: 1080px;
        margin: 0 auto;
        text-align: left;
    }
    .routeL{
        cursor: pointer;
    }
   .cont{
        width: 1080px;
        margin: 20px auto;
        background: #ffffff;
        padding:50px 20px;
    }
    .title{
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
    .title>p{
        margin-top: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #7A777B;
    }
    .boxL{
        padding: 0px 40px;
      
    }
    article>p{
        line-height: 24px;
        text-align: left;
        text-indent:30px
        
    }
 </style>
 