<template>
  <div>
    <div class="association">
      <div class="explainL">
        <span>当前位置：</span>
        <span class="routeL">首页></span>
        <span>心理咨询></span>
      </div>
    </div>
    <div class="brief">
      <div class="headTitle">
        <div class="sexWord">性别</div>
        <div
          class="sexButton"
          :class="[type == 1 ? 'active' : '']"
          @click="check('1')"
        >
          <img class="imgXX" :src="[type == 1 ? man2 : man]" alt="" />
          <span>男</span>
        </div>
        <div
          class="sexButton"
          :class="[type == 2 ? 'active' : '']"
          @click="check('2')"
        >
          <img class="imgXX" :src="[type == 2 ? woman2 : woman]" alt="" />
          <span>女</span>
        </div>
        <div
          class="sexButton"
          :class="[type == '' ? 'active' : '']"
          @click="check('3')"
        >
          <span>不限</span>
        </div>
        <div class="searchPart">
          <el-input placeholder="请输入关键词搜索" v-model="keyWord">
            <i
              slot="prefix"
              class="el-input__icon el-icon-search"
              @click="search()"
            ></i>
          </el-input>
        </div>
      </div>
      <div class="leftPart">
        <div
          class="partConsult"
          v-for="(item, index) in consultList"
          :key="index"
        >
          <div class="imgPart">
            <img
              :src="baseUrl+ item.photo"
              alt=""
              class="imgHHH"
            />
          </div>
          <div class="wordPart">
            <div class="nameWord">{{ item.nickname }}</div>
            <div class="morePart" @click="gotodetail(item)"></div>
            <div class="wordT">
              {{ item.department }}/{{ item.professionalTitle }}
            </div>
            <div class="wordJ">{{ item.description }}</div>
            <div class="doMain">
              <div
                class="domianItem"
                v-for="(items, indexs) in item.domainSet"
                :key="indexs"
              >
                {{ items.domain }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightPart">
        <div class="headR">热门资深心理咨询师</div>
        <div class="partHot" v-for="(item, index) in hotList" :key="index">
          <div class="imgH">
            <img
              :src="baseUrl+ item.avatarName"
              alt=""
              class="littleImg"
            />
          </div>
          <div class="worrH">
            <div class="nameH">{{ item.nickname }}</div>
            <div class="priceH">{{ item.price }}</div>
            <div class="lookDetail" @click="gotodetail(item)">查看详情</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page.sync="pageIndex"
          :page-size="pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import { getUrl } from "../../utils";
  export default {
  name: "index",
  data() {
    return {
      hotList: [],
      consultList: [],
      keyWord: "",
      oldPass: "",
      newPass: "",
      page: 1,
      pageIndex: 1,
      pageSize: 5,
      pageTotal: 0,
      newPassCopy: "",
      type: "",
      baseUrl: getUrl(),
      man: require("../../assets/img/man.png"),
      man2: require("../../assets/img/man2.png"),
      woman: require("../../assets/img/woman.png"),
      woman2: require("../../assets/img/woman2.png"),
    };
  },
  created() {
    this.getData();
    this.getHotData();
  },
  methods: {
    search() {
      console.log(1);
      this.$http({
        url: this.$http.adornUrl("/consultDoctor/list"),
        method: "get",
        params: this.$http.adornParams({
          page: 1,
          sex: "",
          key: this.keyWord,
          size: 5,
        }),
      }).then((res) => {
        console.log(res.data);
        if (res.data.code == 200 && res.data.data.content.length != 0) {
          this.consultList = res.data.data.content;
          this.pageTotal = res.data.data.totalElements;
          this.keyWord = "";
        } else {
          this.$message.error("搜索为空");
        }
      });
    },
    getData() {
      this.$http({
        url: this.$http.adornUrl("/consultDoctor/list"),
        method: "get",
        params: this.$http.adornParams({
          page: this.page,
          sex: this.type,
          size: 5,
        }),
      }).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.consultList = res.data.data.content;
          this.pageTotal = res.data.data.totalElements;
        } else {
          this.consultList = [];
        }
      });
    },
    getHotData() {
      this.$http({
        url: this.$http.adornUrl("/consultDoctor/list"),
        method: "get",
        params: this.$http.adornParams({
          page: this.page,
          sex: this.type,
          size: 5,
        }),
      }).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.hotList = res.data.data.content;
        } else {
          this.hotList = [];
        }
      });
    },
    gotodetail(item) {
      this.$router.push({
        name: "ConsultDetail",
        query: {
          id: item.id,
          userId: item.userId,
        },
      });
    },
    handlePageChange(val) {
      this.page = val;
      this.getData();
    },
    check(data) {
      if (data == 3) {
        this.type = "";
      } else {
        this.type = data;
      }
      this.getData();
    },
    changePassWord() {
      if (this.oldPass == "" || this.newPass == "" || this.newPassCopy == "") {
        this.$message.error("请输入密码");
      }
    },
  },
};
</script>

<style scoped>
.imgHHH {
  width: 135px;
  height: 178px;
}
.imgPart {
  float: left;
  margin-left: 22px;
  margin-top: 22px;
  width: 135px;
  height: 178px;
}
.wordPart {
  float: left;
  margin-left: 20px;
  width: 623px;
  height: auto;
  margin-top: 22px;
}
.wordT {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  width: 92%;
  float: left;
  margin-top: 4px;
}
.doMain {
  width: 92%;
  float: left;
  margin-top: 20px;
  margin-left: -18px;
}
.domianItem {
  width: 83px;
  height: 32px;
  background: #f6f6f6;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
  text-align: center;
  float: left;
  margin-left: 18px;
}
.wordJ {
  width: 92%;
  float: left;
  margin-top: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #222;
  line-height: 19px;
}
.nameWord {
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #1f7eec;
  width: 92%;
  float: left;
}
.morePart {
  width: 28px;
  height: 28px;
  background-image: url(../../assets/img/ck.png);
  border-radius: 28px;
  opacity: 1;
  float: left;
  color: #fff;
  text-align: center;
  line-height: 28px;
  font-size: 20px;
  cursor: pointer;
}
.partConsult {
  width: 821px;
  height: auto;
  overflow: hidden;
  padding-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-top: 22px;
}
.pagination {
  float: left;
  clear: both;
  margin-top: 30px;
  margin-left: 526px;
}
.leftPart {
  width: 821px;
  min-height: 537px;

  border-radius: 0px 0px 0px 0px;
  opacity: 1;

  float: left;
}
.partHot {
  width: 311px;
  height: 100px;
  margin-left: 18px;
  border-bottom: 1px solid #e5e5e5;
}
.nameH {
  width: 100%;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 23px;
}
.lookDetail {
  width: 30%;
  height: 26px;
  float: left;
  margin-left: 10%;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #0ec2db;
  cursor: pointer;
}
.worrH {
  width: 200px;
  height: 100px;
  float: left;
  margin-left: 8px;
}
.priceH {
  width: 60%;
  height: 26px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: 7px;
  float: left;
}
.imgH {
  width: 80px;
  height: 100px;
  line-height: 100px;
  float: left;
  margin-left: 8px;
}
.littleImg {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}
.headR {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 1px;
  width: 311px;
  height: 58px;
  line-height: 58px;
  opacity: 1;
  margin-left: 18px;
  border-bottom: 1px solid #e5e5e5;
}
.rightPart {
  width: 344px;
  height: 580px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin-top: 22px;
  margin-left: 32px;

  float: left;
}
.searchPart {
  width: 477px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;

  float: left;
  margin-left: 160px;
  margin-top: 20px;
}
.sexWord {
  margin-left: 32px;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  float: left;
  margin-top: 26px;
}
.sexButton {
  width: 86px;
  height: 32px;
  background: rgba(229, 229, 229, 0.5);
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  float: left;
  margin-top: 22px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-left: 18px;
  cursor: pointer;
}
.headTitle {
  width: 1200px;
  height: 76px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  background: #ffffff;
}
.association {
  background: #ffffff;
}
.explainL {
  height: 50px;
  line-height: 50px;
  width: 1080px;
  margin: 0 auto;
  text-align: left;
}
.routeL {
  cursor: pointer;
}
.brief {
  width: 1200px;
  margin: 20px auto 76px;
  overflow: hidden;
  padding: 0;
}
.gutter-box {
  background: #ffffff;
}
.gutter-box > ul > li {
  height: 44px;
  line-height: 44px;
  cursor: pointer;
}
.active {
  font-weight: 400;
  color: #ffff;
  background-color: #0ec2db;
}
.boxL {
  padding: 15px;
}
.mainPart {
  width: 80%;
  margin-left: 8%;
  height: 420px;
  margin-top: 30px;
}
.partOne {
  width: 60%;
  height: 30px;

  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
  margin-top: 18px;
}
.partOne2 {
  width: 60%;
  margin-top: 10px;
  height: 40px;

  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.title {
  overflow: hidden;
  border-bottom: 1px solid #e5e5e5;
}
.title > h3 {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: #0ec2db;
  float: left;
  height: 40px;
  display: inline-block;
  border-bottom: 2px solid #0ec2db;
  margin-bottom: 0px;
}
article > p {
  line-height: 24px;
  text-align: left;
  text-indent: 30px;
}
.uls {
  text-align: center;
}
.xh {
  font-size: 18px;
  font-weight: 400;
  color: #0e60db;
}
.jj {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.jjs {
  border-bottom: 1px solid #e5e5e5;
}
.jj h3 {
  text-indent: 24px;
}
.jj p {
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  margin-bottom: 5px;
  text-indent: 24px;
}
.imgR {
  width: 135px;
  height: 178px;
  margin-right: 10px;
}
.imgXX {
  margin-top: -5px;
  margin-right: 5px;
}
</style>