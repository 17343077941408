<template>
    <div>
        <div class="association">
            <div class="explainL">
                <span>当前位置：</span>
                <span class="routeL">首页></span>
                <span>关于协会</span>
            </div>
        </div>
        <div class="brief">
            <a-row :gutter="20">
                <a-col class="gutter-row" :span="4">
                    <div class="gutter-box">
                        <ul class="uls">
                            <li :class="[type == 1 ? 'active' : '']" @click="check('1')"><img class="imgXX"
                                    :src="[type == 1 ? xhjj2 : xhjj]" alt="">协会简介</li>
                            <li :class="[type == 2 ? 'active' : '']" @click="check('2')"><img class="imgXX"
                                    :src="[type == 2 ? zzjg2 : zzjg]" alt="">组织架构</li>
                            <li :class="[type == 3 ? 'active' : '']" @click="check('3')"><img class="imgXX"
                                    :src="[type == 3 ? xhzc2 : xhzc]" alt="">学会章程</li>
                            <li :class="[type == 4 ? 'active' : '']" @click="check('4')"><img class="imgXX"
                                    :src="[type == 4 ? fzjg2 : fzjg]" alt="">负责人介绍</li>
                        </ul>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="20">
                    <div class="gutter-box boxL" v-show="type == 1">
                        <div class="title">
                            <h3>简介</h3>
                        </div>
                        <article>
                            <p style="margin-top: 15px;">
                                安徽省心理卫生协会于1997年经安徽省民政厅登记取得社团主体资格，主管单位是安徽省科学技术协会，挂靠支撑单位安徽医科大学。现任第四届理事会。协会成员由省内教育、医疗卫生等行业知名专家、教授及心理治疗师和心理咨询师骨干组成。
                            </p>
                            <p>协会利用媒体宣传普及理健康知识，开展各类以心理健康促进为主题的社会公益活动，每年均举办业务经验交流和学术年会，不断提高协会服务社会的能力和水平。作为全国首批认证的心理咨询师培训单位，自2003年起为全省培养心理咨询师，形成了规范的教学体系，可以为心理咨询从业者提供规范的理论和技能培训，为促进安徽省心理卫生工作的发展做出了不懈努力，取得了较好的社会效益。与省卫健委、教育厅、省妇联等组织合作，积极推动全省社会心里服务体系建设。
                            </p>
                            <p> 协会努力同全国心理学、医学、社会学、教育学、社会学界等科学工作者合作，在中国心理卫生协会指导下，认真贯彻落实国家科学技术工作和新时代心理卫生与健康工作方针，坚持民主办会原则，维护会员和心理卫生工作者的合法权益，反映心理卫生工作者的意见建议。协会充分发扬学术民主，促进心理卫生人才的成长和提高，促进心理卫生科学技术的繁荣和发展，促进创新驱动发展战略的实施，依法依章程开展心理卫生教育、科学研究、学术交流，普及心理卫生知识，维护心理健康，提高全民的心理素质，为实现中华民族伟大复兴的中国梦和五大发展美好安徽建设而努力奋斗。
                            </p>
                        </article>
                    </div>
                    <div class="gutter-box boxL" v-show="type == 2">
                        <div class="title">
                            <h3>组织架构</h3>
                        </div>
                        <article>
                            <!-- <p style="font-weight: 400;color: #333333;text-align: center;font-size: 18px;margin-top: 20px;">
                                安徽省心理卫生协会组织架构图</p> -->
                            <img src="../../assets/img/zzjg.jpg" alt=""
                                style="width: 90%;margin-left: 5%;margin-top: 2%;">
                        </article>
                    </div>
                    <div class="gutter-box boxL" v-show="type == 3">
                        <div class="title">
                            <h3>学会章程</h3>
                        </div>
                        <article>
                            <p
                                style="font-weight: 400;color: #333333;text-align: center;font-size: 18px;margin-top: 20px;">
                                安徽省心理卫生协会学会章程</p>
                            <p style="text-align: center;">第一章　总则</p>
                            <p>第一条 本会定名为安徽省心理卫生协会，英文名称为Anhui Association for Mental Health ， 缩写AAMH</p>
                            <p>第二条
                                本会是由我省从事心理健康、医疗卫生、教育、社会工作等（以下统称“心理卫生”）专业领域科学工作者和有关单位自愿组成，是依法登记成立的全省性、学术性、非营利性的社会组织，具有社会团体法人资格，是发展我省心理卫生科学技术事业和提高全民心理素质的重要社会力量。
                            </p>
                            <p>第三条
                                本会宗旨：坚持党的全面领导，高举中国特色社会主义伟大旗帜，坚持以马克思列宁主义、毛泽东思想、邓小平理论、“三个代表”重要思想、科学发展观和习近平新时代中国特色社会主义思想为指导，充分发挥协会党组织政治核心、思想引领和组织保障作用。团结全省心理学、医学、社会学、教育学、社会学界等科学工作者遵守国家宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚，贯彻落实国家科学技术工作和新时代心理卫生与健康工作方针。坚持民主办会原则，维护会员和心理卫生工作者的合法权益，反映心理卫生工作者的意见建议，充分发扬学术民主，促进心理卫生人才的成长和提高，促进心理卫生科学技术的繁荣和发展，促进创新驱动发展战略的实施，依法依章程开展心理卫生教育、科学研究、学术交流，普及心理卫生知识，维护心理健康，提高全民的心理素质，推动社会心理服务体系建设，紧密团结凝聚全省心理卫生工作者在党的周围，为实现中华民族伟大复兴的中国梦和加快发展我省经济建设而努力奋斗。
                            </p>
                            <p>第四条
                                根据中国共产党章程的规定，设立中国共产党的组织，开展党的活动，为党组织的活动提供必要条件。本会接受业务主管单位安徽省科学技术协会和社团登记管理机关安徽省民政厅的业务指导和监督管理。
                            </p>
                            <p>第五条 本会的住所设在安徽省合肥市。</p>
                            <p style="text-align: center;">第二章　业务范围</p>
                            <p>第六条 本会的业务范围</p>
                            <p>一、围绕心理卫生及相关学科开展活动，弘扬科学精神，普及心理卫生科学知识，传播科学思想和科学方法，提高全民心理素质和心理健康水平；</p>
                            <p>二、开展心理卫生相关学术交流与合作，活跃学术思想，倡导学术民主，优化学术环境，促进学科发展，推动自主创新；</p>
                            <p>三、围绕心理卫生及相关学科开展继续教育，培养心理卫生专业人才；</p>
                            <p>四、依照有关规定，编辑、出版、发行心理卫生的学术专著和科普书籍、刊物及相关的音像制品；</p>
                            <p>五、承接政府及有关部门委托的工作任务，包括但不限于心理卫生领域的科技项目、科技成果评价及专业技术资格评审或职业技能评价；从事专业技术规范和标准的制定；</p>
                            <p>六、开展各类公益性的心理卫生服务和心理健康促进工作；</p>
                            <p>七、依法维护心理卫生工作者在专业活动中的合法权益和心理卫生工作者在执业活动中的人格尊严，向政府有关部门反映心理卫生工作者的意见和诉求；</p>
                            <p>八、按照规定经批准，推荐和表彰心理卫生科技人才,以及在心理卫生领域里取得优异成绩的科技工作者。</p>
                            <p>上述业务范围概括为：围绕心理卫生开展活动，普及知识，促进交流，规范行为，推动社会心理服务体系建设。</p>

                            <p style="text-align: center;">第三章　会员</p>
                            <p>第七条 本会包括个人会员和单位会员。</p>
                            <p>第八条 申请加入本会的会员，必须具备下列条件</p>
                            <p>凡拥护本会章程,符合下列会员条件之一者,均可自愿申请为本会会员。</p>
                            <p>一、拥护本会的章程；</p>
                            <p>二、有加入本会的意愿；</p>
                            <p>三、在本会的业务，从事心理卫生相关工作，具备中级及以上技术职称或具有同等专业任职资格者或心理卫生学、心理学、医学、社会学、教育学及相关学科高等院校毕业，从事心理卫生相关工作者；
                            </p>
                            <p>四、单位会员需具有一定数量的心理卫生专业人员、支持本会工作的医疗、科研、教育等企事业单位和社会团体或承认协会章程，愿意参加协会有关活动，支持协会工作，合法设立的企事业单位和社会团体。
                            </p>
                            <p>第九条 会员入会的程序是：</p>
                            <p>一、提交入会申请书；</p>
                            <p>二、经理事会讨论通过；</p>
                            <p>三、由本会理事会或常务理事会其授权的机构颁发会员证，并予以公告。 </p>
                            <p>第十条 会员享有下列权利：</p>

                            <p>1、本会的选举权、被选举权和表决权；</p>
                            <p>2、参加本会的活动；</p>
                            <p>3、获得本会服务的优先权；</p>
                            <p>4、对本会工作有批评建议权和监督权；</p>
                            <p>5、入会自愿、退会自由。</p>
                            <p>第十一条 会员履行下列义务：</p>

                            <p>1、遵守本会章程，执行本会的决议；</p>
                            <p>2、遵守职业道德，遵守本会的规范和准则，维护本会的合法权益和会员的声誉；</p>
                            <p>3、完成本会和所在分支机构委托的工作任务，参加本会和所在专业委员会组织的有关社会公益活动；</p>
                            <p>4、按规定交纳会费；</p>
                            <p>5、向本会反映情况，提供有关资料。</p>

                            <p>第十二条 会员退会应书面通知本团体，并交回会员证。会员有下列情形之一的，自动丧失会员资格：</p>
                            <p>1、2年不按规定交纳会费；</p>
                            <p>2、2年不按要求参加本会活动；</p>
                            <p>3、不再符合会员条件；</p>
                            <p>4、丧失民事行为能力。</p>
                            <p>第十三条 会员如有严重违反本章程的行为，经理事会或常务理事会表决通过，予以除名。
                            </p>
                            <p style="text-align: center;">第四章　组织机构和负责人产生、罢免</p>
                            <p>第十四条 本会的最高权力机构是会员代表大会，会员代表大会的职权是：</p>
                            <p>一、制定和修改章程；</p>
                            <p>二、选举和罢免理事、常务理事；</p>
                            <p>三、选举和罢免监事；</p>
                            <p>四、审议理事会的工作报告和财务报告；</p>
                            <p>五、审议监事会的工作报告；</p>
                            <p>六、制定和修改会费标准；</p>
                            <p>七、决定终止事宜；</p>
                            <p>八、决定其他重大事宜。</p>
                            <p> 第十五条 全省会员代表大会须有2/3以上的会员代表出席方能召开，其决议须经到会会员代表半数以上表决通过方能生效。制定和修改章程须经到会会员2/3以上表决通过。</p>
                            <p>第十六条 会员代表大会每届5年。因特殊情况需提前或延期换届的，须由理事会表决通过，报业务主管单位审查并经社团登记管理机关批准同意。但延期换届最长不超过1年。</p>
                            <p>第十七条 理事会是会员代表大会的执行机构，在闭会期间领导本会开展日常工作，对会员代表大会负责。</p>
                            <p>第十八条 理事会的职权是：</p>
                            <p> 一、执行会员代表大会的决议；</p>
                            <p>二、选举和罢免理事长、副理事长、秘书长；</p>
                            <p>三、筹备召开会员代表大会，负责换届选举工作；</p>
                            <p>四、向会员代表大会报告工作和财务状况；</p>
                            <p>五、决定会员的吸收或除名；</p>
                            <p>六、决定设立、变更和终止办事机构、分支机构、代表机构和实体机构；</p>
                            <p>七、决定副秘书长、各机构主要负责人的聘任。</p>
                            <p>八、领导本团体各机构开展工作；</p>
                            <p>九、制定内部管理制度；</p>
                            <p>十、决定本会负责人和工作人员的考核及薪酬管理办法；</p>
                            <p>十一、决定其他重大事项。</p>
                            <p>第十九条
                                理事会须有2／3以上理事出席方能召开，其决议须经到会理事2／3以上表决通过方能生效，理事不能到会，可委托代表参加，受托代表有委托投票权。
                            </p>

                            <p>第二十条 理事会每年至少召开一次会议；情况特殊的也可采用通讯形式召开。</p>
                            <p>第二十一条 本团体设立常务理事会。常务理事会由理事会选举产生，在理事会闭会期间行使第十八条第一、
                                三、五、六、七、八、九项的职权，对理事会负责(常务理事人数不超过理事人数的1／3)。
                            </p>
                            <p>第二十二条
                                常务理事会须有2／3以上常务理事出席方能召开，其决议须经到会常务理事2／3以上表决通过方能生效。
                            </p>
                            <p>第二十三条
                                常务理事会至少半年召开一次会议；情况特殊的也可采用通讯形式召开。
                            </p>
                            <p>第二十四条 本团体的理事长、副理事长、秘书长必须具备下列条件：</p>
                            <p>一、坚持党的路线、方针、政策、政治素质好；</p>
                            <p>二、在本团体业务领域内有较大影响；</p>
                            <p>三、理事长、副理事长、秘书长最高任职年龄不超过70周岁，秘书长为专职；</p>
                            <p>四、身体健康，能坚持正常工作；</p>
                            <p>五、未受过剥夺政治权利的刑事处罚的；</p>
                            <p>六、具有完全民事行为能力；</p>

                            <p>第二十五条 本团体理事长、副理事长、秘书长如超过最高任职年龄的，须经理事会表决通过，报业务主管单位审查并社团登记管理机关批准同意后，方可任职。</p>
                            <p>第二十六条 本团体理事长、副理事长、秘书长任期5年。因特殊情况需延长任期的，须经会员代表大会2／3以上会员代表表决通过，报业务主管单位审查并经社团登记管理机关批准同意后方可任职。
                            </p>
                            <p>第二十七条 理事长为本团体法定代表人。因特殊情况经理事长委托，理事会讨论通过，报业务主管单位审查和社团登记管理机关批准后，可由副理事长担任法定代表人。</p>
                            <p> 本团体法定代表人不兼任其他团体的法定代表人。</p>
                            <p>第二十八条 本团体理事长行使下列职权：</p>
                            <p>一、召集和主持理事会、常务理事会；</p>
                            <p>二、检查会员代表大会、理事会、常务理事会决议的落实情况；</p>
                            <p>三、代表本团体签署有关重要文件；</p>
                            <p>四、向会员代表大会、理事会、常务理事会报告工作。</p>
                            <p>理事长因故不能履行职权，由理事长委派或常务理事会指定一位副理事长代行职权。</p>
                            <p>第二十九条 本团体秘书长行使下列职权：</p>
                            <p>一、主持办事机构开展日常工作，组织实施年度工作计划；</p>
                            <p>二、协调各分支机构、代表机构、实体机构开展工作；</p>
                            <p>三、提名副秘书长以及各办事机构、分支机构、代表机构和实体机构主要负责人，交理事会或常务理事会决定；</p>
                            <p>四、决定办事机构、代表机构、实体机构专职工作人员的聘用；</p>
                            <p>五、处理其他日常事务。</p>

                            <p>第三十条 本团体设立监事会，监事任期与理事任期相同，期满可以连任。监事会由五名监事组成。监事会设监事长一名，副监事长一名，由监事会推举产生。</p>
                            <p>第三十一条 监事的产生和罢免：
                            </p>
                            <p>（一） 由会员代表大会选举产生；</p>
                            <p>（二） 业务主管单位和登记管理机关根据工作推举；</p>
                            <p>（三）监事的罢免依照其产生程序。</p>
                            <p>第三十二条 本团体的负责人、理事、常务理事、财务管理人员和协会专职工作人员不得兼任监事。</p>

                            <p>第三十三条 监事会行使下列职权：</p>
                            <p>（一） 列席理事会、常务理事会，并对决议事项提出质询或建议；</p>
                            <p>（二） 对理事、常务理事、负责人执行本团体职务的行为进行监督，对违反法律、行政法规和本团体章程或者会员代表大会决议的人员提出依程序罢免的建议；</p>
                            <p>（三） 检查本团体的财务报告，向会员代表大会报告监事会的工作和提出提案；</p>
                            <p>（四） 对负责人、理事、常务理事、财务管理人员损害本团体利益的行为，及时进行纠正；</p>
                            <p>（五） 向业务主管单位、登记管理机关以及税务、会计主管部门反映本团体工作中存在的问题；</p>
                            <p>（六） 决定其他应由监事会审议的事项。</p>
                            <p>第三十四条 监事会每6个月至少召开一次会议。监事会会议须有2/3以上监事出席方能召开，其决议须经到会监事1/2以上通过方为有效。</p>
                            <p>第三十五条 监事应当遵守有关法律法规和本团体章程，忠实履行职责。</p>
                            <p>第三十六条 监事会发现本团体开展活动情况异常，可以进行调查；必要时，可以聘请会计师事务所等协助其工作。监事行使职权所必需的费用，由本团体承担。</p>
                            <p style="text-align: center;">第五章　资产管理、使用原则</p>
                            <p>第三十七条 本团体经费来源</p>
                            <p>一、会费；</p>
                            <p>二、捐赠；</p>
                            <p>三、政府资助；</p>
                            <p>四、在核准的业务范围内开展活动或服务的收入；</p>
                            <p>五、利息；</p>
                            <p>六、其他合法收入。</p>
                            <p>第三十八条 本团体按照国家有关规定收取会员会费。</p>
                            <p>第三十九条 本团体经费必须用于本章程规定的业务范围和事业的发展，不得在会员中分配。</p>

                            <p>第四十条 本团体建立严格的财务管理制度，保证会计资料合法、真实、准确、完整。</p>
                            <p>第四十一条 本团体配备具有专业资格的会计人员。会计不得兼任出纳。会计人员必须进行会计核算，实行会计监督。会计人员调动工作或离职时，必须与接管人员办清交接手续。</p>
                            <p>第四十二条
                                本团体的资产管理必须执行国家规定的财务管理制度，接受会员代表大会和财政部门的监督。资产来源属于国家拨款或者社会捐赠、资助的，必须接受审计机关的监督，并将有关情况以适当方式向社会公布。
                            </p>
                            <p>第四十三条 本团体换届或更换法定代表人之前必须接受社团登记管理机关和业务主管单位组织的财务审计。</p>
                            <p>第四十四条 本团体的资产，任何单位、个人不得侵占、私分和挪用。 </p>

                            <p>第四十五条 本团体专职工作人员的工资和保险、福利待遇，参照国家对事业单位的有关规定执行。</p>
                            <p style="text-align: center;">第六章　章程的修改程序</p>

                            <p>第四十六条 对本团体章程的修改，须经理事会表决通过后报会员代表大会审议。</p>
                            <p>第四十七条 本团体修改的章程，须在会员代表大会通过后15日内，经业务主管单位审查同意，并报社团登记管理机关核准后生效。</p>
                            <p style="text-align: center;">第七章　终止程序及终止后的财产处理</p>

                            <p>第四十八条 本团体完成宗旨或自行解散或由于分立、合并等原因需要注销的，由理事会或常务理事会提出终止动议。</p>
                            <p>第四十九条 本团体终止动议须经会员代表大会表决通过，并报业务主管单位审查同意。</p>
                            <p>第五十条 本团体终止前，须在业务主管单位及有关机关指导下成立清算组织，清理债权债务，处理善后事宜。清算期间，不开展清算以外的活动。 </p>
                            <p>第五十一条 本团体经社团登记管理机关办理注销登记手续后即为终止。</p>
                            <p>第五十二条 本团体终止后的剩余财产，在业务主管单位和社团登记管理机关的监督下，按照国家有关规定，用于发展与本团体宗旨相关的事业。</p>
                            <p style="text-align: center;">第八章 附则</p>
                            <p>第五十三条 本章程经2023年3月17日第五次会员代表大会表决通过。</p>
                            <p>第五十四条 本章程的解释权属本团体的理事会。</p>
                            <p>第五十五条 本章程自社团登记管理机关核准之日起生效。 </p>
                        </article>
                    </div>
                    <div class="gutter-box boxL" v-show="type == 4">
                        <div class="title">
                            <h3>负责人介绍</h3>
                        </div>
                        <article style="padding: 15px 0;">
                            <div class="xh">安徽省心理卫生协会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>理事长</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/hcs.png" alt="">
                                </div>
                                <div>
                                    <h3>何成森</h3>
                                    <p>安徽医科大学教授，本科毕业于安徽医科大学临床医学专业，研究生毕业于中国科学技术大学。应用心理学专业硕士生导师，安徽省心理卫生协会理事长、中国心理卫生协会理事、《中国健康心理学杂志》编委。
                                    </p>
                                    <p>长期从事医学心理学教学研究和心理咨询工作，具有三十多年高校教育教学工作的实践经验和近二十年省级专科医院建设及管理经验，对心理健康教育和公共事业管理有比较深入的研究。
                                    </p>
                                    <p>承担国家级课题、省级重大课题参与国际合作课题等二十余项，发表六十余篇专业论文，主编参编著作、教材六部，拥有心理咨询师培训师、心理治疗师职业资格。</p>
                                </div>
                            </div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>副理事长</div>
                            <!--<div class="jj jjs">-->
                            <!--<div>-->
                            <!--<img class="imgR" src="../../assets/img/jyf.png" alt="">-->
                            <!--</div>-->
                            <!--<div>-->
                            <!--<h3>季益富</h3>-->
                            <!--<p>安徽省精神卫生中心主任医师，安徽医科大学硕士研究生导师。是被誉为我国心理治疗与咨询行业“黄埔军校”的中德心理治疗连续培训班的首期毕业生；中挪心理动力学取向心理治疗督导师连续培训班的首期毕业生。一直从事精神心理科临床、教学、科研等工作。是中国心理学会和中国心理卫生协会双认证的首批注册心理督导师（注册号：D-06-026；XXD-2020-138）。</p>-->
                            <!--</div>-->
                            <!--</div>-->
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/zcy.png" alt="">
                                </div>
                                <div style="width: 84%;">
                                    <h3>朱春燕</h3>
                                    <p>教授，博士生导师，安徽医科大学精神卫生与心理科学学院副院长。</p>
                                    <p>任中华医学会行为医学分会委员、心身医学分会委员、中国医师协会心身医学专委会委员、安徽省心理卫生协会心身医学专委会副理事长、精神分析专委会副理事长，安徽省社会心理学会副会长等。
                                    </p>
                                    <p>主要研究方向：情绪障碍及强迫障碍的神经机制及干预研究。主持国家自然科学基金3项，以第一作者（或通讯作者）在Human Brain Mapping，Journal
                                        of
                                        Affective Disorders等杂志发表SCI论文近30篇。研究成果获得中华医学科技一等奖1项、省级科学技术奖一等奖2项。</p>

                                </div>
                            </div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/jyf.png" alt="">
                                </div>
                                <div style="width: 84%;">
                                    <h3>季益富</h3>
                                    <p>安徽省精神卫生中心主任医师，安徽医科大学硕士研究生导师。</p>
                                    <p>是被誉为我国心理治疗与咨询行业“黄埔军校”的中德心理治疗连续培训班的首期毕业生；中挪心理动力学取向心理治疗督导师连续培训班的首期毕业生。</p>
                                    <p>一直从事精神心理科临床、教学、科研等工作。是中国心理学会和中国心理卫生协会双认证的首批注册心理督导师（注册号：D-06-026；XXD-2020-138）。
                                    </p>
                                    <p>主要社会任职：</p>
                                    <p>德中心理治疗研究院 常务理事；</p>
                                    <p>中国心理卫生协会精神分析专业委员会 常务委员；</p>
                                    <p>中国医师协会心身医学专业委员会 常务委员；</p>
                                    <p>中国心理卫生协会心理治疗与心理咨询专业委员会 委员；</p>
                                    <p>安徽省心理卫生协会心理治疗与咨询专业委员会 主任委员；</p>
                                    <p>安徽省医师协会心身医学专业委员会 主任委员。</p>
                                </div>
                            </div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/zxq.png" alt="">
                                </div>
                                <div style="width: 84%;">
                                    <h3>周晓琴</h3>
                                    <p>2012年安徽省第一届“江淮名医”称号；</p>
                                    <p>安徽医科大学 硕士研究生导师；</p>
                                    <p>安徽医科大学附属巢湖医院精神科科主任； </p>
                                    <p>中国心理卫生协会理事；</p>
                                    <p>中国心理卫生协会精神分析专业委员会委员；</p>
                                    <p>中国心理卫生协会心理评估专业委员会委员；</p>
                                    <p>安徽省心理卫生协会常务理事兼心身医学专委会主任委员；</p>
                                    <p>安徽省医师协会精神医学学分会常务委员；</p>
                                    <p>安徽省心理咨询师协会副理事长；</p>
                                    <p>安徽省心理学会常务理事；</p>
                                    <p>合肥市心理卫生协会理事长；</p>
                                    <p>中国心理学会注册督导师（D-21-212）。</p>
                                </div>
                            </div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>秘书长</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/yp.png" alt="">
                                </div>
                                <div style="width: 84%;">
                                    <h3>杨平</h3>
                                    <p>安徽医大学副教授; 中科大心理学博士; 安徽省心理卫生协会秘书长; 中华医学会行为医学分会青年委员; 亚洲药物成瘾治疗专委会委员; Brain Science
                                        Advances 杂志安徽省工作站负责人。
                                    </p>
                                </div>
                            </div>
                            <div class="xh">中小学心理指导专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/hzm.png" alt="">
                                </div>
                                <div>
                                    <h3>黄志敏</h3>
                                    <p>安徽大学教师，兼任中国心理学会会员、安徽省心理卫生协会常务理事、中小学心理指导专委会主任委员等，致力于青少年心理健康教育与家庭教育，发表文章四十多篇，参编心理与教育类著作4本，应邀到全省开展了300多场有关心理健康教育和家庭教育方面的讲座，受众多达近十万人，直接义务辅导和帮助了近千名学生和家长，让很多孩子走出了心理困境，帮助很多家长改进了家教方式。
                                    </p>
                                </div>
                            </div>
                            <div class="xh">心理危机干预专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/dyf.png" alt="">
                                </div>
                                <div style="width: 84%;">
                                    <h3>戴宇峰</h3>
                                    <p>安徽艺术学院心理健康教育与咨询中心主任，国家二级心理咨询师，心理治疗师；</p>
                                    <p>安徽省首批执业心理咨询师；</p>
                                    <p>安徽省心理卫生协会常务理事、危机干预专委会主委；</p>
                                    <p>中国心理卫生协会“八大”代表。</p>
                                </div>
                            </div>
                            <div class="xh">心理治疗与咨询专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/jyf.png" alt="">
                                </div>
                                <div>
                                    <h3>季益富</h3>
                                    <p>安徽省精神卫生中心主任医师，安徽医科大学硕士研究生导师。是被誉为我国心理治疗与咨询行业“黄埔军校”的中德心理治疗连续培训班的首期毕业生；中挪心理动力学取向心理治疗督导师连续培训班的首期毕业生。一直从事精神心理科临床、教学、科研等工作。是中国心理学会和中国心理卫生协会双认证的首批注册心理督导师（注册号：D-06-026；XXD-2020-138）。
                                    </p>
                                    <p>主要社会任职：</p>
                                    <p>德中心理治疗研究院 常务理事；</p>
                                    <p>中国心理卫生协会精神分析专业委员会 常务委员；</p>
                                    <p>中国医师协会心身医学专业委员会 常务委员；</p>
                                    <p>中国心理卫生协会心理治疗与心理咨询专业委员会 委员；</p>
                                    <p>安徽省心理卫生协会 副理事长；</p>
                                    <p>安徽省医师协会心身医学专业委员会 主任委员。</p>
                                </div>
                            </div>
                            <div class="xh">心身医学专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/zxq.png" alt="">
                                </div>
                                <div>
                                    <h3>周晓琴</h3>
                                    <p>2012年安徽省第一届“江淮名医”称号；</p>
                                    <p>安徽医科大学 硕士研究生导师；</p>
                                    <p>安徽医科大学附属巢湖医院精神科科主任；</p>
                                    <p>中国心理卫生协会理事；</p>
                                    <p>中国心理卫生协会精神分析专业委员会委员；</p>
                                    <p>中国心理卫生协会心理评估专业委员会委员；</p>
                                    <p>安徽省心理卫生协会副理事长；</p>
                                    <p>安徽省医师协会精神医学学分会常务委员；</p>
                                    <p>安徽省心理咨询师协会副理事长；</p>
                                    <p>安徽省心理学会常务理事；</p>
                                    <p>合肥市心理卫生协会理事长；</p>
                                    <p>中国心理学会注册督导师（D-21-212）。</p>
                                </div>
                            </div>
                            <div class="xh">青少年职业生涯发展教育专业委员会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/fab.png" alt="">
                                </div>
                                <div style="width: 84%;">
                                    <h3>封安保</h3>
                                    <p>合肥市第一中学党委书记、校长，中国教育发展战略学会生涯教育专业委员会副理事长，安徽省物理特级教师、安徽省物理学会常务理事、安徽省教育学会物理专业委员会常务理事、安徽省高中物理奥林匹克一级教练员、合肥市中学物理特级教师工作站站长，合肥市物理学会常务副理事长、合肥市物理学科带头人，曾荣获“合肥市普通高中课程改革先进个人”“合肥市十大教育新闻人物”“合肥市五一劳动奖章”等称号，是“合肥市第六届专业技术拔尖人才”。
                                    </p>
                                </div>
                            </div>
                            <div class="xh">精神分析专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/lxs.png" alt="">
                                </div>
                                <div style="width: 84%;">
                                    <h3>李晓驷</h3>
                                    <p>安徽省精神卫生中心二级主任医师、学术顾问、教授；</p>
                                    <p>德-中心理治疗研究院中方教员；</p>
                                    <p>中国心理卫生协会精神分析专业委员会副主任委员；</p>
                                    <p>中国心理学会首批注册心理督导师（D-06-034）；</p>
                                    <p>中华医学会精神医学分会第七、第八届常委；</p>
                                    <p>安徽省心理卫生协会副理事长。</p>
                                </div>
                            </div>
                            <div class="xh">大学生心理健康教育与咨询专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>执行主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/wy.png" alt="">
                                </div>
                                <div>
                                    <h3>王永</h3>
                                    <p>教授，心理咨询师，省教学名师，合肥幼儿师范高等专科学校心理中心主任，省教育厅大学生心理健康教育专家指导委员会委员，省心理卫生协会大专委副主任委员，中国心理卫生协会大专委会委员。从事心理健康教育与咨询20多年，发表论文20余篇，出版著作8部，获国家级和省级成果奖9项。积极组织和参加心理心理服务，长年工作在心理健康科普一线。
                                    </p>
                                </div>
                            </div>
                            <div class="xh">婚姻家庭专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/zsl.png" alt="">
                                </div>
                                <div>
                                    <h3>朱水龙</h3>
                                    <p>硕士，副教授，国家高级心理咨询师，国家二级婚姻家庭咨询师，安徽省高校心理健康教育专家，安徽省心理卫生协会理事，淮北市心理卫生协会副理事长兼秘书长，中国心理卫生协会会员。淮北职业技术学院医学系党总支书记。从事心理健康教育和咨询已十余年，先后发表数十篇心理健康研究论文，开展多项省级心理科研课题，广泛参加全国各类心理理论和技术培训，数十次在省内进行心理讲座。2019年获得中国心理卫生协会“优秀心理工作者”，2022年当选中国心理卫生协会八大代表。主要咨询方向：情感婚姻家庭，抑郁、焦虑、恐怖情绪，青少年心理等。
                                    </p>
                                </div>
                            </div>
                            <div class="xh">团体心理辅导与治疗专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/lhq.png" alt="">
                                </div>
                                <div>
                                    <h3>刘鹤群</h3>
                                    <p>中国心理学会注册心理师、督导师；美国欧文•亚隆心理治疗学院认证团体咨询师；德国艾利克森研究院认证催眠治疗师；国家二级心理咨询师。中国心理卫生协会心理治疗与心理咨询专委会委员、文化与心理治疗学组委员，中国健康管理协会公职人员心理健康管理分会理事，安徽省心理卫生协会理事暨团体辅导与治疗专委会主任委员、心理治疗与心理咨询专委会副主任委员。
                                    </p>
                                </div>
                            </div>
                            <div class="xh">临床心理专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/zdp.png" alt="">
                                </div>
                                <div style="width: 84%;">
                                    <h3>周道平</h3>
                                    <p>教授、安徽省第二人民医院副院长、主任医师、硕士生导师，中国医院协会疾病与健康专业委员会委员,中小学心理指导专委会主任委员等，
                                        安徽省综合医院中医药质控中心主任，发表文章四十多篇，中华医学会安徽分会肿瘤学分会委员，安徽省职业健康检查质量控制中心常务副主任，
                                        安徽省医院协会医疗质量与患者安全管理专委会副主任委员，《安徽医药》《安徽卫生职业技术学报》编委。
                                    </p>
                                </div>
                            </div>
                            <div class="xh">森田疗法专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/ljb.png" alt="">
                                </div>
                                <div>
                                    <h3>李江波</h3>
                                    <p>日本国立鹿儿岛大学心身医学博士，华东师范大学附属芜湖医院临床心理科主任医师。日本保健医疗大学教授，武汉大学和皖南医学院硕士生导师，1988开始担任精神科主任。中国心理卫生协会常务理事，中国心理卫生协会森田疗法专委会主任委员，中国心理卫生协会首批认证心理督导师，中国心理卫生协会专家库专家，森田疗法系列丛书主编，芜湖市心理卫生协会副理事长，芜湖市医学会精神科分会副理事长。
                                    </p>
                                </div>
                            </div>

                            <div class="xh">认知行为治疗专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/weh.png" alt="">
                                </div>
                                <div style="width: 84%;">
                                    <h3>汪恩焕</h3>
                                    <p>主任医师、教授、硕士生导师；</p>
                                    <p> 蚌埠市癫痫研究治疗中心主任；蚌埠市功能神经外科研究所所长；蚌埠市脑与脊髓重大疾病研究院院长；蚌埠医学院附属蚌埠第三人民医院功能神经外科主任；</p>
                                    <p>世界华人脊柱脊髓协会常务理事；中华中青年神经外科协会常务理事；中国民族药学脑病学会常委；中国医师协会脊柱脊髓专家委员会委员；中国医师协会神经创伤培训专家委员会委员；
                                    </p>
                                    <p>安徽省抗癫痫协会副理事长；安徽省全科医学会癫痫与神经调控分会副理事长；中国抗癫痫协会神经电理分会理事；安徽省心理卫生协会认知行为治疗专委会主任委员；安徽医师协会神经修复委员会常委；安徽省神经科学学会委员；安徽省神经电生理学会委员；中华医学会
                                        蚌埠市神经外科学分会副主任委员；蚌埠市抗癫痫协会理事长。</p>

                                </div>
                            </div>



                            <div class="xh">心理评估专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/zl.png" alt="">
                                </div>
                                <div>
                                    <h3>张蕾</h3>
                                    <p>教授，硕士生导师，2017年赴澳大利亚塔斯马尼亚大学访学，2021年中科院心理所访学。
                                    </p>
                                    <p>主持国家自然科学基金、教育部社科基金、安徽省自然科学基金，安徽省高校优秀人才基金、安徽省人文社科基金等多项课题。发表SCI论文30余篇，中文核心期刊20余篇。
                                        一直从事精神心理科临床、教学、科研等工作，是中国心理卫生协会首批注册心理咨询师（XXZZ-2021-448），中国心理学协会注册心理咨询师（X-23-105），国家二级心理咨询师，中级心理治疗师。
                                    </p>
                                    <p>主要社会任职：
                                    </p>
                                    <p>中国心理卫生协会大学生心理咨询专业委员会委员，安徽省心理卫生协会心理评估专业委员会主任委员，安徽省心理咨询师协会常务理事，中华医学会心身医学安徽省分会委员，安徽省心理卫生协会心身医学专委会委员，合肥市心理卫生协会常务理事。
                                    </p>
                                </div>
                            </div>

                            <div class="xh">护理心理健康教育专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/bqq.png" alt="">
                                </div>
                                <div>
                                    <h3>毕清泉</h3>
                                    <p>医学硕士，副教授，硕士生导师，护理学科带头人。
                                    </p>
                                    <p>主要从事本科《内科护理学》《健康评估》《妇幼心理学》《老年护理学》及硕士研究《高级健康评估》等教学工作。以第一作者或通讯发表论文60余篇，其中SCI
                                        7篇，主编、副主编8部，参编教材6部；主持教科研项目校级7项、省级6项；指导大学生创新创业项目7项，获国家、省、校基金资助。曾荣获校“优秀临床带教教师”、“先进个人”、“优秀教师”、“优秀学生思想政治辅导员”、多次荣获安徽省卫生厅专科护士培训班“优秀教师”等荣誉称号。

                                    </p>
                                    <p>主要社会任职：
                                    </p>
                                    <p>《安徽医药》《中国医药导报》审稿专家
                                    </p>
                                    <p>安徽省心理卫生协会理事会理事
                                    </p>
                                    <p>安徽省心理卫生协会护理心理健康教育专委会主任委员
                                    </p>
                                    <p>安徽医学会行为学会委员
                                    </p>
                                </div>
                            </div>

                            <div class="xh">萨提亚家庭咨询与治疗专委会</div>
                            <div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>主任委员</div>
                            <div class="jj">
                                <div>
                                    <img class="imgR" src="../../assets/img/wjs.png" alt="">
                                </div>
                                <div>
                                    <h3>王劲松</h3>
                                    <p>安徽医学高等专科学校党委副书记兼安徽省医学科学研究院党总支书记，教授，硕士学位，毕业于华东师范大学教育管理专业。
                                    </p>
                                    <p>长期从事大学生思想政治教育工作，关心关注学生心理健康成长。主编国家级规划教材、主持省级教科研项目多项，领衔编制安徽省地方标准《涉及人的生物医学研究伦理审查规范》等。
                                    </p>
                                   
                                </div>
                            </div>
                            <!--<div style="margin: 10px 0;"><span style="font-weight: 900;">·</span>副主任委员</div>-->
                            <!--<div class="jj">-->
                            <!--<div>-->
                            <!--<img class="imgR" src="../../assets/img/whb.png" alt="">-->
                            <!--</div>-->
                            <!--<div style="width: 84%;">-->
                            <!--<h3>魏宏波</h3>-->
                            <!--<p>发展与教育心理学博士；</p>-->
                            <!--<p>中国心理学会临床心理学注册系统注册心理师（注册号X-19-156）；</p>-->
                            <!--<p>团体心理辅导与治疗专业委员会副主任委员。</p>-->
                            <!--</div>-->
                            <!--</div>-->

                        </article>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            type: 1,
            xhjj: require("../../assets/img/xhjj.png"),
            xhjj2: require("../../assets/img/xhjj2.png"),
            zzjg: require("../../assets/img/zzjg1.png"),
            zzjg2: require("../../assets/img/zzjg2.png"),
            xhzc: require("../../assets/img/xhzc.png"),
            xhzc2: require("../../assets/img/xhzc2.png"),
            fzjg: require("../../assets/img/fzjg.png"),
            fzjg2: require("../../assets/img/fzjg2.png"),
        }
    },
    methods: {
        check(data) {
            this.type = data;
        }
    }
}
</script>

<style scoped>
.association {
    background: #ffffff;
}

.explainL {
    height: 50px;
    line-height: 50px;
    width: 1080px;
    margin: 0 auto;
    text-align: left;
}

.routeL {
    cursor: pointer;
}

.brief {
    width: 1080px;
    margin: 20px auto 76px;
}

.gutter-box {
    background: #ffffff;
}

.gutter-box>ul>li {
    height: 44px;
    line-height: 44px;
    cursor: pointer;
}

.active {
    font-weight: 400;
    color: #0EC2DB;
}

.boxL {
    padding: 15px;
}

.title {
    overflow: hidden;
    border-bottom: 1px solid #E5E5E5;
}

.title>h3 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #0EC2DB;
    float: left;
    height: 40px;
    display: inline-block;
    border-bottom: 2px solid #0EC2DB;
    ;
    margin-bottom: 0px;
}

article>p {
    line-height: 24px;
    text-align: left;
    text-indent: 30px
}

.uls {
    text-align: center;
}

.xh {
    font-size: 18px;
    font-weight: 400;
    color: #0E60DB;
}

.jj {
    display: flex;
    /* justify-content: center; */
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.jjs {
    border-bottom: 1px solid #E5E5E5;
}

.jj h3 {
    text-indent: 24px
}

.jj p {
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    margin-bottom: 5px;
    text-indent: 24px
}

.imgR {
    width: 135px;
    height: 178px;
    margin-right: 10px;
}

.imgXX {
    margin-top: -5px;
    margin-right: 5px;
}
</style>