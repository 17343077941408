<template>
    <div>
     <div class="association">
             <div class="explainL">
                 <span>当前位置：</span>
                 <span class="routeL">首页 > </span>
                 <span>学术年会 > </span>
                 <span>活动通知 ></span>
             </div>
         </div>
         <div class="cont">
             <a-row :gutter="20">
                 <a-col class="gutter-row" :span="24">
                     <div class="title">(中国) 华东心理卫生联盟学术交流会暨2022年安徽省心理卫生协会学术年会参会缴费
                         <p>2023-02-01</p>
                     </div>
                    <!-- <div class="fenxiang">
                     <div style="float:right">
                         <span>分享至： &nbsp;</span>
                         <img src="../../assets/img/wbfx.png" alt=""> 
                         <span> &nbsp;微博 &nbsp;&nbsp;&nbsp; </span>
                         <img src="../../assets/img/wxfx.png" alt="">
                         <span> &nbsp;微信</span>
                     </div>
                    </div> -->
                 </a-col>
                 <a-col>
                     &nbsp;
                     <div style="border-bottom:1px solid #F0EDF0"></div>
                 </a-col>
                 <div class="boxL">
                    <!--  <article>
                     <p style="margin-top: 15px;"> (中国) 华东心理卫生联盟学术交流会暨2021年安徽省心理卫生协会学术年会参会缴费明细如下:</p>
                     <p>一、参会费用</p>  
                     <p> 非会员1180元/人，协会会员980元/人，团报(5 人及以上) 880 元/人，在校学生凭学生证按600元/人标准收取会议费(不再享受其他优惠)</p>
                     <p>会务组统-预订宾馆， 会议期间食宿、交通费用自理。(如新申请协会会员， 则需在收到协会审批通过短信后进行会议注册，方可享受优惠价格。)</p>
                     <p>协会会员或非会员单人参会，可直接在此页面在线交纳会费。团报或在校学生会务费可提前打入协会对公账号，也可在现场交费,汇款时请备注“姓名+电话+2021年学术年会"。如提前缴费，请注意保管好缴费相关凭证，现场审核。</p>
                     <p>*银行账户:安徽省心理卫生协会</p>
                     <p>*开户行:中国银行合肥梅山路支行</p>
                     <p>*账号: 181225905219</p>
                     <p>*纳税人识别号: 51340000743056355E</p>
                 </article> -->
                 <article>
                     <p style="margin-top: 15px;"> (中国) 华东心理卫生联盟学术交流会暨2022年安徽省心理卫生协会学术年会参会缴费明细如下:</p>
                     <!-- <p>一、参会费用</p>   -->
                     <p> 非会员1180元/人，协会会员980元/人，专委会团报价10人以上880元/人，在校学生凭学生证按600元/人标准收取会议费（不再享受其他优惠）。会务组统一预订宾馆，会议期间食宿、交通费用自理。（如新申请协会会员，则需在收到协会审批通过短信后进行会议注册，方可享受优惠价格。）</p>
                     <p>会务费可提前打入协会对公账号，也可在现场交费，汇款时请备注“姓名+电话+2022年学术年会”。</p>
                     <p>如提前缴费，请注意保管好缴费相关凭证，现场审核。</p>
                     <p>*银行账户:安徽省心理卫生协会</p>
                     <p>*开户行:中国银行合肥梅山路支行</p>
                     <p>*账号: 181225905219</p>
                     <p>*纳税人识别号: 51340000743056355E</p>
                 </article>
                 </div>
             </a-row>
               <!-- 弹窗表格 -->
            <div class="application"  @click="dialogVisible = true">缴纳会费</div>
           <div>
            <el-dialog
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                :z-index="1000"
                width="25%"
                center
                >
                <div style="text-align:center">
                    <p style="color:#83899F;font-size:18px">请选择您的参会渠道</p>
                </div>
                <div>
                    <div :class="{'flex':true,'bd':index==1}"  @click="radio1(1)">
                    <p>会员参与</p> 
                    <div :style="{'display':index==1?'none':'block'}"><input class="inp" type="text" v-model="radios"></div>
                    <div :style="{'display':index==1?'block':'none'}"><img style="width:13px;height:13px;" src="../../assets/img/frame.png" alt=""></div>
                   </div>
                   
                   <div :class="{'flex':true,'bd':index==2}"  @click="radio1(2)">
                    <p>非会员参与</p> 
                    <div :style="{'display':index==2?'none':'block'}"><input  class="inp" type="text" v-model="radios"></div>
                    <div :style="{'display':index==2?'block':'none'}"><img style="width:13px;height:13px;" src="../../assets/img/frame.png" alt=""></div>
                   </div>

                   <div :class="{'flex':true,'bd':index==3}"  @click="radio1(3)">
                    <p>团体参与</p> 
                    <div :style="{'display':index==3?'none':'block'}"><input class="inp" type="text" v-model="radios"></div>
                    <div :style="{'display':index==3?'block':'none'}"><img style="width:13px;height:13px;" src="../../assets/img/frame.png" alt=""></div>
                   </div>

                   <div :class="{'flex':true,'bd':index==4}"  @click="radio1(4)">
                    <p>学生参与</p> 
                    <div :style="{'display':index==4?'none':'block'}"><input class="inp" type="text" v-model="radios"></div>
                    <div :style="{'display':index==4?'block':'none'}"><img style="width:13px;height:13px;" src="../../assets/img/frame.png" alt=""></div>
                   </div>
                </div>
                <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="Dialog()">下一步</el-button>   
                </span>
                </el-dialog>
           </div>
           <el-dialog
  :visible.sync="dialogVisible1"
  :close-on-click-modal="false"
  width="700px"
  :show-close="true"
  center
  >
  <div style="width:69%; text-align: center;font-weight: 600;margin-bottom: 20px;margin:0 auto  10px">(中国)华东心理卫生联盟学术交流会暨2021年安徽省心理卫生协会
学术年会参会回执表</div>
 <table border="1" style="width:100%;table-layout:fixed" cellspacing="0" cellpadding="0" >
    <tr>
    <td colspan="2" class="bg">单位名称</td>
    <td colspan="10" ><input type="text" v-model="Participants.unitName" ></td>
   </tr>
   <tr> 
        <td colspan="2" class="bg">通讯地址</td>
        <td colspan="4"><input type="text" v-model="Participants.address"></td>
        <td colspan="2" class="bg">邮编</td>
        <td colspan="4"><input type="text" v-model="Participants.postCode"></td>
   </tr>
   
   <tr>
    <td colspan="2" class="bg">电子邮箱</td>
    <td colspan="10" ><input type="text" v-model="Participants.email"></td>
   </tr>
   <tr>
    <td colspan="2" class="bg">姓名</td>
    <td colspan="2" class="bg">性别</td>
    <td class="bg" colspan="4">职务/职称</td>
    <td class="bg" colspan="4">手机号码</td>
   
   </tr>
   <tr>
    <td colspan="2"><input type="text" v-model="Participants.personList[0].personName"></td>
    <td colspan="2"><input type="text" v-model="Participants.personList[0].sex"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[0].profess"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[0].phone"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text" v-model="Participants.personList[1].personName"></td>
    <td colspan="2"><input type="text" v-model="Participants.personList[1].sex"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[1].profess"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[1].phone"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text" v-model="Participants.personList[2].personName"></td>
    <td colspan="2"><input type="text" v-model="Participants.personList[2].sex"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[2].profess"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[2].phone"></td>
    </tr>
     <tr>
    <td colspan="2"><input type="text" v-model="Participants.personList[3].personName"></td>
    <td colspan="2"><input type="text" v-model="Participants.personList[3].sex"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[3].profess"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[3].phone"></td>
    </tr>
     <tr>
    <td colspan="2"><input type="text" v-model="Participants.personList[4].personName"></td>
    <td colspan="2"><input type="text" v-model="Participants.personList[4].sex"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[4].profess"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[4].phone"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text" v-model="Participants.personList[5].personName"></td>
    <td colspan="2"><input type="text" v-model="Participants.personList[5].sex"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[5].profess"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[5].phone"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text" v-model="Participants.personList[6].personName"></td>
    <td colspan="2"><input type="text" v-model="Participants.personList[6].sex"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[6].profess"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[6].phone"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text" v-model="Participants.personList[7].personName"></td>
    <td colspan="2"><input type="text" v-model="Participants.personList[7].sex"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[7].profess"></td>
    <td colspan="4"><input type="text" v-model="Participants.personList[7].phone"></td>
    </tr>
    <tr>
    <td colspan="3" class="bg">住宿安排</td>
    <td colspan="9">  <el-checkbox v-model="checked1" @change="test(checked1)"> 1、自行安排</el-checkbox>
        <el-checkbox v-model="checked2" @change="tests(checked2)">2、会务组统一安排，需要 <input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Participants.dorm">个标间</el-checkbox>
    </td>
   </tr>
   <!-- <tr>
    <td colspan="3" class="bg">抵会时间</td>
    <td colspan="3"><input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Arrival.month">月<input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Arrival.day">日</td>
    <td colspan="3" class="bg">离会时间</td>
    <td colspan="3"><input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Leave.month">月<input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Leave.day">日</td>
   </tr> -->

   <tr>
    <td colspan="3" class="bg">抵会时间</td>
    <td colspan="3">
        <div class="datas">
    
    <el-date-picker
    style="width:150px"
    value-format="yyyy-MM-dd HH:mm:ss"
    @change="picker"
    size="mini"
      v-model="Arrival"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
  </div>
    </td>
    <td colspan="3" class="bg">离会时间</td>
    <td colspan="3"> <div class="datas">
   
    <el-date-picker
    style="width:150px"
    value-format="yyyy-MM-dd HH:mm:ss"
    @change="picker1"
    size="mini"
    v-model="Leave"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
  </div></td>
   </tr>
 </table>
 <div style="margin-top:10px">请务必在回执中填写参会代表的手机号码，以便及时收到大会信息变更通知短信。
</div>
  <span slot="footer" class="dialog-footer">
 
    <el-button type="primary" @click="dialog()">立即提交</el-button>
  </span>
            </el-dialog>

            <el-dialog
  :visible.sync="dialogVisible2"
  :close-on-click-modal="false"
  width="700px"
  :show-close="true"
  center
  >
  <div style="width:69%; text-align: center;font-weight: 600;margin-bottom: 20px;margin:0 auto  10px">(中国)华东心理卫生联盟学术交流会暨2021年安徽省心理卫生协会
学术年会参会回执表</div>
 <table border="1" style="width:100%;table-layout:fixed" cellspacing="0" cellpadding="0" >
    <tr>
    <td colspan="2" class="bg" >单位名称</td>
    <td colspan="10" ><input type="text" v-model="Participants.unitName" ></td>
   </tr>
   <tr> 
        <td colspan="2" class="bg">通讯地址</td>
        <td colspan="4"><input type="text" v-model="Participants.address"></td>
        <td colspan="2" class="bg">邮编</td>
        <td colspan="4"><input type="text" v-model="Participants.postCode"></td>
   </tr>
   
   <tr>
    <td colspan="2" class="bg">电子邮箱</td>
    <td colspan="10" ><input type="text" v-model="Participants.email"></td>
   </tr>
   <tr>
    <td colspan="2" class="bg">姓名</td>
    <td colspan="2" class="bg">性别</td>
    <td class="bg" colspan="4">职务/职称</td>
    <td class="bg" colspan="4">手机号码</td>
   
   </tr>
   <tr>
    <td colspan="2"><input type="text" v-model="personList.personName"></td>
    <td colspan="2"><input type="text" v-model="personList.sex"></td>
    <td colspan="4"><input type="text" v-model="personList.profess"></td>
    <td colspan="4"><input type="text" v-model="personList.phone"></td>
    </tr>
    <tr>
    <td colspan="3" class="bg">住宿安排</td>
    <td colspan="9">  <el-checkbox v-model="checked1" @change="test(checked1)"> 1、自行安排</el-checkbox>
        <el-checkbox v-model="checked2" @change="tests(checked2)">2、会务组统一安排，需要 <input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Participants.dorm">个标间</el-checkbox>
    </td>
   </tr>
   <!-- <tr>
    <td colspan="3" class="bg">抵会时间</td>
    <td colspan="3"><input style="border-bottom:1px solid #000;width: 30px;" type="text"  v-model="Arrival.month">月<input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Arrival.day">日</td>
    <td colspan="3" class="bg">离会时间</td>
    <td colspan="3"><input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Leave.month">月<input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Leave.day">日</td>
   </tr> -->
   <tr>
    <td colspan="3" class="bg">抵会时间</td>
    <td colspan="3">
        <div class="datas">
    
    <el-date-picker
    style="width:150px"
    value-format="yyyy-MM-dd HH:mm:ss"
    @change="picker"
    size="mini"
      v-model="Arrival"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
  </div>
    </td>
    <td colspan="3" class="bg">离会时间</td>
    <td colspan="3"> <div class="datas">
   
    <el-date-picker
    style="width:150px"
    value-format="yyyy-MM-dd HH:mm:ss"
    @change="picker1"
    size="mini"
    v-model="Leave"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
  </div></td>
   </tr>
 </table>
 <div style="margin-top:10px">请务必在回执中填写参会代表的手机号码，以便及时收到大会信息变更通知短信。
</div>
  <span slot="footer" class="dialog-footer">
 
    <el-button type="primary" @click="dialogs()">立即提交</el-button>
  </span>
    </el-dialog> 
 
    <el-dialog
  :visible.sync="dialogVisible3"
  :close-on-click-modal="false"
  width="700px"
  :show-close="true"
  center
  >
  <div style="width:69%; text-align: center;font-weight: 600;margin-bottom: 20px;margin:0 auto  10px">(中国)华东心理卫生联盟学术交流会暨2021年安徽省心理卫生协会
学术年会参会回执表</div>
 <table border="1" style="width:100%;table-layout:fixed" cellspacing="0" cellpadding="0" >
    <tr>
    <td colspan="2" class="bg" >单位名称</td>
    <td colspan="10" ><input type="text" v-model="Participants.unitName" ></td>
   </tr>
   <tr> 
        <td colspan="2" class="bg">通讯地址</td>
        <td colspan="4"><input type="text" v-model="Participants.address"></td>
        <td colspan="2" class="bg">邮编</td>
        <td colspan="4"><input type="text" v-model="Participants.postCode"></td>
   </tr>
   
   <tr>
    <td colspan="2" class="bg">电子邮箱</td>
    <td colspan="10" ><input type="text" v-model="Participants.email"></td>
   </tr>
   <tr>
    <td colspan="2" class="bg">姓名</td>
    <td colspan="2" class="bg">性别</td>
    <td class="bg" colspan="4">职务/职称</td>
    <td class="bg" colspan="4">手机号码</td>
   
   </tr>
   <tr>
    <td colspan="2"><input type="text" v-model="personList.personName"></td>
    <td colspan="2"><input type="text" v-model="personList.sex"></td>
    <td colspan="4"><input type="text" v-model="personList.profess"></td>
    <td colspan="4"><input type="text" v-model="personList.phone"></td>
    </tr>
    <tr>
    <td colspan="3" class="bg">住宿安排</td>
    <td colspan="9">  <el-checkbox v-model="checked1" @change="test(checked1)"> 1、自行安排</el-checkbox>
        <el-checkbox v-model="checked2" @change="tests(checked2)">2、会务组统一安排，需要 <input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Participants.dorm">个标间</el-checkbox>
    </td>
   </tr>
   <!-- <tr>
    <td colspan="3" class="bg">抵会时间</td>
    <td colspan="3"><input style="border-bottom:1px solid #000;width: 30px;" type="text"  v-model="Arrival.month">月<input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Arrival.day">日</td>
    <td colspan="3" class="bg">离会时间</td>
    <td colspan="3"><input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Leave.month">月<input style="border-bottom:1px solid #000;width: 30px;" type="text" v-model="Leave.day">日</td>
   </tr> -->
   <tr>
    <td colspan="3" class="bg">抵会时间</td>
    <td colspan="3">
        <div class="datas">
    
    <el-date-picker
    style="width:150px"
    value-format="yyyy-MM-dd HH:mm:ss"
    @change="picker"
    size="mini"
      v-model="Arrival"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
  </div>
    </td>
    <td colspan="3" class="bg">离会时间</td>
    <td colspan="3"> <div class="datas">
   
    <el-date-picker
    style="width:150px"
    value-format="yyyy-MM-dd HH:mm:ss"
    @change="picker1"
    size="mini"
    v-model="Leave"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
  </div></td>
   </tr>
 </table>
 <div style="margin-top:10px">请务必在回执中填写参会代表的手机号码，以便及时收到大会信息变更通知短信。
</div>
  <span slot="footer" class="dialog-footer">
 
    <el-button type="primary" @click="isStudents()">立即提交</el-button>
  </span>
    </el-dialog> 
         </div>
    </div>
  </template>

  <script>
      export default {
        data() {
      return {
        codes:1,
        codese:1,
        dialogVisible :false,
        dialogVisible1: false,
        dialogVisible2: false,
        dialogVisible3:false,
        radios:"",
        isStudent:1,
        index:1,
        Arrival:'',
        Leave:'',
        personList:{
            personName:'',
            sex:'',
            profess:'',
            phone:''
        },
        Participants:{
            unitName:'',
            address:'',
            email:'',
            postCode:'',
            arrivalTime:'',
            leaveTime:'',
            dorm:'',
            personList:[
                {
                personName:'',
                sex:'',
                profess:'',
                phone:''
                },
                {
                personName:'',
                sex:'',
                profess:'',
                phone:''
                },
                {
                personName:'',
                sex:'',
                profess:'',
                phone:''
                },
                {
                personName:'',
                sex:'',
                profess:'',
                phone:''
                },
                {
                personName:'',
                sex:'',
                profess:'',
                phone:''
                },
                {
                personName:'',
                sex:'',
                profess:'',
                phone:''
                },
                {
                personName:'',
                sex:'',
                profess:'',
                phone:''
                },
                {
                personName:'',
                sex:'',
                profess:'',
                phone:''
                },
        ]
        },
      
        checked1:false,
        checked2:false,
        
      };
    },
    mounted(){
       
    },
    methods:{
        Dialog(){
            if(this.index == 1){
                this.$http({
                    url: this.$http.adornUrl('/activityUser/identity'),
                    method: 'get',
                }).then((res) => {
                    console.log(res);
                    if(res.data.code == 500){
                        this.dialogVisible2 =false
                        this.$message.error(res.data.msg);
                        this.index = 2
                    }else{
                        this.index = 1
                        this.dialogVisible2 = true
                    }
                });
            }else if(this.index == 3){
                this.dialogVisible2 = false
                this.dialogVisible1 = true
            }else if(this.index == 4){
                this.dialogVisible2 = false
                this.dialogVisible3 = true
            }else if(this.index == 2){
                this.dialogVisible2 = true
            }
        },
        dialog(){
            console.log(this.Participants);
            let picker = this.Arrival
            let picker1 = this.Leave
            this.Participants.arrivalTime = picker;
            this.Participants.leaveTime = picker1;
            var arr2=this.Participants.personList.filter(function (item) {
                if (item.personName != '' && item.sex != '' && item.profess != '' && item.phone != '') {
                    return item;
                }
                 });
                 console.log(arr2,picker,picker1); 
            if(arr2.length<5){
                // this.dialogVisible3 = true
                this.$message.error('团体参会至少需要5人');
            }else if(this.Participants.unitName == '' || this.Participants.address == '' || this.Participants.arrivalTime == '' || this.Participants.leaveTime == ''){
                this.$message.warning('请完善相关信息');
            }else{
                this.$http({
                    url: this.$http.adornUrl('/activityUnit/addUnit'),
                    method: 'post',
                    data: this.$http.adornData({
                        "unitName":this.Participants.unitName,
                        "address":this.Participants.address,
                        "email":this.Participants.email,
                        "postCode":this.Participants.postCode,
                        "arrivalTime":this.Participants.arrivalTime,
                        "leaveTime":this.Participants.leaveTime,
                        "dorm":this.Participants.dorm,
                        "personList":arr2
                     }),
                }).then((res) => {
                    console.log(res);
                    this.codes = res.data.data.code
                    this.$router.push({ name: "paymentchanne",
        params:{
            id:this.index,
            code:this.codes
        }
    });
                });
            }
        },
        dialogs(){
            let picker = this.Arrival
            let picker1 = this.Leave
            this.Participants.arrivalTime = picker;
            this.Participants.leaveTime = picker1;
            if(this.Participants.unitName == '' || this.Participants.address == '' || this.Participants.arrivalTime == '' || this.Participants.leaveTime == ''){
                this.$message.warning('请完善相关信息');
            }else{
                this.$http({
                    url: this.$http.adornUrl('/activityUser/addPerson'),
                    method: 'post',
                    data: this.$http.adornData({
                        "unitName":this.Participants.unitName,
                        "address":this.Participants.address,
                        "email":this.Participants.email,
                        "postCode":this.Participants.postCode,
                        "arrivalTime":this.Participants.arrivalTime,
                        "leaveTime":this.Participants.leaveTime,
                        "dorm":this.Participants.dorm,
                        "personName":this.personList.personName,
                        "sex":this.personList.sex,
                        "profess":this.personList.profess,
                        "phone":this.personList.phone,
                     }),
                }).then((res) => {
                    console.log(res);
                    this.codese = res.data.data.code
                    this.$router.push({ name: "paymentchanne",params:{
                        id:this.index,
                        codes:this.codese
                    }
                });
                });
            }
        },
        // 学生入会
        isStudents(){
            let picker = this.Arrival
            let picker1 = this.Leave
            this.Participants.arrivalTime = picker;
            this.Participants.leaveTime = picker1;
            if(this.Participants.unitName == '' || this.Participants.address == '' || this.Participants.arrivalTime == '' || this.Participants.leaveTime == ''){
                this.$message.warning('请完善相关信息');
            }else{
                this.$http({
                    url: this.$http.adornUrl('/activityUser/addPerson'),
                    method: 'post',
                    data: this.$http.adornData({
                        "unitName":this.Participants.unitName,
                        "address":this.Participants.address,
                        "email":this.Participants.email,
                        "postCode":this.Participants.postCode,
                        "arrivalTime":this.Participants.arrivalTime,
                        "leaveTime":this.Participants.leaveTime,
                        "dorm":this.Participants.dorm,
                        "personName":this.personList.personName,
                        "sex":this.personList.sex,
                        "profess":this.personList.profess,
                        "phone":this.personList.phone,
                        "isStudent":this.isStudent
                     }),
                }).then((res) => {
                    console.log(res);
                    this.codese = res.data.data.code
                    this.$router.push({ name: "paymentchanne",params:{
                        id:this.index,
                        codes:this.codese,
                   
                    }
                });
            });
            }
        },
        radio1(num){
            this.index = num
        },
        test(checked1){
            this.checked2 = checked1 == true ? false : true
        },
        tests(checked2){
            this.checked1 = checked2 == true ? false : true
        },
        picker(){
            console.log(this.Arrival);
        },
        picker1(){
            console.log(this.Leave);
        }
    }
  };
  </script>
  
  <style scoped>
  *{
     padding: 0;
     margin: 0;
  }
  .association{
         background: #ffffff;
     }
     .explainL{
         height: 50px;
         line-height: 50px;
         width: 1080px;
         margin: 0 auto;
         text-align: left;
         /* background:#1b1a1a */
     }
     .routeL{
         cursor: pointer;
     }
    .cont{
         width: 1080px;
         margin: 20px auto;
         background: #ffffff;
         padding:50px 20px;
     }
     .title{
         width: 100%;
         font-size: 16px;
         font-weight: 600;
         text-align: center;
     }
     .title>p{
         margin-top: 20px;
         font-size: 12px;
         font-weight: 600;
         color: #7A777B;
     }
     .boxL{
         padding: 0px 40px;
       
     }
     article>p{
         line-height: 24px;
         text-align: left;
         text-indent:30px
         
     }
     .application{
        width: 200px;
        height: 40px;
        background: #FDC023;
        margin: 40px  auto 20px;
        text-align: center;
        line-height: 40px;
        color: white;
        cursor: pointer;
     }
     .flex{
        width: 250px;
        height: 60px;
        line-height: 60px;
        margin: 10px auto;
        padding:  0 20px;
        background: #F8F6F9;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
     }
     .bd{
        border: 2px solid #F3C443;
        background: white;
        border-radius: 5px;
     }
     .none{
        display: none;
     }
     .block{
        display: block;
     }
     .inp{
        width:13px;
        height:13px;
        border: 1px solid #767676;
        border-radius: 50%;
        cursor: pointer;

     }
     table {
        text-align: center;
     }
     table tr{
        width: 100%;
        height: 30px;
     }
    table tr td{
        height: 30px;
    }
    
    table input{
        width: 100%;
        border: none;
        text-align: center;
    }
    .bg{
        background: #F4F6F8;
    }
   /*  .message{
        z-index: 3000 !important;
    } */
    .datas{
      margin: 0 auto;
    }
  </style>
  