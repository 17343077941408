<template>
    <div>
        <div class="association">
            <div class="explainL">
                <span>当前位置：</span>
                <span class="routeL">在线考试</span>
            </div>
        </div>
        <div class="brief">
            <el-row :gutter="20">
                <el-col :span="6">
                    <div class="grid-content bg-purple">
                        <div style="font-weight: bold;">答题卡</div>
                        <div>单选题（共{{ single }}题,合计{{ singleScore }}分）</div>
                        <ul class="ulList">
                            <li v-for="item in radio" :class="{'active':item.score === true? true:false}" :key="item.num" @click="clickNum(item)">{{ item.num }}</li>
                            <div style="clear: both;"></div>
                        </ul>
                        <div>多选题（共{{ multiple }}题,合计{{ multipleScore }}分）</div>
                        <ul class="ulList">
                            <li v-for="(item) in selection" :class="{'active':item.score === true? true:false}" :key="item.num" @click="clickNum(item)">{{ item.num }}</li>
                            <div style="clear: both;"></div>
                        </ul>
                        <div>填空题（共{{ judge }}题,合计{{ judgeScore }}分）</div>
                        <ul class="ulList">
                            <li v-for="(item) in judgment" :class="{'active':item.score === true? true:false}" :key="item.num" @click="clickNum(item)">{{ item.num }}</li>
                            <div style="clear: both;"></div>
                        </ul>
                        <div style="text-align: center;font-weight: bold;padding: 20px;">考试剩余时间：<span class="time">{{timeList}}</span></div>
                        <div style="text-align: center;">
                            <el-button type="success" size ="mini" icon="view"  @click="upAnser" :disabled="!idFlag">提交答案</el-button>
                        </div>
                    </div>
                </el-col>
                <el-col :span="18">
                    <div class="grid-content bg-purple">
                        <div class="gutter-box">
                            <!--单选题-->
                            <div v-show="box.examType === 0" class="content">
                                <div>单选题（共{{ single }}题,合计{{ singleScore }}分）</div>
                                <p>第{{box.num}}题.{{box.content}}</p>
                                <div v-for="(item,index) in box.optionList" style="margin: 10px 0;" :key="index">
                                    <el-radio @click.native="clickRadio($event,item,box.num)" v-model="radioList" :label="item+'-'+box.num">{{item}}</el-radio>
                                </div>
                            </div>
                            <!--多选题-->
                            <div v-show="box.examType === 1" class="content">
                                <div>多选题（共{{ multiple }}题,合计{{ multipleScore }}分）</div>
                                <p>第{{box.num}}题.{{box.content}}</p>
                                <el-checkbox-group v-model="checkList" @change="clickRadioGroup(box.num)">
                                    <div v-for="(item,index) in box.optionList" style="margin: 10px 0;" :key="index">
                                        <el-checkbox :key="index" :label="item.split('.')[0]+'-'+box.num">{{item}}</el-checkbox>
                                    </div>
                                </el-checkbox-group>
                            </div>
                            <!--填空题-->
                            <div v-show="box.examType === 2" class="content">
                                <div>判断题（共{{ judge }}题,合计{{ judgeScore }}分）</div>
                                <p>第{{box.num}}题.{{box.content}}</p>
                                <div style="margin: 10px 0;">
                                    <el-radio @click.native="clickRadioP($event,'T',box.num)" v-model="judgeList" :label="'T-'+box.num">正确</el-radio>
                                </div>
                                <div style="margin: 10px 0;">
                                    <el-radio @click.native="clickRadioP($event,'F',box.num)" v-model="judgeList" :label="'F-'+box.num">错误</el-radio>
                                </div>
                            </div>
                            <div style="text-align: center;">
                                <el-button @click="upper">上一题</el-button> <el-button @click="next()">下一题</el-button>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
               <el-dialog
                    :visible.sync="dialogVisible"
                    :close-on-click-modal="false"
                     width="300px"
                    center
                    >
                    <div style="width:210px;text-align:center;margin:auto">
                        还有试题未作答，确定交卷吗？
                        <el-button type="primary" style="margin-top:20px" @click="dialogVisible1()">确定</el-button>
                    </div>
                    </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                dialogVisible:false,
                id: this.$route.query.id,
                testId: this.$route.query.testId1,
                single:0,//单选题数目
                singleScore:0,//单选题总共多少分
                multiple:0,//多选题数目
                multipleScore:0,//多选题总共多少分
                judge:0,//填空题数目
                judgeScore:0,//填空题总共多少分
                content:[],//所有题目  examType 单选0 多选1 填空2
                number:0,//当前题目个数
                radio:[],//单选题
                selection:[],//多选题
                judgment:[],//填空题
                box:[],//当前渲染的题目
                radioList:'',//当前选中的单选题
                checkList:[],//当前选中的多选题
                judgeList:'',//当前选中的判断题
                answer:[],//存储答案
                idFlag:true,
                timer:null,//定时器
                timeList:'00:00:00',//倒计时显示
            };
        },
        mounted() {
            this.Questions();
            this.getTimer();
        },
        methods: {
            //获取考试题目
            Questions() {
                this.$http({
                    url: this.$http.adornUrl("/testPaper/showPaper"),
                    method: "get",
                    params: this.$http.adornParams({
                        paperId: this.id,
                    }),
                }).then((res) => {
                    if(res.data.code === 200){
                        this.single = res.data.data.single;//单选题数目
                        this.singleScore = res.data.data.singleScore;//单选题总共多少分
                        this.multiple = res.data.data.multiple;//多选题数目
                        this.multipleScore = res.data.data.multipleScore;//多选题总共多少分
                        this.judge = res.data.data.judge;//填空题数目
                        this.judgeScore = res.data.data.judgeScore;//填空题总共多少分
                        this.content = res.data.data.content;//所有题目  examType 单选0 多选1 填空2single:0,//单选题数目
                        res.data.data.content.forEach((v, i) => {
                            if (v.examType == 0) {
                                this.radio.push(v);
                            } else if (v.examType == 1) {
                                this.selection.push(v);
                            } else if (v.examType == 2) {
                                this.judgment.push(v);
                            }
                        });
                        this.box = res.data.data.content[this.number];
                    }else{
                        this.$message.error(res.data.data.msg);
                    }
                });
            },
            //绑定选择的数据
            getData(){
                if(this.answer.length>0){
                    if(this.box.examType === 0){//单选题
                        for(let k in this.answer){
                            if(this.answer[k].num === this.box.num){
                                this.radioList= this.answer[k].answer+'-'+this.box.num;
                            }
                        }
                    }else if(this.box.examType === 1){//多选题
                        for(let k in this.answer){
                            if(this.answer[k].num === this.box.num.toString()){
                                let arrList = [];
                                if(this.answer[k].answer.length == 1){
                                    arrList.push(this.answer[k].answer+'-'+this.box.num);
                                }else if(this.answer[k].answer.length > 1){
                                    let arr = this.answer[k].answer.split(',');
                                    for(let i in arr){
                                        arrList.push(arr[i]+'-'+this.box.num)
                                    }
                                }
                                this.checkList = arrList;
                            }
                        }
                    }else if(this.box.examType === 2){//判断题
                        for(let k in this.answer){
                            if(this.answer[k].num === this.box.num){
                                this.judgeList= this.answer[k].answer+'-'+this.box.num;
                            }
                        }
                    }
                }
            },
            //点击左侧获取题目
            clickNum(val){
                this.checkList = [];
                this.box = this.content[val.num-1];
                this.number = val.num-1;
                this.getData();
            },
            //数组排序
            compare(property){
                return function(a,b){
                    var value1 = a[property];
                    var value2 = b[property];
                    return value1 - value2;
                }
                //console.log(arr.sort(compare('age')))
            },
            //点击查看上一题
            upper(){
                this.checkList = [];
                if(this.number>0){
                    this.number--;
                    this.box = this.content[this.number];
                    this.getData();
                }else{
                    this.$message.error('暂无上一题');
                }
            },

            //点击查看下一题
            next(){
                this.checkList = [];
                if(this.number>this.content.length-2){
                    this.$message.error('暂无下一题');
                }else{
                    this.number++;
                    this.box = this.content[this.number];
                    this.getData();
                }
            },
            clickRadio(e,val,num){
                //单选点击事件
                if (e.target.tagName === 'INPUT') return;
                if(this.answer.length>0){
                    for(let k in this.answer){
                        if(this.answer[k].num === num){
                            this.answer.splice(k,1);
                        }
                    }
                }
                this.answer.push(
                    {
                        'num':num,
                        'answer':val.split('-')[0]
                    }
                );
                for(let k in this.radio){
                    if(this.radio[k].num === num){
                        this.radio[k].score = true;

                    }
                }
            },
            clickRadioGroup(num){
                // console.log(this.checkList,num);
                //多选题点击事件
                if(this.checkList.length>0){
                    if(this.answer.length>0){
                        for(let k in this.answer){
                            if(this.answer[k].num === this.checkList[0].split('-')[1]){
                                this.answer.splice(k,1);
                            }
                        }
                    }
                    let arr = [];
                    for(let k in this.checkList){
                        arr.push(this.checkList[k].split('-')[0])
                    }
                    this.answer.push(
                        {
                            'num':this.checkList[0].split('-')[1],
                            'answer':arr.toString()
                        }
                    )
                    for(let k in this.selection){
                        if(this.selection[k].num === num){
                            this.selection[k].score = true;
                        }
                    }
                }else{
                    for(let k in this.selection){
                        if(this.selection[k].num === num){
                            this.selection[k].score = false;
                        }
                    }
                    for(let k in this.answer){
                        if(this.answer[k].num == num){
                            this.answer.splice(k,1);
                        }
                    }
                }
            },
            //判断题
            clickRadioP(e,val,num){
                //判断题点击事件
                if (e.target.tagName === 'INPUT') return;
                if(this.answer.length>0){
                    for(let k in this.answer){
                        if(this.answer[k].num === num){
                            this.answer.splice(k,1);
                        }
                    }
                }
                this.answer.push(
                    {
                        'num':num,
                        'answer':val
                    }
                );
                for(let k in this.judgment){
                    if(this.judgment[k].num === num){
                        this.judgment[k].score = true;
                    }
                }
            },
            formatSeconds(time) {
                // 转换为式分秒
                let h = parseInt(time / 60 / 60 % 24);
                h = h < 10 ? '0' + h : h;
                let m = parseInt(time / 60 % 60);
                m = m < 10 ? '0' + m : m;
                let s = parseInt(time % 60);
                s = s < 10 ? '0' + s : s;
                // 作为返回值返回
                return h+':'+m+':'+s;
            },
            //倒计时
            getTimer(){
                let _this = this;
                _this.timer = setInterval(function () {
                    _this.$http({
                        url: _this.$http.adornUrl('/testManage/judgeTime'),
                        method: 'get',
                        params: _this.$http.adornParams({
                            testId: _this.testId,
                        }),
                    }).then((res) => {
                        if(res.data.code === 200){
                            if(res.data.data>0){
                                _this.timeList = _this.formatSeconds(res.data.data);
                            }else{
                                _this.timeList = '00:00:00';
                                clearInterval(_this.timer);
                                _this.dialogVisible1();
                                this.$message.success('交卷成功！');
                            }
                        }
                    });
                },1000)
            },
            //提交答案
            upAnser(){
                let num = this.single + this.multiple + this.judge
                if(this.answer.length < num){
                    this.dialogVisible = true
                }else{
                    this.answer = this.answer.sort(this.compare('num'));
                let arr = [];
                for(let i in this.answer){
                    if(this.answer[i].answer.split('.')[0]){
                        arr.push({
                            'num':this.answer[i].num.toString(),
                            'answer':this.answer[i].answer.split('.')[0]
                        })
                    }else{
                        arr.push({
                            'num':this.answer[i].num,
                            'answer':this.answer[i].answer
                        })
                    }
                }
                //console.log(arr);//最后提交的答案
                this.$http({
                    url: this.$http.adornUrl('/testUser/commit'),
                    method: 'post',
                    data: this.$http.adornData({
                        'paperId': Number(this.id),
                        'testId': Number(this.testId),
                        'answer': arr
                    }),
                }).then((res) => {
                    if(res.data.code === 200){
                        this.idFlag = false;
                        this.timeList = '00:00:00';
                        clearInterval(this.timer);
                        this.$message.success('提交成功！');
                        this.$router.push({name: "examination"});
                    }
                });
                }
            },
            dialogVisible1(){
                this.answer = this.answer.sort(this.compare('num'));
                let arr = [];
                for(let i in this.answer){
                    if(this.answer[i].answer.split('.')[0]){
                        arr.push({
                            'num':this.answer[i].num.toString(),
                            'answer':this.answer[i].answer.split('.')[0]
                        })
                    }else{
                        arr.push({
                            'num':this.answer[i].num,
                            'answer':this.answer[i].answer
                        })
                    }
                }
                //console.log(arr);//最后提交的答案
                this.$http({
                    url: this.$http.adornUrl('/testUser/commit'),
                    method: 'post',
                    data: this.$http.adornData({
                        'paperId': Number(this.id),
                        'testId': Number(this.testId),
                        'answer': arr
                    }),
                }).then((res) => {
                    if(res.data.code === 200){
                        this.idFlag = false;
                        this.timeList = '00:00:00';
                        clearInterval(this.timer);
                        this.$message.success('提交成功！');
                        this.$router.push({name: "examination"});
                    }
                });
            }
        },
    };
</script>
<style scoped>
    .bg-purple{
        background-color: #ffffff;
        padding: 15px 10px;
    }
    .ulList>li{
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        margin-top: 15px;
        margin-right: 3px;
        border: 1px solid #bdbdbd;
        border-radius: 50%;
        display: inline-block;
        float: left;
        cursor: pointer;
    }
    .content{
        padding: 20px;
        white-space: pre-line
    }
    .content>p{
        font-weight: bold;
        margin-top: 20px;
    }
    .active{
        background-color: #0EC2DB;
        color: #ffffff;
    }
    .time{
        color: #6fcbf5;
    }
</style>