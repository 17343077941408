import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import merge from 'lodash/merge'
import { clearLoginInfo } from '@/utils'

const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
    config.headers['Authorization'] = Vue.cookie.get('token');// 请求头带上token
    return config
}, error => {
  return Promise.reject(error)
});

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  // console.log(response.data , response.data.code)
  if (response.data && response.data.code === 401) { // 401, token失效
      http({
          url: http.adornUrl("/auth/logout"),
          method: "DELETE",
      }).then((res) => {
          if (res.data.code == 200) {
              this.$message.success('登录信息过期重新登录');
              this.$emit("loginStates", "fail");
              this.show = true;
              this.$cookie.set("roleid", "");
              this.$cookie.set("token", "");
              this.$cookie.set("username", "");
              this.$cookie.set("useridList", "");
              this.$cookie.set("avatarName", "");
              router.push({ name: 'dashBoard' })
          }
      });
      router.push({ name: "login" });
  }
  return response
}, error => {
    // console.log(error)
    if(error.response.status === 401){
        http({
            url: http.adornUrl("/auth/logout"),
            method: "DELETE",
        }).then((res) => {
            if (res.data.code == 200) {
                this.$message.success('登录信息过期重新登录');
                this.$emit("loginStates", "fail");
                this.show = true;
                this.$cookie.set("roleid", "");
                this.$cookie.set("token", "");
                this.$cookie.set("username", "");
                this.$cookie.set("useridList", "");
                this.$cookie.set("avatarName", "");
                router.push({ name: 'dashBoard' })
            }
        });
        router.push({ name: "login" });
    }
  return Promise.reject(error)
});

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxy-api/' : 'http://10.100.159.238:8006') + actionName;
  return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/prod-api' : '/prod-api') + actionName;

};

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    't': new Date().getTime()
  };
  return openDefultParams ? merge(defaults,params) : params
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  var defaults = {
    't': new Date().getTime()
  };
  data = openDefultdata ? merge(defaults, data) : data;
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data);
};

export default http;
