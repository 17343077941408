<template>
    <div>
        <div class="association">
            <div class="explainL">
                <span>当前位置：</span>
                <span class="routeL">首页></span>
                <span>心理问答</span>
            </div>
        </div>
        <div class="brief">
            <el-row :gutter="20">
                <el-col :span="16" v-show="!putQuestion">
                    <div class="grid-content bg-purple">
                        <!--导航-->
                        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                            <el-menu-item index="1">热门回答</el-menu-item>
                            <el-menu-item index="2">最新回答</el-menu-item>
                            <el-menu-item index="3">推荐问题</el-menu-item>
                            <el-menu-item index="4">我的关注<span v-show="wdgz>0">（{{wdgz}}）</span></el-menu-item>
                            <el-menu-item index="5">我的收藏<span v-show="wdsc>0">（{{wdsc}}）</span></el-menu-item>
                        </el-menu>
                        <!--所有热门回答-->
                        <div v-show="type == 1" class="typeList">
                            <div class="typeItem" v-for="(item,index) in answerList" :key="index">
                                <el-row>
                                    <el-col :span="2">
                                        <div class="grid-content bg-purple">
                                            <el-avatar size="large" :src="baseUrl+item.photo"></el-avatar>
                                        </div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div class="grid-content bg-purple">
                                            <p><span class="name">{{item.nickname}}</span><span class="hospital">{{item.department}}</span></p>
                                            <p class="js">{{item.professionalTitle}}</p>
                                            <p class="sh"><span>{{item.createTime}}</span>  回答了：</p>
                                            <p class="titleW">{{item.title}}</p>
                                            <div class="text">
                                                {{item.content}}
                                            </div>
                                            <div class="zgs">
                                                <div>
                                                    <img :src="[item.like == true ? zt2 : zt]" alt="" @click="ztT(item.id,item.like,index,1)">
                                                    <span>赞同</span>
                                                </div>
                                                <div>
                                                    <img :src="[item.collect == true ? sc2 : sc]" alt="" @click="scT(item.id,item.collect,index,1)">
                                                    <span>收藏</span>
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="pagination">
                                <el-pagination background layout="total, prev, pager, next" :current-page.sync="pageIndex"
                                               :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange1"></el-pagination>
                            </div>
                        </div>
                        <!--最新回答-->
                        <div v-show="type == 2" class="typeList">
                            <div class="typeItem" v-for="(item,index) in answerList" :key="index">
                                <el-row>
                                    <el-col :span="2">
                                        <div class="grid-content bg-purple">
                                            <el-avatar size="large" :src="baseUrl+item.photo"></el-avatar>
                                        </div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div class="grid-content bg-purple">
                                            <p><span class="name">{{item.nickname}}</span><span class="hospital">{{item.department}}</span></p>
                                            <p class="js">{{item.professionalTitle}}</p>
                                            <p class="sh"><span>{{item.createTime}}</span>  回答了：</p>
                                            <p class="titleW">{{item.title}}</p>
                                            <div class="text">
                                                {{item.content}}
                                            </div>
                                            <div class="zgs">
                                                <div>
                                                    <img :src="[item.like == true ? zt2 : zt]" alt="" @click="ztT(item.id,item.like,index,2)">
                                                    <span>赞同</span>
                                                </div>
                                                <div>
                                                    <img :src="[item.collect == true ? sc2 : sc]" alt="" @click="scT(item.id,item.collect,index,2)">
                                                    <span>收藏</span>
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="pagination">
                                <el-pagination background layout="total, prev, pager, next" :current-page.sync="pageIndex"
                                               :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange2"></el-pagination>
                            </div>
                        </div>
                        <!--推荐问题-->
                        <div v-show="type == 3" class="typeList">
                            <!--推荐问题-->
                            <div class="typeItem" v-show="!hotP&&!myType" v-for="(item,index) in recommend" :key="index">
                                <el-row>
                                    <el-col :span="2">
                                        <div class="grid-content bg-purple">
                                            <el-avatar size="large" v-if="item.avatarName == ''" :src="circleUrl"></el-avatar>
                                            <el-avatar size="large" v-else :src="baseUrl+item.avatarName"></el-avatar>
                                        </div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div class="grid-content bg-purple">
                                            <p>
                                                <span class="nameT" v-if="item.auditStatus == 1">匿名</span>
                                                <span class="nameT" v-else>{{item.nickname}}</span>
                                                <span class="timeT">{{item.createTime}}</span>
                                            </p>
                                            <p class="shX">
                                                <span>{{item.collect}}收藏</span>
                                                <span>{{item.answer}}个回答</span>
                                            </p>
                                            <p class="sctw" @click="scT(item.id,item.collectOwn,index,3)" v-if="item.collectOwn == true">取消收藏</p>
                                            <p class="sctw" @click="scT(item.id,item.collectOwn,index,3)" v-else>收藏提问</p>
                                            <p class="titleW" style="cursor: pointer;" @click="activeClick(item.id)">{{item.title}}</p>
                                            <div>
                                                {{item.content}}
                                            </div>
                                            <div class="zgs">
                                                <div class="activeXX" v-for="(itemList,index) in item.tagList" :key="index">
                                                    {{itemList.domain}}
                                                </div>
                                            </div>
                                            <!--回答问题-->
                                            <div v-if="roleId < 3 && roleId > 0" class="hdan" @click="hdW(item.id)">
                                                 {{ HDWT.indexOf(item.id) > -1 ? '放弃回答' : '回答问题'}}
                                            </div>
                                            <el-input type="textarea" :rows="4" v-show="HDWT.indexOf(item.id) > -1" v-model="hd" placeholder="请输入你的回答..."></el-input>
                                            <div class="hdanT" @click="fbhd(item.id)" v-show="HDWT.indexOf(item.id) > -1">
                                                发布回答
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <!--我的问题-->
                            <div class="typeItem" v-show="myType"  v-for="(item,index) in myW" :key="index">
                                <el-row>
                                    <el-col :span="24">
                                        <div class="grid-content bg-purple">
                                            <p class="shX">
                                                <!--<span>50浏览</span>-->
                                                <span>{{item.collect}}收藏</span>
                                                <span>{{item.answer}}个回答</span>
                                                <span style="float: right;">{{item.createTime}}</span>
                                            </p>
                                            <p class="titleW">{{item.title}}</p>
                                            <div>
                                                {{item.content}}
                                            </div>
                                            <div class="zgs">
                                                <div class="activeXX" v-for="(itemList,index) in item.tagList" :key="index">
                                                    {{itemList.domain}}
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <!--热门问题-->
                            <div class="typeItem" v-show="hotP">
                                <el-row>
                                    <el-col :span="2">
                                        <div class="grid-content bg-purple">
                                            <el-avatar size="large" v-if="hotData.avatarName == ''" :src="circleUrl"></el-avatar>
                                            <el-avatar size="large" v-else :src="baseUrl+hotData.avatarName"></el-avatar>
                                        </div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div class="grid-content bg-purple">
                                            <p>
                                                <span class="nameT" v-if="hotData.auditStatus == 1">匿名</span>
                                                <span class="nameT" v-else>{{hotData.nickname}}</span>
                                                <span class="timeT">{{hotData.createTime}}</span>
                                            </p>
                                            <p class="shX">
                                                <!--<span>50浏览</span>-->
                                                <span>{{hotData.collect}}收藏</span>
                                                <span>{{hotData.answer}}个回答</span>
                                            </p>
                                            <p class="sctw" @click="scT(hotData.id,hotData.collectOwn,0,6,1)" v-if="hotData.collectOwn == true">取消收藏</p>
                                            <p class="sctw" @click="scT(hotData.id,hotData.collectOwn,0,6,1)" v-else>收藏提问</p>
                                            <p class="titleW">{{hotData.title}}</p>
                                            <div>
                                                {{hotData.content}}
                                            </div>
                                            <div class="zgs">
                                                <div class="activeXX" v-for="(item,index) in hotData.tagList" :key="index">
                                                    {{item.domain}}
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <!--热门问题的回答-->
                            <div class="typeItem" v-for="(item,index) in hotListA" :key="index" v-show="hotP">
                                <el-row>
                                    <el-col :span="2">
                                        <div class="grid-content bg-purple">
                                            <el-avatar size="large" :src="baseUrl+item.photo"></el-avatar>
                                        </div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div class="grid-content bg-purple">
                                            <p><span class="name">{{item.nickname}}</span><span class="hospital">{{item.department}}</span></p>
                                            <p class="js">{{item.professionalTitle}}</p>
                                            <p class="sh"><span>{{item.createTime}}</span>  回答了：</p>
                                            <!--<p class="titleW">{{item.title}}</p>-->
                                            <div class="text">
                                                {{item.content}}
                                            </div>
                                            <div class="zgs">
                                                <div>
                                                    <img :src="[item.like == true ? zt2 : zt]" alt="" @click="ztT(item.id,item.like,index,6)">
                                                    <span>赞同</span>
                                                </div>
                                                <div>
                                                    <img :src="[item.collect == true ? sc2 : sc]" alt="" @click="scT(item.id,item.collect,index,6,2)">
                                                    <span>收藏</span>
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="pagination" v-show="recommend!=''&&!myType">
                                <el-pagination background layout="total, prev, pager, next" :current-page.sync="pageIndex"
                                               :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange3"></el-pagination>
                            </div>
                            <div class="pagination" v-show="myType">
                                <el-pagination background layout="total, prev, pager, next" :current-page.sync="pageIndex"
                                               :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange4"></el-pagination>
                            </div>
                        </div>
                        <!--我的关注-->
                        <div v-show="type == 4" class="typeList">
                            <div class="typeItem" v-for="(item,index) in doctorData" :key="index">
                                <el-row>
                                    <el-col :span="2">
                                        <div class="grid-content bg-purple">
                                            <el-avatar size="large" v-if="item.consultDoctor.photo == ''" :src="circleUrl"></el-avatar>
                                            <el-avatar size="large" v-else :src="baseUrl+item.consultDoctor.photo"></el-avatar>
                                        </div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div class="grid-content bg-purple">
                                            <p><span class="name">{{item.consultDoctor.nickname}}</span><span class="hospital">{{item.consultDoctor.department}}</span></p>
                                            <p class="js">{{item.consultDoctor.professionalTitle}}</p>
                                            <img style="cursor: pointer;" @click="gotodetail(item.consultDoctor.id,item.consultDoctor.userId)" class="ckImg" src="../../assets/img/ck.png" alt="">
                                            <p class="titleZ">{{item.consultDoctor.description}}</p>
                                            <div style="text-align: right;">
                                                <img style="cursor: pointer;" :src="gz2" alt="" @click="gzT(item.consultDoctor.userId,index)">
                                                <span>关注</span>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                        <!--我的收藏-->
                        <div v-show="type == 5" class="typeList">
                            <div class="typeItem" v-for="(item,index) in Sc" :key="index" v-if="item.type == 1">
                                <el-row>
                                    <el-col :span="2">
                                        <div class="grid-content bg-purple">
                                            <el-avatar size="large" v-if="item.data.photo == ''" :src="circleUrl"></el-avatar>
                                            <el-avatar size="large" v-else :src="baseUrl+item.data.photo"></el-avatar>
                                        </div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div class="grid-content bg-purple">
                                            <p><span class="name">{{item.data.nickname}}</span><span class="hospital">{{item.data.department}}</span></p>
                                            <p class="js">{{item.data.professionalTitle}}</p>
                                            <p class="sh"><span>{{item.data.createTime}}</span>  回答了：</p>
                                            <p class="titleW">{{item.data.title}}</p>
                                            <div>
                                                {{item.data.problemContent}}
                                            </div>
                                            <div class="zgs">
                                                <div>
                                                    <img :src="[item.data.problemAnswer.like == true ? zt2 : zt]" alt="">
                                                    <span>赞同</span>
                                                </div>
                                                <div>
                                                    <img :src="[item.data.problemAnswer.collect == true ? sc2 : sc]" alt=""  @click="scT(item.data.problemAnswerId,item.data.problemAnswer.collect,index,5,1)">
                                                    <span>收藏</span>
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="typeItem" v-for="(item,index) in Sc" :key="index" v-if="item.type == 2">
                                <el-row>
                                    <el-col :span="2">
                                        <div class="grid-content bg-purple">
                                            <el-avatar size="large" v-if="item.data.avatarName == ''" :src="circleUrl"></el-avatar>
                                            <el-avatar size="large" v-else :src="baseUrl+item.data.avatarName"></el-avatar>
                                        </div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div class="grid-content bg-purple">
                                            <p>
                                                <span class="nameT" v-if="item.data.auditStatus == 1">匿名</span>
                                                <span class="nameT" v-else>{{item.data.nickname}}</span>
                                                <span class="timeT">{{item.data.createTime}}</span>
                                            </p>
                                            <p class="shX">
                                                <span>{{item.data.isDelete}}收藏</span>
                                                <span>{{item.data.isDelete}}个回答</span>
                                            </p>
                                            <p class="sctw" @click="scT(item.data.problemId,item.data.problem.collectOwn,index,5,2)" v-if="item.data.problem.collectOwn == true">取消收藏</p>
                                            <p class="sctw" @click="scT(item.data.problemId,item.data.problem.collectOwn,index,5,2)" v-else>收藏提问</p>
                                            <p class="titleW">{{item.data.problem.title}}</p>
                                            <div>
                                                {{item.data.problem.content}}
                                            </div>
                                            <div class="zgs">
                                                <div v-for="(itemList, index) in item.data.problem.tagList" :key="index" class="bq activeX">
                                                    {{ itemList.domain }}
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </el-col>
                <!--我要提问-->
                <el-col :span="16" v-show="putQuestion">
                    <div class="grid-content bg-purple divs" style="background: #ffffff;padding: 15px;">
                        <p class="pps">发布提问</p>
                        <p class="ppp">建议描述</p>
                        <p class="ppp">【身份背景】如，今年30岁，性别女</p>
                        <p class="ppp">【问题详细】如，失眠多梦，压力大，有轻微的头痛状况</p>
                        <p class="ppp">【问题时长】如，最近一周</p>
                        <p class="ppf">提问标题*</p>
                        <el-form ref="form" :model="form" :rules="rules">
                            <el-form-item prop="title">
                                <el-input type="textarea" v-model="form.title" placeholder="请您输入5~60字的标题"></el-input>
                            </el-form-item>
                            <p class="ppf">提问内容*</p>
                            <el-form-item prop="content">
                                <el-input :rows="5" maxlength="200" type="textarea" v-model="form.content" placeholder="请您输入5~200字的提问内容（请勿填写敏感词汇，在您填写完成后，系统将自动识别）"></el-input>
                            </el-form-item>
                            <p class="ppf">请选择标签（至少一个）*</p>
                            <div class="flexTX">
                                <div v-for="(item, index) in TypeList" :key="index" class="bq" :class="{ activeX: gatherX.indexOf(item.id) > -1 }" @click="onPitchX(item.id)">
                                    {{ item.domain }}
                                </div>
                            </div>
                            <el-form-item>
                                <el-checkbox-group v-model="form.type">
                                    <el-checkbox label="匿名发布" name="type"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item style="text-align: right;position: absolute;bottom: 22px;right: 36%;">
                                <el-button size="small" type="primary" @click="onSubmit">立即发布</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <div class="grid-content bg-purple" style="background: #ffffff;padding: 15px;">
                                    <div class="flexT">
                                        <div class="flexTT" @click="putQ()">我要提问</div>
                                        <div class="flexTT" @click="myQ()">我的问题</div>
                                    </div>
                                </div>
                            </el-col>
                            <!--问题类型-->
                            <el-col :span="24" style="margin-top: 20px;">
                                <div class="grid-content bg-purple" style="background: #ffffff;padding: 15px;">
                                    <p class="lx">问题类型</p>
                                    <div class="flexTX">
                                        <div v-for="(item, index) in TypeList" :key="index" class="bq" :class="{ activeX: gather.indexOf(item.id) > -1 }" @click="onPitch(item.id)">
                                            {{ item.domain }}
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <!--热门提问-->
                            <el-col :span="24" style="margin-top: 20px;">
                                <div class="grid-content bg-purple" style="background: #ffffff;padding: 15px;">
                                    <p class="lx">热门提问</p>
                                    <div class="rmtw" v-for="(item,index) in HotList" :key="index">
                                        <p @click="activeClick(item.id,index)" :class="[isActive == index ? 'activeT':'']">{{item.title}}</p>
                                        <p>
                                            <!--<span>{{item.liu}}</span>-->
                                            <span>{{item.collect}}收藏</span>
                                            <span class="dd"></span>
                                            <span>{{item.answer}}回答</span>
                                        </p>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import { getUrl } from "../../utils";

    export default {
        name: "index",
        watch: {
            info() {
                this.showall = false
            },
        },
        data(){
            return {
                activeIndex: '1',
                roleId:this.$cookie.get('roleid'),
                hd:'',
                form:{
                    title:'',
                    content:'',
                    type:false,
                },
                rules: {
                    title: [
                        { required: true, message: '请输入标题', trigger: 'blur' },
                    ],
                    content: [
                        { required: true, message: '请输入内容', trigger: 'blur' },
                    ]
                },
                wdgz:0,
                wdsc:0,
                putQuestion:false,
                baseUrl:getUrl(),
                userId:this.$cookie.get('useridList'),
                type: 1,
                hotP:false,
                circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
                showall: false,
                hiddenBtn: false,
                lineClamp: 3,
                zt:require("../../assets/img/zt.png"),
                zt2:require("../../assets/img/zt2.png"),
                ztI:true,
                gz:require("../../assets/img/gz.png"),
                gz2:require("../../assets/img/gz2.png"),
                gzI:true,
                sc:require("../../assets/img/sc.png"),
                sc2:require("../../assets/img/sc2.png"),
                scI:true,
                page:1,
                pageIndex:1,
                pageSize:8,
                pageTotal:0,
                stringList: null, //选中集合数组转换成字符串
                stringListX: null, //选中集合数组转换成字符串
                isActive:-1,
                answerList:[],//回答的数据
                HotList:[],//热门问题
                TypeList:[],//问题类型
                gather: [], //选中集合数组
                gatherX: [], //提问的时候 选中集合数组
                hotData:{
                    title:'',
                    content:'',
                    auditStatus:'',//是否匿名 1是2否
                    nickname:'',
                    id:'',
                    collect:0,
                    answer:0,
                    createTime:'',
                    avatarName:require("../../assets/img/avatar.png"),
                    collectOwn:false,
                    tagList:[],
                },//热门问题数据
                hotListA:[],
                recommend:[],//推荐问题
                myW:[],//我的问题
                myType:false,
                doctorData:[],//关注医生
                Sc:[],//我的收藏数据
                HDWT:[],
            }
        },
        computed: {

        },
        mounted() {
            this.getData(this.$cookie.get('useridList'),1);
            this.getHot();
            this.getType();
            console.log(this.$cookie.get('roleid'))
        },
        methods:{
            //roleid 2 医生 6 普通用户

            //回答问题
            hdW(id){
                //切换状态
                this.HDWT = [];
                this.hd = '';
                let subscript = this.HDWT.indexOf(id); //indexOf:返回某个指定的字符串值在字符串中首次出现的位置
                if (subscript > -1) {
                    this.HDWT.splice(subscript, 1); //splice:用于添加或删除数组中的元素
                } else {
                    this.HDWT.push(id); //通过push方法将选中id添加到数组中
                }
            },
            fbhd(id){
                //回答问题  problemId doctorId content
                if(this.hd){
                    this.$http({
                        url: this.$http.adornUrl('/problemAnswer/add'),
                        method: 'post',
                        data: this.$http.adornData({
                            'problemId': id,
                            'content': this.hd,
                            'doctorId':this.$cookie.get('useridList'),
                        })
                    }).then((res) => {
                        if ( res.data.code == 200) {
                            this.$message.success('回复成功！');
                            this.HDWT = [];
                        }
                    });
                }else{
                    this.$message.error('请填写您的回答！')
                }
            },
            //跳转到详情医生
            gotodetail(id,userId) {
                this.$router.push({
                    name: "ConsultDetail",
                    query: {
                        id: id,
                        userId:userId,
                    },
                })
            },
            //默认获取热门回答
            getData(userId,type){
                this.$http({
                    url: this.$http.adornUrl('/problemAnswer/list'),
                    method: 'get',
                    params: this.$http.adornParams({
                        'page': this.page,
                        'size': this.pageSize,
                        'type':type,
                        'userId':userId
                    })
                }).then((res) => {
                    if ( res.data.code == 200) {
                        this.answerList = res.data.data.content;
                        this.pageTotal = res.data.data.totalElements;
                    }else{
                        this.answerList = [];
                    }
                });
            },
            //获取问题类型 consultDomain/list
            getType(){
                this.$http({
                    url: this.$http.adornUrl('/consultDomain/list'),
                    method: 'get',
                }).then((res) => {
                    if ( res.data.code == 200) {
                        this.TypeList = res.data.data.content;
                    }else{
                        this.TypeList = [];
                    }
                });
            },
            //获取推荐问题 problem/getList  patientId : 人员id 可为空  tagIds 多个字符串拼接
            getRecommend(patientId,tagIds){
                this.$http({
                    url: this.$http.adornUrl('/problem/getList'),
                    method: 'get',
                    params: this.$http.adornParams({
                        'page': this.page,
                        'size': this.pageSize,
                        'patientId': patientId,
                        'tagIds': tagIds,
                    })
                }).then((res) => {
                    if ( res.data.code == 200) {
                        this.recommend = res.data.data.content;
                        this.pageTotal = res.data.data.totalElements;
                    }else{
                        this.recommend = [];
                    }
                });
            },
            //获取热门问题
            getHot(){
                this.$http({
                    url: this.$http.adornUrl('/problem/hotList'),
                    method: 'get',
                    params: this.$http.adornParams({
                        'page': 1,
                        'size': this.pageSize,
                    })
                }).then((res) => {
                    if ( res.data.code == 200) {
                        this.HotList = res.data.data.content;
                    }else{
                        this.HotList = [];
                    }
                });
            },
            //获取关注医生 consultDoctorCollect/list
            getCollect(){
                this.$http({
                    url: this.$http.adornUrl('/consultDoctorCollect/list'),
                    method: 'get',
                    params: this.$http.adornParams({
                        'page': 1,
                        'size': this.pageSize,
                        'userId': this.$cookie.get('useridList'),
                    })
                }).then((res) => {
                    if ( res.data.code == 200) {
                        this.doctorData = res.data.data.content;
                    }else{
                        this.doctorData = [];
                    }
                });
            },
            //我的收藏  problemStemCollect/getAllList
            getSc(){
                this.$http({
                    url: this.$http.adornUrl('/problemStemCollect/getAllList'),
                    method: 'get',
                    // params: this.$http.adornParams({
                    //     'userId': this.$cookie.get('userid'),
                    // })
                }).then((res) => {
                    if ( res.data.code == 200) {
                        // 数据里面 type ：1 回答  2 提问
                        this.Sc = res.data.data;
                    }
                });
            },
            //点击切换
            handleSelect(key){
                this.type = key;
                this.hotP = false;
                this.page = 1;
                /**
                 * key 1,2 获取回答内容  type 1 最新 2 热门
                 */
                if(key == 1 || key == 2){
                    this.getData(this.$cookie.get('useridList'),key)
                }else if(key == 3){
                    //获取推荐问题
                    this.myType = false;
                    this.getRecommend(this.userId,'');
                }else if(key == 4){
                    if(this.$cookie.get('useridList')){
                        this.getCollect();
                    }else{
                        this.$message.error('请先登录账号！')
                    }
                }else if(key == 5){
                    if(this.$cookie.get('useridList')){
                        this.getSc();
                    }else{
                        this.$message.error('请先登录账号！')
                    }
                }
            },
            //点赞
            ztT(id,fabulous,index,type){
                if(this.$cookie.get('useridList')){
                    let ids = this.$cookie.get('useridList');
                    //点赞状态修改
                    if(type == 1 || type == 2){
                        //热门回答，最新回答数据修改  answerList数据修改
                        if(fabulous){//取消点赞
                            this.$http({
                                url: this.$http.adornUrl('/problemAnswerLike/delete'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemAnswerId': id,
                                    'userId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.answerList[index].like = false;
                                    this.$message.success('取消点赞成功！')
                                }
                            });
                        }
                        else{//点赞
                            this.$http({
                                url: this.$http.adornUrl('/problemAnswerLike/add'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemAnswerId': id,
                                    'userId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.answerList[index].like = true;
                                    this.$message.success('点赞成功！')
                                }
                            });
                        }
                    }else if(type == 3){
                        if(fabulous){//取消点赞
                            this.$http({
                                url: this.$http.adornUrl('/problemAnswerLike/delete'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemAnswerId': id,
                                    'userId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.hotListA[index].like = false;
                                    this.$message.success('取消点赞成功！')
                                }
                            });
                        }
                        else{//点赞
                            this.$http({
                                url: this.$http.adornUrl('/problemAnswerLike/add'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemAnswerId': id,
                                    'userId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.hotListA[index].like = true;
                                    this.$message.success('点赞成功！')
                                }
                            });
                        }
                    }else if(type == 6){
                        if(fabulous){//取消点赞
                            this.$http({
                                url: this.$http.adornUrl('/problemAnswerLike/delete'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemAnswerId': id,
                                    'userId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.hotListA[index].like = false;
                                    this.$message.success('取消点赞成功！')
                                }
                            });
                        }
                        else{//点赞
                            this.$http({
                                url: this.$http.adornUrl('/problemAnswerLike/add'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemAnswerId': id,
                                    'userId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.hotListA[index].like = true;
                                    this.$message.success('点赞成功！')
                                }
                            });
                        }
                    }
                }else{
                    this.$message.error('请先登录账号！')
                }
            },
            //关注
            gzT(id,index){
                if(this.$cookie.get('useridList')){
                    let ids = this.$cookie.get('useridList');
                    this.$http({
                        url: this.$http.adornUrl('/consultDoctorCollect/del'),
                        method: 'post',
                        data: this.$http.adornData({
                            'consultDoctorId': id,
                            'userId': ids,
                        })
                    }).then((res) => {
                        if ( res.data.code == 200) {
                            this.doctorData.splice(index,1);
                            this.$message.success('取消关注成功！')
                        }
                    });
                }else{
                    this.$message.error('请先登录账号！')
                }
            },
            //收藏
            scT(id,fabulous,index,type,num){
                if(this.$cookie.get('useridList')){
                    let ids = this.$cookie.get('useridList');
                    //收藏状态修改
                    if(type == 1 || type == 2){
                        //热门回答，最新回答数据修改  answerList数据修改
                        if(fabulous){//取消收藏
                            this.$http({
                                url: this.$http.adornUrl('/problemCollect/delete'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemAnswerId': id,
                                    'patientId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.answerList[index].collect = false;
                                    this.$message.success('取消收藏成功！')
                                }
                            });
                        }
                        else{//收藏
                            this.$http({
                                url: this.$http.adornUrl('/problemCollect/add'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemAnswerId': id,
                                    'patientId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.answerList[index].collect = true;
                                    this.$message.success('收藏成功！')
                                }
                            });
                        }
                    }else if(type == 3){
                        if(fabulous){//取消收藏
                            this.$http({
                                url: this.$http.adornUrl('/problemStemCollect/del'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemId': id,
                                    'patientId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.recommend[index].collectOwn = false;
                                    this.$message.success('取消收藏成功！')
                                }
                            });
                        }
                        else{//收藏
                            this.$http({
                                url: this.$http.adornUrl('/problemStemCollect/add'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemId': id,
                                    'patientId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.recommend[index].collectOwn = true;
                                    this.$message.success('收藏成功！')
                                }
                            });
                        }
                    }else if(type == 4){
                        ///consultDoctorCollect/del 取消关注

                    }else if(type == 5){
                        // 收藏取消
                        if(num == 1){
                            this.$http({
                                url: this.$http.adornUrl('/problemCollect/delete'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemAnswerId': id,
                                    'patientId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.Sc[index].data.problemAnswer.collect = false;
                                    this.Sc.splice(index,1);
                                    this.$message.success('取消收藏成功！')
                                }
                            });
                        }else if(num == 2){
                            this.$http({
                                url: this.$http.adornUrl('/problemStemCollect/del'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'problemId': id,
                                    'patientId': ids,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.Sc[index].data.problem.collectOwn = false;
                                    this.Sc.splice(index,1);
                                    this.$message.success('取消收藏成功！')
                                }
                            });
                        }
                    }else if(type == 6){
                        if(num == 1){//问题
                            if(fabulous){//取消收藏
                                this.$http({
                                    url: this.$http.adornUrl('/problemStemCollect/del'),
                                    method: 'post',
                                    data: this.$http.adornData({
                                        'problemId': id,
                                        'patientId': ids,
                                    })
                                }).then((res) => {
                                    if ( res.data.code == 200) {
                                        this.hotData.collectOwn = false;
                                        this.$message.success('取消收藏成功！')
                                    }
                                });
                            }
                            else{//收藏
                                this.$http({
                                    url: this.$http.adornUrl('/problemStemCollect/add'),
                                    method: 'post',
                                    data: this.$http.adornData({
                                        'problemId': id,
                                        'patientId': ids,
                                    })
                                }).then((res) => {
                                    if ( res.data.code == 200) {
                                        this.hotData.collectOwn = true;
                                        this.$message.success('收藏成功！')
                                    }
                                });
                            }
                        }else if(num == 2){//回答
                            if(fabulous){//取消收藏
                                this.$http({
                                    url: this.$http.adornUrl('/problemCollect/delete'),
                                    method: 'post',
                                    data: this.$http.adornData({
                                        'problemAnswerId': id,
                                        'patientId': ids,
                                    })
                                }).then((res) => {
                                    if ( res.data.code == 200) {
                                        this.hotListA[index].collect = false;
                                        this.$message.success('取消收藏成功！')
                                    }
                                });
                            }
                            else{//收藏
                                this.$http({
                                    url: this.$http.adornUrl('/problemCollect/add'),
                                    method: 'post',
                                    data: this.$http.adornData({
                                        'problemAnswerId': id,
                                        'patientId': ids,
                                    })
                                }).then((res) => {
                                    if ( res.data.code == 200) {
                                        this.hotListA[index].collect = true;
                                        this.$message.success('收藏成功！')
                                    }
                                });
                            }
                        }
                    }
                }else{
                    this.$message.error('请先登录账号！')
                }
            },
            //热门回答分页
            handlePageChange1 (val) {
                this.page = val;
                this.getData(this.$cookie.get('useridList'),1)
            },
            //最新回答分页
            handlePageChange2 (val) {
                this.page = val;
                this.getData(this.$cookie.get('useridList'),2)
            },
            //推荐问题分页
            handlePageChange3 (val) {
                this.page = val;
                this.getRecommend(this.userId,'');
            },
            //我的问题分页
            handlePageChange4 (val) {
                this.page = val;
                this.myQ();
            },
            //每次点击 登录则添加一条记录
            //点击事件 通过传参拿到每一条的id
            onPitch(id) {
                let subscript = this.gather.indexOf(id); //indexOf:返回某个指定的字符串值在字符串中首次出现的位置
                if (subscript > -1) {
                    this.gather.splice(subscript, 1); //splice:用于添加或删除数组中的元素
                } else {
                    this.gather.push(id); //通过push方法将选中id添加到数组中
                    if(this.$cookie.get('useridList')){
                        this.$http({
                            url: this.$http.adornUrl('/problemTag/click'),
                            method: 'post',
                            data: this.$http.adornData({
                                'id': id,
                            })
                        }).then((res) => {
                            if ( res.data.code == 200) {
                                console.log('添加成功')
                            }
                        });
                    }else{
                        console.log('暂未登录')
                    }
                }
                this.stringList = this.gather.join(',');//转换成字符串并以逗号隔开
                this.type = 3;
                this.myType = false;
                this.hotP = false;
                this.putQuestion = false;
                this.getRecommend(this.userId,this.stringList);
            },
            onPitchX(id){
                let subscript = this.gatherX.indexOf(id); //indexOf:返回某个指定的字符串值在字符串中首次出现的位置
                if (subscript > -1) {
                    this.gatherX.splice(subscript, 1); //splice:用于添加或删除数组中的元素
                } else {
                    this.gatherX.push(id); //通过push方法将选中id添加到数组中
                }
                this.stringListX = this.gatherX.join(',');//转换成字符串并以逗号隔开
            },
            //热门提问点击
            activeClick(id,index){
                // console.log(index)
                this.putQuestion = false;
                this.isActive = index;
                this.type = 3;
                this.hotP = true;
                this.myType = false;
                //获取当前问题详细信息 problem/getById  id ：当前问题id
                this.$http({
                    url: this.$http.adornUrl('/problem/getById'),
                    method: 'get',
                    params: this.$http.adornParams({
                        'id': id,
                        'patientId': this.$cookie.get('useridList'),
                    })
                }).then((res) => {
                    if ( res.data.code == 200) {
                        Object.keys(this.hotData).forEach((item) => {
                            this.hotData[item] = res.data.data[item];
                        });
                        this.hotData.id = id;
                    }else{
                        this.hotData = [];
                    }
                });

                //获取所有回答 problemAnswer/list type 默认2 problemId ：当前问题id
                this.$http({
                    url: this.$http.adornUrl('/problemAnswer/list'),
                    method: 'get',
                    params: this.$http.adornParams({
                        'type': 2,
                        'problemId': id,
                        'page':1,
                        'userId': this.$cookie.get('useridList'),
                        'size':this.pageSize,
                    })
                }).then((res) => {
                    if ( res.data.code == 200) {
                        this.hotListA = res.data.data.content;
                    }else{
                        this.hotListA = [];
                    }
                });

            },
            //我要提问 putQ
            putQ(){
                if(this.$cookie.get('useridList')){
                    this.putQuestion = true;
                }else{
                    this.$message.error('请先登录账号！')
                }
            },
            //我的问题 我提出的问题
            myQ(){
                if(this.$cookie.get('useridList')){
                    this.putQuestion = false;
                    this.hotP = false;
                    this.type = 3;
                    this.myType = true;
                    this.$http({
                        url: this.$http.adornUrl('/problem/listOwnPage'),
                        method: 'get',
                        params: this.$http.adornParams({
                            'page': this.page,
                            'size': this.pageSize,
                        })
                    }).then((res) => {
                        if ( res.data.code == 200) {
                            this.myW = res.data.data.content;
                            this.pageTotal = res.data.data.totalElements;
                        }else{
                            this.myW = [];
                        }
                    });

                }else{
                    this.$message.error('请先登录账号！')
                }
            },
            //发布问题 /problem/add patientId:人员  tagIds：标签id  title  anonymous匿名 1匿名 2不匿名
            onSubmit(){
                this.$refs['form'].validate((valid) => {
                    if(this.stringListX){
                        if (valid) {
                            let typeList = 2;
                            if(this.form.type){
                                typeList = 1
                            }else{
                                typeList = 2
                            }
                            this.$http({
                                url: this.$http.adornUrl('/problem/add'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'patientId': this.userId,
                                    'tagIds': this.stringListX,
                                    'title':this.form.title,
                                    'content':this.form.content,
                                    'anonymous':typeList,
                                })
                            }).then((res) => {
                                if(res.data.code == 200){
                                    this.$message.success('发布成功！');
                                    this.gatherX = [];
                                    this.stringListX = null;
                                    this.form.title = '';
                                    this.form.content = '';
                                }
                            });
                        }
                    }else{
                        this.$message.error('请选择标签！')
                    }
                })
            }
        }
    }
</script>

<style>
    p{
        margin-bottom: 5px;
    }
    .hdan{
        width: 180px;
        height: 40px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        cursor: pointer;
        text-align: center;
        line-height: 40px;
        color: #0EC2DB;
        border: 1px solid #0EC2DB;
        margin: 15px auto 15px;
    }
    .hdanT{
        width: 180px;
        height: 40px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        cursor: pointer;
        text-align: center;
        line-height: 40px;
        background: #0EC2DB;
        color: #ffffff;
        border: 1px solid #0EC2DB;
        margin: 15px auto;
    }
    .divs>p{
        margin-bottom: 5px;
    }
    .pps{
        font-size: 18px;
        font-weight: 400;
        color: #333333;
    }
    .ppp{
        font-weight: 400;
        color: #666666;
    }
    .ppf{
        font-size: 16px;
        font-weight: 400;
        color: #0EC2DB;
    }
    .association{
        background: #ffffff;
    }
    .explainL{
        height: 50px;
        line-height: 50px;
        width: 1080px;
        margin: 0 auto;
        text-align: left;
    }
    .routeL{
        cursor: pointer;
    }
    .brief{
        width: 1080px;
        margin: 20px auto 76px;
        padding: 15px;
    }
    .el-menu--horizontal>.el-menu-item.is-active{
        border-color: #0EC2DB!important;
    }
    .el-menu-item.is-active{
        color: #0EC2DB!important;
        border: none;
    }
    .el-menu--horizontal>.el-menu-item{
        width: 20%;
        text-align: center;
    }
    .typeList{
        margin-top: 20px;
    }
    .typeItem{
        background: #ffffff;
        padding: 15px;
        margin-bottom: 15px;
    }
    .name{
        color: #1F7EEC;
        font-size: 16px;
        font-weight: 400;
        margin-right: 15px;
    }
    .hospital{
        font-size: 16px;
        font-weight: 400;
        color: #333333;
    }
    .js{
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        padding: 2px 4px;
        background: rgba(14, 96, 219, 0.11);
        border-radius: 10px 10px 10px 10px;
    }
    .sh{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 5px;
    }
    .titleW{
        font-size: 20px;
        font-weight: bold;
        color: #444444;
        margin-bottom: 5px;
    }
    .dp-text-ellipsis-wrapper{
        display: flex;
        margin: 6px 0 20px 0;
        overflow: hidden;
        font-size: 14px;
        line-height: 20px;
    }
    .text {
        position: relative;
        overflow: hidden;
        line-height: 1.5;
        text-align: justify;
        text-overflow: ellipsis;
        word-break: break-all;
        transition: 0.3s max-height;
        margin-bottom: 10px;
    }
    .text::before {
        float: right;
        height: calc(100% - 20px);
        content: '';
    }
    .btn {
        position: relative;
        float: right;
        clear: both;
        margin-left: 10px;
        font-size: 14px;
        padding: 0 8px;
        color: #0EC2DB;
        line-height: 20px;
        border-radius: 4px;
        cursor: pointer;
        z-index: 10;
    }
    .btn::after {
        font-family: element-icons !important;
        content: '展开\e790';
        color: #0EC2DB;
    }

    .text.showall {
        max-height: none;
    }
    .text.showall .btn::before {
        visibility: hidden;
    }
    .text.showall .btn::after {
        visibility: visible;
        content: '收起\e78f';
    }
    .btn::before {
        position: absolute;
        left: 1px;
        color: #333;
        transform: translateX(-100%);
        content: '...';
    }
    .zgs{
        display: flex;
        justify-content: left;
        margin-top: 20px;
    }
    .flexT{
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .flexTX{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .zgs>div{
        margin-right: 30px;
    }
    .zgs>div>img{
        cursor: pointer;
        margin-right: 10px;
        margin-top: -5px;
        width: 18px;
    }
    .pagination{
        text-align: center;
    }
    .flexTT{
        width: 70px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #ffffff;
        cursor: pointer;
        background: #0EC2DB;
        border-radius: 5px;
        opacity: 1;
        margin: 0 30px;
        font-size: 12px;
    }
    .lineT{
        height: 51px;
        border: 1px solid #E5E5E5;
        margin: 0 50px;
    }
    .numT{
        font-size: 20px;
        font-weight: bold;
        color: #F5C342;
    }
    .numTT{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
    }
    .lx{
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        padding-bottom: 10px;
        border-bottom: 1px solid #E5E5E5;
    }
    .bq{
        width: 90px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        background: rgba(229, 229, 229, 0.6);
        margin: 5px;
        cursor: pointer;
    }
    .activeX{
        background: rgba(245, 195, 66, 0.2);
        color: #F5C342;
        width: 90px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        font-size: 14px;
        font-weight: 400;
        margin: 5px;
        cursor: pointer;
    }
    .activeXX{
        background: rgba(245, 195, 66, 0.2);
        color: #F5C342;
        width: 83px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        font-weight: 400;
        margin: 5px;
        cursor: pointer;
    }
    .rmtw>p:first-child{
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        width: 100%;
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis;/*隐藏后添加省略号*/
        white-space: nowrap;/*强制不换行*/
        margin-bottom: 0;
        cursor: pointer;
    }
    .rmtw>p:last-child{
        font-size: 12px;
        font-weight: 400;
        color: #999999;
    }
    .rmtw>p:last-child>span{
        margin-right: 10px;
    }
    .activeT{
        font-size: 14px;
        font-weight: 400;
        color: #0EC2DB!important;
    }
    .nameT{
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        margin-right: 20px;
    }
    .timeT{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
    }
    .shX>span{
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin-right: 10px;
    }
    .sctw{
        position: relative;
        float: right;
        top: -65px;
        width: 80px;
        height: 36px;
        color: #0EC2DB;
        text-align: center;
        line-height: 36px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        cursor: pointer;
        border: 1px solid #0EC2DB;
    }
    .titleZ{
        font-size: 14px;
        font-weight: 400;
        color: #333333;
    }
    .ckImg{
        position: relative;
        float: right;
        top: -35px;
    }
    .el-button--primary{
        background-color: #0EC2DB!important;
        border-color: #0EC2DB!important;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #0EC2DB!important;
    }
    .dd{
        display: inline-block;
        width: 6px;
        height: 6px;
        background: rgba(102, 102, 102, 0.35);
        border-radius: 50%;
        position: relative;
        top:-2px;
    }
</style>