<template>
    <div>
        <div class="LoginX">
            <p class="hy">欢迎登录</p>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="账号登录" name="first">
                    <el-form :model="formState" ref="formState" :rules="dataRule" class="demo-dynamic">
                        <el-form-item
                                prop="username"
                        >
                            <el-input prefix-icon="el-icon-user" v-model="formState.username" placeholder="请输入账号"></el-input>
                        </el-form-item>
                        <el-form-item
                                prop="password"
                        >
                            <el-input prefix-icon="el-icon-lock" type="password" v-model="formState.password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <div class="login-form-wrap">
                            <p class="login-form-forgot" @click="Forget()">忘记密码</p>
                        </div>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('formState')">登录</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="手机号登录" name="second">
                    <el-form :model="formStateT" ref="formStateT" :rules="dataRule" class="demo-dynamic">
                        <el-form-item
                                prop="phone"
                        >
                            <el-input prefix-icon="el-icon-mobile-phone" v-model="formStateT.phone" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item
                                prop="password"
                        >
                            <el-input prefix-icon="el-icon-lock" type="password" v-model="formStateT.password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <div class="login-form-wrap">
                            <p class="login-form-forgot" @click="Forget()">忘记密码</p>
                        </div>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('formStateT')">登录</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
        <img class="bmg" src="../../assets/img/login.png" alt="">
    </div>
</template>

<script>
    import { isMobile } from "../../utils/validate";

    export default {
        name: "login",
        data(){
            return{
                formState :{
                    'username': '',
                    'password': '',
                },
                formStateT :{
                    'username': '',
                    'password': '',
                },
                dataRule:{
                    username: [
                        { required: true, message: '请输入账号'}
                    ],
                    password: [
                        { required: true, message: '请输入密码'}
                    ],
                    phone: [
                        { required: true, message: '请输入密码',validator:isMobile()}
                    ],
                },
                activeName: 'first',
                type:true
            }
        },
        methods: {
            Forget(){
                this.$router.push({ name: "forget" });
            },
            //切换
            handleClick() {
                this.type = !this.type;
            },
            //登录
            submitForm() {
                if(this.type){
                    this.$refs['formState'].validate((valid) => {
                        if (valid) {
                            this.$http({
                                url: this.$http.adornUrl('/auth/mh/login'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'username': this.formState.username,
                                    'password': this.formState.password,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    // console.log( res.data.data)
                                    this.$emit("loginState","sucess");
                                    this.$cookie.set('token', res.data.data.access_token);
                                     this.$cookie.set('roleid', res.data.data.user.user.roles[0].id);
                                    this.$cookie.set('username', res.data.data.user.user.username);
                                     this.$cookie.set('useridList', res.data.data.user.user.id);
                                    if (res.data.data.user.user.avatarName){
                                        this.$cookie.set('avatarName', res.data.data.user.user.avatarName);
                                    }else{
                                        this.$cookie.set('avatarName', '');
                                    }
                                    this.$router.push({ name: "dashBoard" });
                                }else{
                                    this.$message.error(res.data.msg);
                                }
                            })
                        }
                    })
                }else{
                    this.$refs['formStateT'].validate((valid) => {
                        if (valid) {
                            this.$http({
                                url: this.$http.adornUrl('/auth/mh/login'),
                                method: 'post',
                                data: this.$http.adornData({
                                    'phone': this.formStateT.phone,
                                    'password': this.formStateT.password,
                                })
                            }).then((res) => {
                                if ( res.data.code == 200) {
                                    this.$emit("loginState","sucess");
                                    this.$message.success('登录成功');
                                    this.$cookie.set('token', res.data.data.access_token);
                                    this.$cookie.set('username', res.data.data.user.user.username);
                                    if (res.data.data.user.user.avatarName){
                                        this.$cookie.set('avatarName', res.data.data.user.user.avatarName);
                                    }else{
                                        this.$cookie.set('avatarName', '');
                                    }
                                    this.$router.push({ name: "dashBoard" });
                                }else{
                                    this.$message.error(res.data.msg);
                                }
                            })
                        }
                    })   
                }

            },
        },
    }
</script>

<style scoped>
    .LoginX{
        width: 443px;
        height: 330px;
        margin: 98px auto 150px;
        background: #FFFFFF;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        color: #333333;
        padding: 30px 5%;
        position: relative;
    }
    .bmg{
        width: 100%;
        margin-top: -570px;
    }
    .hy{
        height: 21px;
        line-height: 21px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        text-align: center;
    }
    .el-button{
        width: 100%;
    }
    .login-form-wrap{
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
        color: #666666!important;
    }
</style>