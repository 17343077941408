<template>
    <div class="btn-foot">
        <div class="contain">
            <div class="containH">
                <div class="daohang" @click="homePage()">
                    <span>首页</span>
                </div>
                <div class="daohang" @click="association()">
                    <span>关于协会</span>
                </div>
                <div class="daohang" @click="member()">
                    <span>会员服务</span>
                </div>
                <div class="daohang" @click="learning()">
                    <span>学术交流</span>
                </div>
                <div class="daohang" @click="Consult()">
                    <span>心理咨询</span>
                </div>
                <div class="daohang" @click="psychology()">
                    <span>心理问答</span>
                </div>
                <div class="daohang" @click="group()">
                    <span>社会服务</span>
                </div>
            </div>
        </div>
        <div class="footLine">
            <div>
                <div class="footI">
                    <!--<img src="../assets/img/wx.png" alt="">-->
                    <!--<img src="../assets/img/yx.png" alt="">-->
                </div>
                <div>
                    <p>电话：0551-5165138</p>
                    <p>邮编：230031</p>
                    <p>地址：安徽合肥市梅山路安医大</p>
                </div>
            </div>
            <div class="XLine"></div>
            <div class="footE">
                <div class="footM" style="text-align: center;">
                    <img src="../assets/img/xcx.jpg" alt="" style="width: 93px;height: 94px;border-radius: 8px;">
                    <div>心小源小程序</div>
                </div>
                <div class="footM" style="text-align: center;">
                    <img src="../assets/img/ewm.png" alt="" style="border-radius: 8px;">
                    <div>安徽心理卫生协会公众号</div>
                </div>
            </div>
        </div>
        <div class="footS">
            <el-link target="_blank" :href.asyc="urlList" :underline="false">
                <span style="color: #ffffff;">版权所有：安徽省心理卫生协会</span>
            </el-link>
            <span>|</span>
            <span>备案号：皖ICP备2024058714号</span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                urlList:'https://beian.miit.gov.cn/'
            };
        },
        created() {},
        watch: {

        },
        mounted() {

        },
        methods: {
            homePage(){
                this.$router.push({ name: "dashBoard" });
            },
            association(){
                this.$router.push({ name: "association" });
            },
            learning(){
                this.$router.push({ name: "learning" });
            },
            member(){
                this.$router.push({ name: "member" });
            },
            group(){
                this.$router.push({ name: "group" });
            },
             Consult(){
                this.$router.push({ name: "Consult" });
            },
	        psychology(){
                this.$router.push({ name: "psychology" });
            },
        },
    };
</script>
<style scoped>
    .btn-foot {
        height: 260px;
        width: 100%;
        background: #0E60DB;
    }
    .contain{
        width: 100%;
        height: 50px;
        line-height: 50px;
    }
    .containH {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }
    .daohang {
        cursor: pointer;
        color: #fff;
        float: left;
        font-weight: 400;
        width: 14%;
        text-align: center;
        font-size: 12px;
        white-space: nowrap;
    }
    .daohang>span{
        display: inline-block;
        height: 50px;
    }
    .footLine{
        width: 1080px;
        margin: 10px auto;
        padding-bottom: 10px;
        color: #ffffff;
        font-size: 12px;
        display: flex;
        /*实现垂直居中*/
        align-items: center;
        /*实现水平居中*/
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.42);;
    }
    .XLine{
        width: 0.5px;
        height: 153px;
        border-right: 0.5px solid rgba(255, 255, 255, 0.6);
    }
    .footI{
        text-align: left;
        margin-bottom: 20px;
    }
    .footI>img{
        margin-right: 27px;
    }
    .footLine>div>div>p{
        text-align: left;
    }
    .footE{
        display: flex;
        /*实现垂直居中*/
        align-items: center;
        /*实现水平居中*/
        justify-content: center;
    }
    .footE>div>img{
        margin-bottom: 20px;
    }
    .footM{
        margin-right: 27px;
    }
    .footS{
        color: #ffffff;
        text-align: center;
    }
    .footS>span{
        margin: 0 10px;
    }
</style>