<template>
    <div>
     <div class="association">
             <div class="explainL">
                 <span>当前位置：</span>
                 <span class="routeL">首页 > </span>
                 <span>学术年会 > </span>
                 <span>活动通知 ></span>
             </div>
         </div>
         <div class="cont" v-show="index==1">
             <a-row :gutter="20">
                 <a-col class="gutter-row" :span="24">
                     <div class="title">安徽省心理卫生协会学术年会参会缴费通道
                     </div>
                 </a-col>
                 <div class="boxL" >
                        <p style="color:#f3b406;font-size: 18px;" v-show="type == 1">您{{identity}}，应付金额:{{price1}}元</p>
                        <p style="color:#f3b406;font-size: 18px;" v-show="type == 2">您{{identity}}，应付金额:{{price}}元</p>
                        <div style="display:flex;margin-top: 80px;justify-content: space-evenly;">
                        <div style="display:flex;flex-direction: column;">
                        <img class="img" :src="imgUrl" alt="">
                        <div>
                        <img style="width:30px;height:30px" src="../../assets/img/wxzf.png" alt=""> 微信支付
                    </div>
                    </div>
                    </div>
                 </div>
             </a-row>
             <div class="application" @click="back()">返回</div>
         </div>
         <div class="cont" v-show="index==2">
             <a-row :gutter="20">
                 <a-col class="gutter-row" :span="24">
                     <div class="title">安徽省心理卫生协会学术年会参会缴费通道
                     </div>
                 </a-col>
                <div class="boxL" >
                <img style="width:80px;height:80px" src="../../assets/img/Group.png" alt="">
                <p style="font-size:20px; font-weight:600 ;margin-top: 30px;">参会缴费支付成功</p>
                <div style="text-align:left;width: 220px;margin: 20px auto;">
                <p style="margin-bottom:10px" v-show="type == 1">支付金额：<span style="color:red" >{{price1}}元</span> </p>
                <p style="margin-bottom:10px" v-show="type == 2">支付金额：<span style="color:red" >{{price}}元</span> </p>
                <p>支付时间：{{nowDate}}</p>
                <div class="application" @click="back1()">返回</div>
             </div>
             </div>

             <div class="foot">
                <p style=" font-size: 12px;">如需发票，请扫描右侧二维码，填写相关信息。</p>
                <img src="../../assets/img/fapiao.png" alt="">
             </div>
             </a-row>
         </div>
         <div class="cont" v-show="index==3">
             <a-row :gutter="20">
                 <a-col class="gutter-row" :span="24">
                     <div class="title">安徽省心理卫生协会学术年会参会缴费通道
                     </div>
                 </a-col>
                <div class="boxL" >
                <img style="width:80px;height:80px" src="../../assets/img/common_icons_info.png" alt="">
                <p style="font-size:20px; font-weight:600 ;margin-top: 30px;">支付失败，请重新支付</p>
                <div class="anew">
                <div class="applications" @click="answ()">重新支付</div>
                <div class="back" @click="back1()">返回</div>
             </div>
             </div>
             <div class="foot">
                <p style=" font-size: 12px;">如需发票，请扫描右侧二维码，填写相关信息。</p>
                <img src="../../assets/img/fapiao.png" alt="">
             </div>
             </a-row>
         </div>
    </div>
  </template>
  
  <script>
      export default {
        data() {
      return {
        type:1,
        identity:"",
        price:"",
        price1:"",
        index:1,
        nowDate:"",
        code:"",
        codes:"",
        imgUrl:"",
        intervalwx:"",
      };
    },
    mounted(){
        this.code = this.$route.params.code
        this.codes = this.$route.params.codes
        if(this.$route.params.id == 3){
            this.Pay()
            this.money()
        }else{
            this.Pays()
            this.money1()
        }
       
        this.formatDate()
        this.identity1()
       
    },
    methods:{
        back(){
            this.$router.push({ name: "payment" });
        },
        back1(){
            this.$router.push({ name: "payment" });
        },
        formatDate() {
            let date = new Date();
            let year = date.getFullYear(); // 年
            let month = date.getMonth() + 1; // 月
            let day = date.getDate(); // 日
           
            let hour = date.getHours(); // 时
            hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
            let minute = date.getMinutes(); // 分
            minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
            let second = date.getSeconds(); // 秒
            second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
            this.nowDate = `${year}-${month}-${day}   ${hour}:${minute}:${second}`;
        },
     
        Pays(){
            this.$http({
                url: this.$http.adornUrl('/wxPay/pay'),
                method: 'get',
                responseType: 'arraybuffer',
                params: this.$http.adornParams({
                 code:this.codes
                })
            }).then((res) => {
                console.log(res);
                this.imgUrl =  'data:image/png;base64,' + btoa(
                new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
                if(res.status == 200){
                    let timesRun = 0;
                    let interval = setInterval(()=>{
                        timesRun += 1;
                        if(timesRun === 20){
                            clearInterval(interval);
                        }
                    this.$http({
                        url: this.$http.adornUrl('/wxPay/isPay'),
                        method: 'get',
                    }).then((res) => {
                        console.log(res);
                        if(res.data.data.status == 1){
                            this.index = 2
                        }else if(res.data.data.status == -1){
                            this.index = 3
                        }
                        });
                            }, 3000);
                }
            });
        },
        Pay(){
            this.$http({
                url: this.$http.adornUrl('/wxPay/pay'),
                method: 'get',
                responseType: 'arraybuffer',
                params: this.$http.adornParams({
                 code:this.code
                })
            }).then((res) => {
                console.log(res);
                this.imgUrl =  'data:image/png;base64,' + btoa(
                new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
                if(res.status == 200){
                    let timesRun = 0;
                    let interval = setInterval(()=>{
                        timesRun += 1;
                        if(timesRun === 20){
                            clearInterval(interval);
                        }
                    this.$http({
                        url: this.$http.adornUrl('/wxPay/isPay'),
                        method: 'get',
                    }).then((res) => {
                        console.log(res);
                        if(res.data.data.status == 1){
                            this.index = 2
                        }else if(res.data.data.status == -1){
                            this.index = 3
                        }
                        });
                            }, 3000);
                }
            });
        },
        identity1(){
            if(this.$route.params.id == 1){
                this.identity = "是协会会员"
                this.type = 1
            }else if(this.$route.params.id == 2){
                this.identity = "非协会会员"
                this.type = 1
            }else if(this.$route.params.id == 3){
                this.identity = "是协会团体参与"
                this.type = 2
            }else {
                this.identity = "是协会学生"
                this.type = 1
            }
        },
        money(){
            this.$http({
                        url: this.$http.adornUrl('/wxPay/money'),
                        method: 'get',
                        params: this.$http.adornParams({
                        code:this.code
                     }),
                    }).then((res) => {
                        console.log(res);
                        this.price = parseFloat(res.data.data.money / 100).toFixed(2)
                   
            });
        },
        money1(){
            this.$http({
                        url: this.$http.adornUrl('/wxPay/money'),
                        method: 'get',
                        params: this.$http.adornParams({
                        code:this.codes
                     }),
                    }).then((res) => {
                        console.log(res);
                        this.price1 = parseFloat(res.data.data.money / 100).toFixed(2)
                   
            });
        },
        answ(){
            this.index = 1
        },
       
    }
  };
  </script>

  <style scoped>
  *{
     padding: 0;
     margin: 0;
  }
  .association{
         background: #ffffff;
         /* color:#f3b406 */
        
     }
     .explainL{
         height: 50px;
         line-height: 50px;
         width: 1080px;
         margin: 0 auto;
         text-align: left;
     }
     .routeL{
         cursor: pointer;
     }
    .cont{
         width: 1080px;
         margin: 20px auto;
         background: #ffffff;
         padding:50px 20px;
     }
     .title{
        text-align: center;
        
     }
     .boxL{
        width: 800px;
        height: 400px;
        background: #FCF9FC;
      margin: 50px auto;
      text-align: center;
        padding-top: 40px;
     }
  
     .application{
        width: 200px;
        height: 40px;
        background: #47CFE3;
        margin: 50px  auto 10px;
        text-align: center;
        line-height: 40px;
        color: white;
        cursor: pointer;
       
     }
     .applications{
        width: 150px;
        height: 40px;
        background: #47CFE3;
        margin: 50px  auto 10px;
        text-align: center;
        line-height: 40px;
        color: white;
        cursor: pointer;
     }
     .back{
        width: 150px;
        height: 40px;
        background: #E7E4E7;
        margin: 50px  auto 10px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
     }
     .img{
        width: 150px;
        height: 150px;
     }
     .anew{
        width: 500px;
        display: flex;
        justify-content: space-between;
        margin: 20px auto;
     }
     .foot{
        width: 800px;
        margin: 0 auto;
        display: flex;
     }
     
     .foot img {
        width: 200px;
        height: 200px;
        margin-top: -50px;
       margin-left: 45px;
    }
  </style>
  