// 引入vue框架
import Vue from 'vue'
// 引入vue-router路由依赖
import Router from 'vue-router'

// Vue全局使用Router
Vue.use(Router)
import dashBoard from '../components/dashBoard'
import login from '../components/login/index'
import register from '../components/login/register'
import forget from '../components/login/forget'
import association from '../components/association/index'
import learning from '../components/learning/index'
import dynamic from '../components/dynamic/index'
import detail from '../components/detail/index'
import member from '../components/member/index'
import group from '../components/group/index'
import psychology from '../components/psychology/index'
//janetuAdd
import setting from '../components/setting/index'
import Consult  from '../components/Consult/index'
import message  from '../components/message/index'
import ConsultDetail  from '../components/Consult/detail'
//
import academic from '../components/academic/xueshunianhui'
import essay from '../components/academic/essay'
import individual from '../components/academic/individual'
import individual1 from '../components/academic/individual1'
import participants from '../components/academic/participants'
import payment from '../components/academic/payment'
import paymentchanne from '../components/academic/paymentchanne'
//examination
import examination from '../components/examination/examination'
import paper from '../components/examination/paper'
import historypapers from '../components/examination/historypapers'

import baywindow  from '../components/Baywindow/baywindow'
/* Router Modules */
export default new Router({
  // mode: 'history',
  // base: './dist', 

  routes: [
      {
          path: '/',
          component: dashBoard,
          name: 'dashBoard'
      },{
          path: '/login/index',
          component: login,
          name: 'login'
      },{
          path: '/login/register',
          component: register,
          name: 'register'
      },{
          path: '/login/forget',
          component: forget,
          name: 'forget'
      },{
          path: '/association/index',
          component: association,
          name: 'association'
      },{
          path: '/learning/index',
          component: learning,
          name: 'learning'
      },{
          path: '/dynamic/index',
          component: dynamic,
          name: 'dynamic'
      },{
          path: '/detail/index',
          component: detail,
          name: 'detail'
      },{
          path: '/member/index',
          component: member,
          name: 'member'
      },{
          path: '/group/index',
          component: group,
          name: 'group'
      },{
        path: '/setting/index',
        component: setting,
        name: 'setting'
    },{
        path: '/Consult/index',
        component: Consult,
        name: 'Consult'
    },
    {
        path: '/ConsultDetail/index',
        component: ConsultDetail,
        name: 'ConsultDetail'
    },
    {
        path: '/message/index',
        component: message,
        name: 'message'
    },
{
          path: '/psychology/index',
          component: psychology,
          name: 'psychology'
      },
      {
        path: '/academic/xueshunianhui',
          component: academic,
          name: 'academic'
    },
    {
        path: '/academic/essay', 
          component: essay,
          name: 'essay'
    },
    {
        path: '/academic/individual', 
          component: individual,
          name: 'individual'
    },
    {
        path: '/academic/individual1', 
          component: individual1,
          name: 'individual1'
    },
    {
        path: '/academic/participants', 
          component: participants,
          name: 'participants'
    },
    {
        path: '/academic/payment', 
          component: payment,
          name: 'payment'
    },
    {
         path: '/academic/paymentchanne', 
         component: paymentchanne,
         name: 'paymentchanne',
    },
    {
        path: '/examination/examination', 
        component: examination,
        name: 'examination',
   },
   {
        path: '/examination/paper', 
        component: paper,
        name: 'paper',
    },
    {
        path: '/examination/historypapers', 
        component: historypapers,
        name: 'historypapers',
    },
    {
        path: '/Baywindow/baywindow', 
        component: baywindow,
        name: 'baywindow',
    },
  ]
})