<template>
  <div id="app">
    <top-headers :loginState="loginState" v-on:loginStates="updateStates"></top-headers>
    <router-view style="min-height: 551px;" v-on:loginState="updateState"></router-view>
    <btn-foot></btn-foot>
  </div>
</template>

<script>
import TopHeaders from "./components/TopHeader2.vue";
import BtnFoot from "./components/foot.vue";
export default {
  name: "App",
  components: {
    "top-headers": TopHeaders,
    "btn-foot": BtnFoot,
  },
  data() {
    return {
      loginState: "fail",
      loginStates: "fail",
    };
  },
  created() {},
  mounted() {},
  methods: {
    updateState(e) {
      //声明这个函数
      this.loginState = e;
    },
     updateStates(e) {
      //声明这个函数
      this.loginState = e;
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #333333;
  background: #f8f8f8;
}
li {
  list-style-type: none;
}
</style>
