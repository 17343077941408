import Vue from 'vue'
// import 'amfe-flexible'


import httpRequest from './utils/httpRequest' // api: https://github.com/axios/axios

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
import '../node_modules/swiper/swiper.min.css';
// import './assets/index.css'
import 'ant-design-vue/dist/antd.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueCookie from 'vue-cookie'
Vue.use(VueCookie)
import router from './router'
Vue.prototype.router = router
import App from './App';
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import {
  Input
} from 'ant-design-vue';
Vue.use(Input);
import {
  Drawer
} from 'ant-design-vue';
Vue.use(Drawer);
import {
  Button
} from 'ant-design-vue';
Vue.use(Button);
import {
  Tooltip
} from 'ant-design-vue';
Vue.use(Tooltip);
import {
  Modal
} from 'ant-design-vue';
Vue.use(Modal);
import {
  message
} from 'ant-design-vue';
Vue.prototype.$message = message;
import {
  List
} from 'ant-design-vue';
Vue.use(List);
import { Carousel } from 'ant-design-vue';
Vue.use(Carousel);
import { Row } from 'ant-design-vue';
Vue.use(Row);
import { Form } from 'ant-design-vue';
Vue.use(Form);
import { Checkbox } from 'ant-design-vue';
Vue.use(Checkbox);
import { Col } from 'ant-design-vue';
Vue.use(Col);
import {
  Tabs
} from 'ant-design-vue';
Vue.use(Tabs);
import VueCoreVideoPlayer from 'vue-core-video-player'

Vue.use(VueCoreVideoPlayer)
import 'fullpage.js/vendors/scrolloverflow'

import VueFullPage from 'vue-fullpage.js'

Vue.use(VueFullPage);

router.afterEach(() => {
  window.scrollTo(0, 0);
})

Vue.config.productionTip = false;

var domain = window.location.host;
// console.log(domain)
// eslint-disable-next-line

// 挂载全局
Vue.prototype.$http = httpRequest; // ajax请求方法

var _hmt = _hmt || [];
(function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?0dc570a807f05470d1d49c1f48ce8ef3";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app');