<template>
    <div>
        <div class="association">
            <div class="explainL">
                <span>当前位置：</span>
                <span class="routeL">首页></span>
                <span>会员服务></span>
            </div>
        </div>
        <div class="brief">
            <a-row :gutter="20">
                <a-col class="gutter-row" :span="4">
                    <div class="gutter-box">
                        <ul class="uls">
                            <li :class="[this.type == 1 ? 'active':'']" @click="check('1')"><img class="imgXX" :src="[this.type == 1 ? hytl2 : hytl]" alt="">会员条例</li>
                            <li :class="[this.type == 2 ? 'active':'']" @click="check('2')"><img class="imgXX" :src="[this.type == 2 ? grhy2 : grhy]" alt="">个人会员</li>
                            <li :class="[this.type == 3 ? 'active':'']" @click="check('3')"><img class="imgXX" :src="[this.type == 3 ? dwhy2 : dwhy]" alt="">单位会员</li>
                            <li :class="[this.type == 4 ? 'active':'']" @click="check('4')"><img class="imgXX" :src="[this.type == 4 ? fzjg2 : fzjg]" alt="">入会指南</li>
                        </ul>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="20">
                    <div class="gutter-box boxL" v-show="type == 1">
                        <div class="title">
                            <h3>会员条例</h3>
                        </div>
                        <article>
                            <p style="font-weight: 400;color: #333333;text-align: center;font-size: 18px;margin-top: 20px;">安徽省心理卫生协会会员条例</p>
                            <p>第一条 本会包括个人会员（包括普通会员、资深会员、境外会员）和单位会员。</p>
                            <p>第二条 各类会员条件</p>
                            <p>凡拥护本会章程,符合下列会员条件之一者,均可自愿申请为本会会员。</p>
                            <p>一、个人会员</p>
                            <p>（一）普通会员</p>
                            <p>1、从事心理卫生相关工作，具备中级及以上技术职称或具有同等专业任职资格者。</p>
                            <p>2、心理卫生学、心理学、医学、社会学、教育学及相关学科高等院校毕业，从事心理卫生相关工作者。</p>
                            <p>（二）资深会员</p>
                            <p>本会会员中取得主任医师、教授、研究员职称，具有学术威望，在本学科发展领域中成绩卓著，有重大贡献，热心支持协会工作，能履行资深会员的义务者。</p>
                            <p>（三）境外会员</p>
                            <p>境外心理卫生专家，或非心理卫生学界的境外专家和知名人士，支持本会工作，对我国和我省友好，对促进我省的心理卫生对外交流做出重要贡献者。</p>
                            <p>二、单位会员</p>
                            <p>具有一定数量的心理卫生专业人员、支持本会工作的医疗、科研、教育等企事业单位，和相关的社会团体。</p>
                            <p>第三条 入会程序</p>
                            <p>普通会员由本人向本会提交入会申请书，经理事会或常务理事会批准后，由理事会或理事会授权的机构发给会员证。</p>
                            <p>本会的分支机构、申请人所在各地市心理卫生协会可根据本会理事会的授权，或接受理事会委托，接受普通会员的入会申请，并交本会理事会或常务理事会确认。</p>
                            <p>资深会员由本会组织工作委员会提出名单，报理事会或常务理事会审批后发证。</p>
                            <p>境外会员由本会组织工作委员会或专业委员会提名，并由协会本届理事会2名及以上理事推荐，经本届理事会或常务理事会讨论通过，报上级业务主管单位核准备案。</p>
                            <p>单位会员由单位法定代表人向本会提出申请，加盖单位印章，经理事会或常务理事会审批后发给证书。</p>
                            <p>第四条 各类会员享有下列权利：</p>
                            <p>一、普通会员 </p>
                            <p>1、享有本会的选举权、被选举权和表决权；</p>
                            <p>2、优先参加本会组织的国际和省内外学术交流、培训等；</p>
                            <p>3、优先在本会主办或与本会协作的学术期刊上发表论文；</p>
                            <p>4、对本会工作有批评建议权和监督权。</p>
                            <p>二、资深会员</p>
                            <p>除享有会员的权利外，可终身享有资深会员资格，并可免费获取本会年度活动计划、总结和所在专委会论文汇编。</p>
                            <p>三、境外会员（不具有选举权、被选举权、表决权）</p>
                            <p>1、优先参加本会组织的国内外学术交流、培训等；</p>
                            <p>2、优先在本会主办的学术期刊上发表论文；</p>
                            <p>3、对本会工作有批评建议权和监督权。</p>
                            <p>四、单位会员 </p>
                            <p>1、享有本会的选举权、被选举权、表决权；</p>
                            <p>2、优先参加本会组织的国内外有关学术活动；</p>
                            <p>3、取得本会有关学术资料；</p>
                            <p>4、可要求本会给予技术咨询，协助举办培训班及国内外学术会议；</p>
                            <p>5、享有对本会工作的批评建议权并进行监督。</p>
                            <p>第五条 各类会员履行下列义务：</p>
                            <p>一、普通会员</p>
                            <p>1、遵守本会章程，执行本会决议决定；</p>
                            <p>2、维护本会合法权益；</p>
                            <p>3、完成本会和所在分支机构委托的工作任务，参加本会和所在专业委员会（分会）组织的有关社会公益活动；</p>
                            <p>4、按规定缴纳会费；</p>
                            <p>5、向本会反映情况，提供有关资料。</p>
                            <p>二、资深会员</p>
                            <p>除承担普通会员的义务外，还应当参加本会组织的对心理卫生工作发展战略、政策、重大决策的论证咨询活动。</p>
                            <p>三、境外会员</p>
                            <p>1、拥护中华人民共和国宪法；</p>
                            <p>2、遵守本会章程，执行本会决议决定；</p>
                            <p>3、维护本会合法权益；</p>
                            <p>4、协助本会做好国际间的学术交流；</p>
                            <p>5、向本会介绍国际心理卫生研究的新进展。</p>
                            <p>四、单位会员</p>
                            <p>1、遵守本会章程，执行本会决议决定；</p>
                            <p>2、维护本会合法权益；</p>
                            <p>3、组织和完成本会委托的工作任务；</p>
                            <p>4、按规定缴纳会费；</p>
                            <p>5、向本会反映情况，提供有关资料。</p>
                            <p>第六条 各类会员证书或聘书均由本会统一印制。</p>
                            <p>第七条会员有退会的自由、会员退会应书面通知发证单位，并交回会员证，会员两年不缴纳会费或不参加本会活动，视为自动退会，会员证作废。会员严重违反本会章程，经本会理事会或常务理事会讨论通过后，予以除名并追回会员证或聘书。</p>
                        </article>
                    </div>
                    <div class="gutter-box boxL" v-show="type == 2">
                        <div class="title">
                            <h3>个人会员</h3>
                        </div>
                        <article>
                            <p style="font-weight: 400;color: #333333;text-align: center;font-size: 18px;margin-top: 20px;">安徽省心理卫生协会个人会员</p>
                            <p>第一条 个人会员包括普通会员、资深会员和境外会员。</p>
                            <p>第二条  个人会员享有下列权利：</p>
                            <p>一、普通会员</p>
                            <p>1、享有本会的选举权、被选举权和表决权；</p>
                            <p>2、优先参加本会组织的国际和省内外学术交流、培训等；</p>
                            <p>3、优先在本会主办或与本会协作的学术期刊上发表论文；</p>
                            <p>4、对本会工作有批评建议权和监督权。</p>
                            <p>二、资深会员</p>
                            <p>除享有会员的权利外，可终身享有资深会员资格，并可免费获取本会年度活动计划、总结和所在专委会论文汇编。</p>
                            <p>三、境外会员（不具有选举权、被选举权、表决权）</p>
                            <p>1、优先参加本会组织的国内外学术交流、培训等；</p>
                            <p>2、优先在本会主办的学术期刊上发表论文；</p>
                            <p>3、对本会工作有批评建议权和监督权。</p>
                            <p>第三条 个人会员履行下列义务：</p>
                            <p>一、普通会员</p>
                            <p>1、遵守本会章程，执行本会决议决定；</p>
                            <p>2、维护本会合法权益；</p>
                            <p>3、完成本会和所在分支机构委托的工作任务，参加本会和所在专业委员会（分会）组织的有关社会公益活动；</p>
                            <p>4、按规定缴纳会费；</p>
                            <p>5、向本会反映情况，提供有关资料。</p>
                            <p>二、资深会员</p>
                            <p>除承担普通会员的义务外，还应当参加本会组织的对心理卫生工作发展战略、政策、重大决策的论证咨询活动。</p>
                            <p>三、境外会员</p>
                            <p>1、拥护中华人民共和国宪法；</p>
                            <p>2、遵守本会章程，执行本会决议决定；</p>
                            <p>3、维护本会合法权益；</p>
                            <p>4、协助本会做好国际间的学术交流；</p>
                            <p>5、向本会介绍国际心理卫生研究的新进展。</p>
                        </article>
                    </div>
                    <div class="gutter-box boxL" v-show="type == 3">
                        <div class="title">
                            <h3>单位会员</h3>
                        </div>
                        <article>
                            <p style="font-weight: 400;color: #333333;text-align: center;font-size: 18px;margin-top: 20px;">安徽省心理卫生协会单位会员</p>
                            <p>第一条  单位会员享有下列权利： </p>
                            <p>1、享有本会的选举权、被选举权、表决权；</p>
                            <p>2、优先参加本会组织的国内外有关学术活动；</p>
                            <p>3、取得本会有关学术资料；</p>
                            <p>4、可要求本会给予技术咨询，协助举办培训班及国内外学术会议；</p>
                            <p>5、享有对本会工作的批评建议权并进行监督。</p>
                            <p>第二条 单位会员履行下列义务：</p>
                            <p>1、遵守本会章程，执行本会决议决定；</p>
                            <p>2、维护本会合法权益；</p>
                            <p>3、组织和完成本会委托的工作任务；</p>
                            <p>4、按规定缴纳会费；</p>
                            <p>5、向本会反映情况，提供有关资料。</p>
                        </article>
                    </div>
                    <div class="gutter-box boxL" v-show="type == 4">
                        <div class="title">
                            <h3>入会指南</h3>
                        </div>
                        <article>
                            <p style="font-weight: 400;color: #333333;text-align: center;font-size: 18px;margin-top: 20px;">安徽省心理卫生协会入会指南</p>
                            <p>（一）、普通会员入会条件</p>
                            <p>1、从事心理卫生相关工作，具备中级及以上技术职称或具有同等专业任职资格者。</p>
                            <p>2、心理卫生学、心理学、医学、社会学、教育学及相关学科高等院校毕业，从事心理卫生相关工作者。</p>
                            <p>（二）、会费标准</p>
                            <p>个人会员（一届会费200元）</p>
                            <p>（三）、入会程序</p>
                            <p>1、由本人向本会提交“安徽省心理卫生协会个人会员入会申请表”；</p>
                            <p>2、个人会员提交两张2寸近期证件照，身份证、最高学历证、证书资格证复印件或照片1份；</p>
                            <p>3、以上资料发送至协会秘书处邮箱：ahxh2018@126.com；</p>
                            <p>4、会员申请经理事会或常务理事会批准后，交纳会费，会费缴纳方式：</p>
                            <p>户名：安徽省心理卫生协会</p>
                            <p>账号：181225905219</p>
                            <p>开户行：中国银行合肥梅山路支行</p>
                            <p>纳税人识别号：51340000743056355E</p>
                            <p>5、发放会员证书。</p>
                        </article>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data(){
            return{
                type:1,
                hytl:require("../../assets/img/hytl.png"),
                hytl2:require("../../assets/img/hytl2.png"),
                grhy:require("../../assets/img/grhy.png"),
                grhy2:require("../../assets/img/grhy2.png"),
                dwhy:require("../../assets/img/dwhy.png"),
                dwhy2:require("../../assets/img/dwhy2.png"),
                fzjg:require("../../assets/img/fzjg.png"),
                fzjg2:require("../../assets/img/fzjg2.png"),
            }
        },
        methods:{
            check(data){
                this.type = data;
            }
        }
    }
</script>

<style scoped>
    .association{
        background: #ffffff;
    }
    .explainL{
        height: 50px;
        line-height: 50px;
        width: 1080px;
        margin: 0 auto;
        text-align: left;
    }
    .routeL{
        cursor: pointer;
    }
    .brief{
        width: 1080px;
        margin: 20px auto 76px;
    }
    .gutter-box{
        background: #ffffff;
    }
    .gutter-box>ul>li{
        height: 44px;
        line-height: 44px;
        cursor: pointer;
    }
    .active{
        font-weight: 400;
        color: #0EC2DB;
    }
    .boxL{
        padding: 15px;
    }
    .title{
        overflow: hidden;
        border-bottom: 1px solid #E5E5E5;
    }
    .title>h3{
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        color: #0EC2DB;
        float: left;
        height: 40px;
        display: inline-block;
        border-bottom: 2px solid #0EC2DB;;
        margin-bottom: 0px;
    }
    article>p{
        line-height: 24px;
        text-align: left;
        text-indent:30px
    }
    .uls{
        text-align: center;
    }
    .xh{
        font-size: 18px;
        font-weight: 400;
        color: #0E60DB;
    }
    .jj{
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    .jjs{
        border-bottom: 1px solid #E5E5E5;
    }
    .jj h3{
        text-indent:24px
    }
    .jj p{
        font-size: 14px;
        font-weight: 400;
        color: #444444;
        margin-bottom: 5px;
        text-indent:24px
    }
    .imgR{
        width: 135px;
        height: 178px;
        margin-right: 10px;
    }
    .imgXX{
        margin-top: -5px;
        margin-right: 5px;
    }
</style>