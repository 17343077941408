<template>
    <div>
        <div class="association">
            <div class="explainL">
                <span>当前位置：</span>
                <span class="routeL">首页></span>
                <span>协会动态</span>
            </div>
        </div>
        <div class="brief">
            <div class="titleT">
                <span :class="[this.type == 1 ? 'active':'']" @click="getList('1')">协会动态</span>
                <span :class="[this.type == 2 ? 'active':'']" @click="getList('2')">通知公告</span>
            </div>
            <div class="new">
                <ul>
                    <li v-for="(item,index) in associate" :key="index">
                        <img :src="url+item.articlePic" alt="" style="width: 304px;height: 200px;">
                        <div @click="details(item.id)">
                            <p class="TP1">{{item.title}}</p>
                            <p class="TP2" v-html="item.description"></p>
                            <p class="TP3">
                                <img src="../../assets/img/clock.png" alt="">
                                <span>{{item.createTime}}</span>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page.sync="pageIndex"
                               :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import { getUrl } from "../../utils";

    export default {
        name: "index",
        data(){
            return{
                associate:[],
                type:1,
                page:1,
                pageIndex:1,
                pageSize:8,
                pageTotal:0,
                url:getUrl()
            }
        },
        components:{

        },
        mounted() {
            this.getData()
        },
        methods: {
            //首次默认获取
            getData(){
                //获取协会动态
                this.$http({
                    url: this.$http.adornUrl('/psychologyArticle/list'),
                    method: 'get',
                    params: this.$http.adornParams({
                        'page': this.page,
                        'size': this.pageSize,
                        'psychologyColumnId':1
                    })
                }).then((res) => {
                    if ( res.data.code == 200) {
                        this.associate = res.data.data.content;
                        this.pageTotal = res.data.data.totalElements
                    }else{
                        this.associate = [];
                    }
                });
            },
            getList(data){
                this.type = data;
                this.page = 1;
                this.$http({
                    url: this.$http.adornUrl('/psychologyArticle/list'),
                    method: 'get',
                    params: this.$http.adornParams({
                        'page': this.page,
                        'size': this.pageSize,
                        'psychologyColumnId':data
                    })
                }).then((res) => {
                    if ( res.data.code == 200) {
                        this.associate = res.data.data.content;
                        this.pageTotal = res.data.data.totalElements
                    }else{
                        this.associate = [];
                    }
                });
            },
            handlePageChange (val) {
                this.page = val;
                this.getList(this.type);
            },
            details(id){
                this.$router.push({ path: "/detail/index", query: { id: id } });
            }
        }
    }
</script>

<style scoped>
    .association{
        background: #ffffff;
    }
    .explainL{
        height: 50px;
        line-height: 50px;
        width: 1080px;
        margin: 0 auto;
        text-align: left;
    }
    .routeL{
        cursor: pointer;
    }
    .brief{
        width: 1080px;
        margin: 20px auto 76px;
        background: #ffffff;
        padding: 15px;
    }
    .titleT{
        overflow: hidden;
    }
    .titleT>span{
        display: inline-block;
        float: left;
        margin-right: 26px;
        width: 102px;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        text-align: center;
    }
    .pagination{
        text-align: center;
    }
    .active{
        background: #0EC2DB;
        border-radius: 20px 20px 20px 20px;
        opacity: 1;
        color: #ffffff;
    }
    .new>ul>li{
        display: flex;
        justify-content: center;
        margin: 10px 0 30px;
        text-align: left;
    }
    .new>ol>li>img{
        width: 304px;
        height: 200px;
    }
    .new>ul>li>div{
        display: inline-block;
        width: 65%;
        margin: 5px 30px 0;
        border-bottom: 1px solid #E5E5E5;
        cursor: pointer;
    }
    .TP1{
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        padding: 5px 0 10px;
        margin-bottom: 0;
    }
    .TP2{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        height: 104px;
        overflow:hidden;
    }
    .TP3{
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        bottom: -50px;
        overflow: hidden;
    }
    .TP3>span{
        position: relative;
        top: 0px;
        margin-left: 30px;
        /*float: right;*/
    }
</style>