<template>
   <div class="top-banner">
    <div class="banner">
        <a-carousel autoplay>
                <div >
                    <img src="../../assets/img/banner.png" alt="" style="width: 100%;cursor: pointer;">
                </div>
            </a-carousel>
    </div>
        <div class="cont1">
         
            <div class="gutter-box1">
                        <div class="title1">
                            <h3>活动通知</h3>
                        </div>
            </div>
           
        </div>
        <div class="cont2">
            <div class="huodong">
                <div class="huodong1">
                    <img src="../../assets/img/zctg.png" alt="">
                    <div class="p">注册投稿</div>
                    <div class="zhuce">
                   <div class="yuan"></div>
                   <span>注册账号</span>
                    </div>
                    <div class="zhuce1">
                   <div class="yuan"></div>
                   <span>登录已有账号</span>
                    </div>
                </div>
                <div class="huodong1">
                    <img src="../../assets/img/zwsm.png" alt="">
                    <div class="p">征文说明</div>
                    <div class="zhuce" @click="essay()">
                   <div class="yuan"></div>
                   <span>会议征文通知</span>
                    </div>
                  
                </div>
                <div class="huodong1">
                    <img src="../../assets/img/xjpx.png" alt="">
                    <div class="p">先进评选</div>
                    <div class="zhuce">
                   <div class="yuan"></div>
                   <span @click="individual()">先进个人申请</span>
                    </div>
                    <div class="zhuce1">
                   <div class="yuan"></div>
                   <span @click="individual1()">先进集体申请</span>
                    </div>
                </div>
                <div class="huodong1">
                    <img src="../../assets/img/chsm.png" alt="">
                    <div class="p">参会说明</div>
                 <!--    <div class="zhuce">
                   <div class="yuan"></div>
                   <span @click="participants()">参会申请</span>
                    </div> -->
                    <div class="zhuce1">
                   <div class="yuan"></div>
                   <span @click="payment()">参会申请</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods:{
            essay(){
                this.$router.push({ name: "essay"})
            },
            individual(){
                this.$router.push({ name: "individual"})
            },
            individual1(){
                this.$router.push({ name: "individual1"})
            },
            participants(){
                this.$router.push({ name: "participants"})
            },
            payment(){
                this.$router.push({ name: "payment"})
            }
        }
};
</script>

<style scoped>
    .banner{
        height: 425px;
        width: 100%;
    }
    .cont{
        width: 1200px;
        margin:  auto;
        background: #ffffff;
        padding:50px 20px;
    }
    .cont1{
        width: 1200px;
        margin:  auto;
        padding: 50px 20px 10px;
    }
     .cont2{
        width: 1200px;
        margin:  auto;
        /* padding: 50px 20px; */
    }
    .gutter-box{
        height: 358px;
    }
    .gutter-box1{
        height: 80px;
    }
    .title{
        height: 50px;
        line-height: 50px;
        overflow: hidden;
        border-bottom: 1px solid rgba(196, 196, 196, 0.6);;
    }
    .title1{
        height: 50px;
        line-height: 50px;
        overflow: hidden;
    }
    .title1>h3{
        font-size: 22px;
        font-weight: 400;
        color: #0E60DB;
        display: inline-block;
        float: left;
        height: 50px;
        border-bottom: 2px solid #0E60DB;
    }
    .title>h3{
        font-size: 22px;
        font-weight: 400;
        color: #0E60DB;
        display: inline-block;
        float: left;
        height: 50px;
        border-bottom: 3px solid #0E60DB;
    }
   
    .articleList{
        height: 90%;
        padding-top: 23px;
    }
    .articleList1{
        height: 90%;
        padding-top: 30px;
        margin-left: 20px;
    }
    .articleList1>li{
       height: 92px;
       line-height: 42px;
       cursor: pointer;
       /* border-bottom: 1px dashed #E5E5E5; */
   }
    .articleList>li{
       
        height: 52px;
        line-height: 52px;
        cursor: pointer;
        border-bottom: 1px dashed #E5E5E5;
    }
    .span1{
        display: inline-block;
        width: 70%;
        color:#858587;
        font-weight: 700;
     
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        margin-left: 5px;
    }
    .span1>p{
        width: 40%;
        font-size: 12px;
        color: #98989A;
       border-bottom: 1px dashed #E5E5E5;

    }
    .span2{
        display: inline-block;
        float: right;
    }
  
    .ddT{
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #EE6238;
        border-radius: 50%;
        position: relative;
        top:16px;
        float: left;
    }
    .gutter-box >>> .el-carousel__indicators{
        display: none;
    }
    .gutter-box >>> .el-carousel__arrow{
        border-radius: 0;
        top: 94%;
        width: 24px;
        height: 24px;
        background-color: #5E9DFB;
    }
    .gutter-box >>> .el-carousel__arrow:hover{
        background-color: #0E60DB;
    }
    .gutter-box >>> .el-carousel__arrow--left{
        left: 85%;
    }
    .huodong{
        margin-bottom: 150px;
        width: 100%;
        display: flex;
        justify-content: space-between;
   
    }
    .huodong1{
        border-radius: 10px;
        width: 250px;
        height: 280px;
        text-align: center;
        background: white;
       
    }
    .huodong1>img{
        width: 100px;
        height: 100px;
        margin: 20px 0;
    }
    .huodong1>.p{
        font-size: 20px;
        font-weight: 700;
        color: #7995EA;
    }
    .zhuce{
        margin-top: 20px;
        font-size: 16px;
        font-weight: 530;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        color: #777578;
        cursor: pointer;
    }
    .zhuce1{
        margin-top: 10px;
        font-size: 16px;
        font-weight: 530;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        color: #777578;
        cursor: pointer;
    }
    .yuan{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 70px;
        margin-right: 10px;
        background: #98C4F6;
    }
  
</style>
