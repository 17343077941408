<template>
  <div class="top-headers">
    <div class="top">
      <div class="box">
        <img src="../assets/img/logo.png" alt="" />
        <div>
          <p>安徽省心理卫生协会</p>
          <p>Anhui Association for Mental Health</p>
        </div>
      </div>
      <div class="boxD">
        <div v-show="show">
          <span @click="Login()">登录</span>
          |
          <span @click="register()">注册</span>
        </div>
        <!-- 消息通知 -->
        <div v-show="!show">
          <!-- 消息通知 -->
          <el-popover placement="bottom" width="332" trigger="click" v-model="hidePopover">
            <div class="main1">
              <div class="headLs">
                <div class="wordG2">最新信息</div>
              </div>
              <div class="pageOfficals">
                <div class="messageParts" v-for="(item, index) in talkList" :key="index" @click="haveHistory(item)">
                  <div class="avaterImg">
                    <img src="../assets/img/avatar.png" alt="" />
                  </div>
                  <div class="wordGongss">
                    <span class="nameSapce">{{ item.lastMsg.fromNick }}</span>
                    <span class="timePart">{{
                      item.lastMsg.time | timeData
                    }}</span>
                  </div>
                  <div class="lastWord">{{ item.lastMsg.content }}</div>
                </div>
              </div>
            </div>
            <el-badge :is-dot="haveMessage1" class="item" slot="reference">
              <img src="../assets/img/kefu.png" alt="" class="imageKefu" @click="getInformation()" />
            </el-badge>
          </el-popover>
          <el-popover placement="bottom" width="506" trigger="click">
            <div class="main1">
              <div class="headL">
                <div class="wordG" @click="check(1)">
                  <div class="word16" :class="[type == 1 ? 'actives' : '']">
                    官方
                  </div>
                  <div class="redCircle">{{ notReadNum }}</div>
                </div>

                <div class="borderGray"></div>

                <div class="wordG" @click="check(2)">
                  <div class="word16" :class="[type == 2 ? 'actives' : '']">
                    通知
                  </div>
                  <div class="redCircle">{{ notReadNum2 }}</div>
                </div>
                <div class="wordXiao">消息设置</div>
                <div class="wordQuan" @click="checkAll()">全部已读</div>
              </div>
              <div class="pageOffical" v-show="type == 1">
                <div class="messagePart" v-for="(item, index) in officalMessage" :key="index" @click="goto(item)">
                  <div class="wordGong">
                    {{ item.bulletinContent }}
                  </div>
                  <div class="dataWord">{{ item.createTime }}</div>
                </div>
              </div>
              <div class="pageOffical" v-show="type == 2">
                <div class="messagePart" v-for="(item, index) in normalMessage" :key="index" @click="gotos(item)">
                  <div class="imagePart">
                    <img :src="baseUrl + item.fromAvatar" alt="" srcset="" class="circleImg" v-if="item.fromAvatar" />
                    <img src="../assets/img/avatar.png" alt="" srcset="" class="circleImg" v-else />
                  </div>
                  <div class="wordGongs">
                    {{ item.fromName }}
                  </div>
                  <div class="wordGongs1">
                    <span class="answer1" v-if="item.type == 1">回答了</span>
                    <span class="answer1" v-if="item.type == 3">点赞了</span>
                    <span class="answer2"> {{ item.problemTitle }}</span>
                  </div>
                  <div class="dataWords">{{ item.createTime }}</div>
                </div>
              </div>
              <div class="bottomWords" @click="message()">查看全部</div>
            </div>
            <el-badge :is-dot="haveMessage" class="item" slot="reference">
              <img src="../assets/img/message1.png" alt="" @click="getMessage()" />
            </el-badge>
          </el-popover>
          <el-dropdown>
            <span class="el-dropdown-link" ref="echarType">
              <el-avatar size="large" :src="circleUrl"></el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="setting()">个人中心</el-dropdown-item>
              <el-dropdown-item @click.native="signOut()">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="contain">
      <div class="containH">
        <div class="daohang" @click="homePage()">
          <span :class="[typeA == 1 ? 'activeA' : '']" @click="getTo('1')">首页</span>
        </div>
        <div class="daohang" @click="association()">
          <span :class="[typeA == 2 ? 'activeA' : '']" @click="getTo('2')">关于协会</span>
        </div>
        <div class="daohang" @click="member()">
          <span :class="[typeA == 3 ? 'activeA' : '']" @click="getTo('3')">会员服务</span>
        </div>
        <div class="daohang" @click="learning()">
          <span :class="[typeA == 4 ? 'activeA' : '']" @click="getTo('4')">学术交流</span>
        </div>
        <div class="daohang" @click="Consult()">
          <span :class="[typeA == 5 ? 'activeA' : '']" @click="getTo('5')">心理咨询</span>
        </div>
        <div class="daohang" @click="psychology()">
          <span :class="[typeA == 6 ? 'activeA' : '']" @click="getTo('6')">心理问答</span>
        </div>
        <div class="daohang" @click="group()">
          <span :class="[typeA == 7 ? 'activeA' : '']" @click="getTo('7')">社会服务</span>
        </div>
        <div class="daohang" @click="examination()">
          <span :class="[typeA == 8 ? 'activeA' : '']" @click="getTo('8')">在线考试</span>
        </div>
        <div class="daohang">
          <a href="https://association.ahaxt.com/Login?redirect=%2FHomepage" target="_blank" style="color: #fff;">心理咨询师培训</a>

        </div>
      </div>
    </div>
    <!-- //弹窗聊天界面 -->
    <div class="chat-box show-netcall-box" id="chatBox" v-show="chatShow">
      <div class="title" id="chatTitle">
        <span id="nickName" class="chatPeople">{{ chatPeople }}</span>
        <div class="cloudMsg tc radius4px" data-record-id="" id="cloudMsg" @click="closeChat()">
          关闭
        </div>
      </div>
      <div class="netcall-box" id="netcallBox">
        <div class="netcall-mask hide">
          <div class="netcallTip"></div>
        </div>
      </div>
      <div id="whiteboard"></div>
      <div class="chat-content box-sizing" id="chatContent" ref="middle">
        <!-- 聊天记录 -->
        <div class="item" v-for="(item, index) in chatInfo" :key="index"
          :class="item.flow == 'out' ? 'item-me' : 'item-you'">
          <img src="../assets/img/default-icon.png" alt="" class="img j-img" />
          <div class="msg msg-text j-msg">
            <div class="box">
              <div class="cnt">
                <div class="f-maxWid" v-if="item.file == null">
                  {{ item.content }}
                </div>
                <img :src="item.file.url" alt="" v-if="item.file != null" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="chat-editor box-sizing" id="chatEditor" data-disabled="1">

        <input v-model="sendMeesage" id="messageText" class="chat-btn msg-input box-sizing radius5px p2p" rows="1"
          autofocus="autofocus" maxlength="500" placeholder="写下您的回答..." />
        <span class="chat-btn msg-type" id="chooseFileBtn">
          <input type="file" style="display: none" ref="img" @change="sendImagemessage" id="fileId" />
          <a class="icon icon-file" type="file" @click="choiceImg">
            <img src="../assets/img/submitP.png" alt=""></a>
        </span>
        <a class=" radius5px absule" id="sendBtn" @click="sendmessage">
          <img src="../assets/img/sendMessage.png" alt="" class="sendImg"></a>
        <form action="#" id="uploadForm">
          <input multiple="multiple" type="file" name="file" id="uploadFile" class="hide" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getUrl } from "../utils";
import SDK from "../../NIM_Web_NIM_v9.2.0";
export default {
  props: ["loginState"],
  data() {
    return {
      haveMessage1: false,
      haveMessage: false,
      typeA: this.$cookie.get('typeA'),
      hidePopover: false,
      chatPeople: "",
      sendMeesage: "",
      chatShow: false,
      roleid: this.$cookie.get("roleid"),
      notReadNum: 0,
      notReadNum2: 0,
      type: "1",
      userid: this.$cookie.get("useridList"),
      userName: this.$cookie.get("username"),
      avatarName: this.$cookie.get("avatarName"),
      show: true,
      accids: "",
      circleUrl: require("../assets/img/avatar.png"),
      baseUrl: getUrl(),
      officalMessage: [],
      normalMessage: [],
      talkList: [],
      chatInfo: [],
      account: "",
      token: "",
    };
  },
  filters: {
    timeData: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return MM + "/" + d + " " + h + ":" + m;
    },
  },
  created() {
    this.getMessage();
    this.getInformation();
  },
  watch: {
    loginState: function () {
      this.getInformation();
      console.log(this.loginState);
      this.getMessage();
      if (this.loginState == "sucess") {
        this.show = false;
        this.roleid = this.$cookie.get("roleid");
      }
    },
  },
  mounted() {
    if (this.userName) {
      this.show = false;
    }
  },
  methods: {
    checkAll() {
      this.$http({
        url: this.$http.adornUrl("/message/updateReadByUserId"),
        method: "post",
        params: this.$http.adornParams({
          userId: this.userid,
        }),
      }).then((res) => {
        if (res.data.code == 200) {
          this.getMessage();
          this.notReadNum = 0;
          this.notReadNum2 = 0;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    goto(item) {
      // console.log(item);
      this.$http({
        url: this.$http.adornUrl("/message/updateReadById"),
        method: "post",
        params: this.$http.adornParams({
          id: item.id,
        }),
      }).then((res) => {
        if (res.data.code == 200) {
          this.getMessage();
          this.$router.push({ name: "detail", query: { id: item.bulletinId } });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    gotos(item) {
      // console.log(item);
      this.$http({
        url: this.$http.adornUrl("/message/updateReadById"),
        method: "post",
        params: this.$http.adornParams({
          id: item.id,
        }),
      }).then((res) => {
        if (res.data.code == 200) {
          this.getMessage();
          this.$router.push({
            name: "psychology",
            query: { id: item.problemId },
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    closeChat() {
      this.chatShow = false;
    },
    sendmessage() {
      var that = this;
      that.nim.sendCustomMsg({
        scene: "p2p",
        to: this.accids,
        content: that.sendMeesage,
        done: function sendMsgDone(error, msg) {
          // console.log(msg);
          that.chatInfo.push(msg);
          that.sendMeesage = "";
          that.$nextTick(() => {
            let middle = that.$refs["middle"];
            middle.scrollTop = middle.scrollHeight;
          });
        },
      });
    },
    choiceImg() {
      this.$refs.img.dispatchEvent(new MouseEvent("click"));
    },
    sendImagemessage(file) {
      var that = this;
      that.nim.sendFile({
        scene: "p2p",
        to: this.accids,
        type: "image",
        /* eslint-disable */
        fileInput: fileId,
        done: function sendMsgDone(error, msg) {
          // console.log(msg);
          that.chatInfo.push(msg);
          that.$nextTick(() => {
            let middle = that.$refs["middle"];
            middle.scrollTop = middle.scrollHeight;
          });
        },
      });
    },
    haveHistory(item) {
      console.log(1);
      const that = this;
      that.hidePopover = false;
      that.accids = item.to;
      that.chatPeople = item.lastMsg.fromNick;
      window.scrollTo(0, 0);
      that.chatShow = true;
      that.nim.getHistoryMsgs({
        scene: "p2p",
        to: item.to,
        asc: true,
        done: getHistoryMsgsDone,
      });

      function getHistoryMsgsDone(error, obj) {
        console.log(obj);
        if (!error) {
          // console.log("获取历史记录", obj.msgs);
          const msgs = obj.msgs;
          that.chatInfo = msgs;
          that.$nextTick(() => {
            let middle = that.$refs["middle"];
            middle.scrollTop = middle.scrollHeight;
          });
          // that.$refs.common_chat.goEnd();
        }
      }
    },
    getInformation() {
      console.log(this.roleid);
      if (this.roleid == "2" || this.roleid == "6") {
        this.$http({
          url: this.$http.adornUrl("/neteaseUser/getUser"),
          method: "get",
        }).then((res) => {
          // console.log(res.data);
          if (res.data.code == 200) {
            this.account = res.data.data.accid;
            this.token = res.data.data.token;
            this.initSDK();
          } else {
          }
        });
      } else {
        // console.log("不是医生用户");
      }
    },
    initSDK() {
      this.nim = SDK.getInstance({
        debug: true,
        //appkey固定
        appKey: "ece4c0440f2dcb27b2d6e99c2ce53152",
        //账号
        account: this.account,
        token: this.token,
        // privateConf: {}, // 私有化部署方案所需的配置
        onconnect: this.onConnect, // 链接已经建立(登录成功)
        onwillreconnect: this.onWillReconnect,
        ondisconnect: this.onDisconnect,
        onerror: this.onError,
        // 用户名片
        onmyinfo: this.onMyInfo,
        onupdatemyinfo: this.onUpdateMyInfo,
        onusers: this.onUsers,
        onupdateuser: this.onUpdateUser,
        // // 会话
        // syncSessionUnread: true,
        onsessions: this.onSessions,
        onupdatesession: this.onUpdateSession,
        // 同步完成
        onsyncdone: this.onSyncDone,
        onofflinemsgs: this.onOfflineMsgs,
      });
    },
    onSessions(sessions) {
      (this.talkList = []), console.log("收到会话列表", sessions);
      for (var i = 0; i < sessions.length; i++) {
        if (sessions[i].lastMsg.flow == "in") {
          this.talkList.push(sessions[i]);
        }
      }
      console.log(this.talkList);
      if (this.talkList.length == 0) {
        this.haveMessage1 = false
      } else {
        this.haveMessage1 = true
      }
    },
    check(data) {
      this.type = data;
    },

    getMessage() {
      this.haveMessage = true;
      this.userid = this.$cookie.get("useridList");
      // console.log(this.userid)
      if (this.userid) {
        (this.normalMessage = []),
          (this.officalMessage = []),
          this.$http({
            url: this.$http.adornUrl("/message/getUnReadByUserId"),
            method: "get",
            params: this.$http.adornParams({
              userId: this.userid,
            }),
          }).then((res) => {
            // console.log(res.data);
            if (res.data.data.length == 0) {
              this.haveMessage = false;
            } else {
              this.haveMessage = true;
              if (res.data.code == 200) {
                for (var i = 0; i < res.data.data.length; i++) {
                  if (res.data.data[i].type == 6) {
                    this.officalMessage.push(res.data.data[i]);
                  } else if (res.data.data[i].type == 5) {
                    res.data.data[i].bulletinContent =
                      res.data.data[i].problemTitle;
                    this.officalMessage.push(res.data.data[i]);
                  } else {
                    this.normalMessage.push(res.data.data[i]);
                  }
                }
                this.notReadNum = this.officalMessage.length;
                this.notReadNum2 = this.normalMessage.length;
              } else {
              }
            }
          });
      } else {
        // console.log("notLogin");
      }
    },
    //退出登录
    signOut() {
      this.$http({
        url: this.$http.adornUrl("/auth/logout"),
        method: "DELETE",
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success('退出成功');
          this.$emit("loginStates", "fail");
          this.show = true;
          this.$cookie.set("roleid", "");
          this.$cookie.set("token", "");
          this.$cookie.set("username", "");
          this.$cookie.set("useridList", "");
          this.$cookie.set("avatarName", "");
        }
      });
      this.$router.push({ name: "login" });
    },

    homePage() {
      this.$router.push({ name: "dashBoard" });
    },
    association() {
      this.$router.push({ name: "association" });
    },
    learning() {
      this.$router.push({ name: "learning" });
    },
    Login() {
      this.$router.push({ name: "login" });
    },
    register() {
      this.$router.push({ name: "register" });
    },
    member() {
      this.$router.push({ name: "member" });
    },
    setting() {
      this.$router.push({ name: "setting" });
    },
    group() {
      this.$router.push({ name: "group" });
    },
    psychology() {
      this.$router.push({ name: "psychology" });
    },
    Consult() {
      this.$router.push({ name: "Consult" });
    },
    message() {
      this.$router.push({ name: "message" });
    },
    examination() {
      this.$router.push({ name: "examination" });
    },
    //点击跳转
    getTo(key) {
      this.typeA = key;
      this.$cookie.set('typeA', key);
    },
  },
};
</script>
<style scoped>
.chatPeople {
  margin-left: 30px;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}

.chat-box {
  background-color: #fff;
  position: absolute;
  top: 18%;
  left: 16%;
  box-shadow: 4px 4px 4px 4px rgba(14, 96, 219, 0.1);
}

.imgPart {
  float: left;
  margin-left: 22px;
  margin-top: 22px;
  width: 135px;
  height: 178px;
}

.wordPart {
  float: left;
  margin-left: 20px;
  width: 623px;
  height: 200px;
  margin-top: 22px;
}

.wordT {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  width: 92%;
  float: left;
  margin-top: 4px;
}

.imageKefu {
  width: 24px;
  height: 24px;
}

.top-headers {
  height: 148px;
  width: 100%;
  background: #ffffff;
}

.top {
  width: 1080px;
  height: 98px;
  margin: 0 auto;
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: space-between;
}

.box {
  display: flex;
  align-items: center;
  justify-content: left;
  /*margin-right: 62%;*/
}

.box>div>p {
  margin: 0 0 0 10px;
  text-align: left;
}

.contain {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: linear-gradient(158deg, #1e7dec 0%, #39bfdd 100%);
}

.containH {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.daohang {
  cursor: pointer;
  color: #fff;
  float: left;
  font-weight: 400;
  width: 10.5%;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
}

.daohang>span {
  display: inline-block;
  height: 50px;
}

.main1 {
  width: 100%;
  height: 432px;
}

.items {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  width: 50px;
  text-align: center;
  margin-left: 30px;
  float: left;
  margin-top: -4px;
}

.title {
  height: 71px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 0px 0px;
  line-height: 80px;
  color: #666;
  position: relative;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -moz-border-top-left-radius: 5px;
}

.bottomWords {
  cursor: pointer;
  width: 100%;
  text-align: center;
  line-height: 47px;
  height: 47px;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.imagePart {
  width: 40px;
  margin-left: 20px;
  float: left;
}

.wordGongs {
  width: 200px;
  margin-left: 20px;
  float: left;

  line-height: 25px;
  margin-top: 20px;
}

.answer2 {
  color: #333;
}

.answer1 {
  color: #666;
}

.wordGongs1 {
  width: 200px;
  margin-left: 20px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  /*文字超出宽度则显示ellipsis省略号*/
  text-overflow: ellipsis;
  line-height: 25px;
  margin-top: 0px;
}

.wordGong {
  width: 240px;
  margin-left: 20px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  /*文字超出宽度则显示ellipsis省略号*/
  text-overflow: ellipsis;
  line-height: 25px;
  margin-top: 20px;
}

.avaterImg {
  float: left;
  width: 15%;
}

.wordGongss {
  width: 70%;
  margin-left: 20px;
  float: left;
  line-height: 25px;
  margin-top: 8px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.dataWord {

  float: left;
  margin-left: 42px;
}

.nameSapce {
  float: left;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
}

.timePart {
  white-space: nowrap;
  width: 30%;
  float: left;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-left: 10%;
}

.lastWord {
  float: left;
  margin-left: 20px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  /*文字超出宽度则显示ellipsis省略号*/
  text-overflow: ellipsis;
  width: 200px;
}

.dataWords {
  float: left;
  margin-left: 42px;
  margin-top: -40px;
}

.pageOffical {
  height: 350px;
  width: 470px;
  margin-left: 12px;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
}

.pageOfficals {
  height: 350px;
  width: 100%;
  margin-left: 12px;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
}

.circleImg {
  width: 36px;
  height: 36px;

  border-radius: 36px;
}

.messagePart {
  cursor: pointer;
  line-height: 83px;
  width: 100%;
  height: 83px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.messageParts {
  cursor: pointer;
  line-height: 60px;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.headL {
  width: 470px;
  height: 45px;
  line-height: 45px;
  margin-left: 12px;
  opacity: 1;
  border-bottom: 1px solid #e5e5e5;
}

.headLs {
  width: 100%;
  height: 45px;
  line-height: 45px;
  margin-left: 12px;
  opacity: 1;
  border-bottom: 1px solid #e5e5e5;
}

.boxD {
  cursor: pointer;
}

.borderGray {
  width: 0px;
  height: 22px;
  opacity: 1;
  border-left: 1px solid #e5e5e5;
  float: left;
  margin-top: 8px;
  margin-left: 10px;
}

.redCircle {
  line-height: 18px;
  width: 18px;
  height: 18px;
  background: #ee0f0f;
  opacity: 1;
  float: left;
  border-radius: 18px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 14px;
  margin-left: 5px;
}

.actives {
  border-bottom: 3px solid #4acfe1;
}

.wordXiao {
  width: 80px;
  height: 45px;
  margin-left: 100px;
  float: left;
  cursor: pointer;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  visibility: hidden;
}

.wordQuan {
  width: 76px;
  height: 45px;
  margin-left: 10px;
  float: left;
  cursor: pointer;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #f03333;
}

.word16 {
  width: 38px;
  height: 45px;
  margin-left: 20px;
  float: left;
  cursor: pointer;
}

.wordG {
  width: 90px;
  float: left;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.wordG1 {
  width: 150px;
  float: left;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.wordG2 {
  width: 150px;
  float: left;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: left;
  margin-left: 8px;
}

.active {
  border-bottom: 3px solid #ffffff;
}

.activeA {
  border-bottom: 3px solid #ffffff;
}
</style>