<template>
    <div>
        <div class="association">
            <div class="explainL">
                <div class="explainL">
                <span>当前位置：</span>
                <span class="routeL">在线考试</span>
            </div>
            </div>
        </div>
        <div class="brief">
            <a-row :gutter="20">
                <a-col class="gutter-row" :span="3">
                    <div style=" background: #ffffff; padding: 10px;">
                        <ul class="uls">
                           <!--  <li :class="[type == 1 ? 'active':'']" @click="check('1')"> <img v-show="type == 1" src="../../assets/img/Frame2.png" alt=""><img v-show="type == 2" src="../../assets/img/Frame4.png" alt=""> 当前考试</li>
                            <li :class="[type == 2 ? 'active':'']" @click="history1('2')"> <img v-show="type == 1" src="../../assets/img/Frame(1).png" alt=""><img v-show="type == 2" src="../../assets/img/Frame(3).png" alt=""> <span>历史考试</span></li> -->
                            <div style="cursor: pointer;" @click="history1('2')">返回</div>
                        </ul>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="5" v-show="type == 1">
                        <div style="height:950px;background: white;">
                        <div class="titles">答题卡</div>
                        <div class="gutter-box">
                        <div>单选题（共{{single}}题,合计{{singleScore}}分）</div>
                        <ul class="ul" >
                            <li :class="[!v.stuAnswer?'colo': v.isRight == 0?'col':'cols']" v-for="(v,i) in Radio" :key="i">{{v.num}}</li>
                        </ul>
                        <div>多选题（共{{multiple}}题,合计{{multipleScore}}分）</div>
                        <ul class="ul">
                            <li :class="[!v.stuAnswer?'colo': v.isRight == 0?'col':'cols' ]" v-for="(v,i) in Selection" :key="i">{{v.num}}</li>
                        </ul>
                        <div>判断题（共{{judge}}题,合计{{judgeScore}}分）</div>
                        <ul class="ul">
                            <li :class="[!v.stuAnswer ? 'colo' : v.isRight == 0 ? 'col':'cols']" v-for="(v,i) in Judgment" :key="i">{{v.num}}</li>
                        </ul>
                    </div>
                    <div class="titles">考试结果</div>
                     <div class="foot" style="text-align:center;margin-top:20px">
                            <span>得分:{{Score}}分</span><span>用时:{{duration}}分钟</span>
                        </div>
                        <div class="foot">
                    <div style="display:flex;align-items: center;">
                            <div class="circle"></div>
                            <div> 未答</div>
                        </div>
                        <div style="display:flex;align-items: center;">
                            <div class="circle" style="background:#1AC184"></div>
                            <div> 正确</div>
                        </div>
                        <div style="display:flex;align-items: center;">
                            <div class="circle" style="background:#EE5257"></div>
                            <div> 错误</div>
                        </div>
                </div>
                       
                </div>
                </a-col>
                <a-col class="gutter-row" :span="16" v-show="type == 1">
                    <div style="border:1px solid #BDBDBD;background: #fff;">
                    <div>
                        <div   v-show="index == 1">
                            <div class="title" >单选题（共{{single}}题,合计{{singleScore}}分）</div>
                            <div class="gutter-box1 ">
                                <div style="position: relative;" v-for="(v,i) in Radio" :key="i">
                                <div style="display:flex" >{{v.num}}.{{v.content}} 
                                     <div class="right" v-show="v.isRight == 0">正确</div>
                                     <div class="err" v-show="v.isRight == 1">错误</div>
                                     <div class="NotAnswered" v-show="!v.stuAnswer">未答</div>
                                    </div>
                            <div class="options" v-for="(val,inde) in v.optionList" :key="inde">
                              <div class="Selected">{{val}}</div>                    
                            </div>
                            <div class="absolute"><span style="margin-right:20px">考生答案：<span :style="{'color':v.isRight==0?'#1AC184':'#F66D6E'}">{{v.stuAnswer}}</span></span><span>正确答案：<span style="color:#1AC184">{{v.answer}}</span></span></div>
                        </div>
                    </div>
                        </div>
                        <div  v-show="index == 2">
                            <div class="title" >多选题（共{{multiple}}题,合计{{multipleScore}}分）</div>
                            <div class="gutter-box1 ">
                                <div style="position: relative;" v-for="(v,i) in Selection" :key="i">
                                <div style="display:flex">  {{v.num}}.{{v.content}} 
                                    <div class="right" v-show="v.isRight == 0">正确</div>
                                    <div class="err" v-show="v.isRight == 1">错误</div>
                                    <div class="NotAnswered" v-show="!v.stuAnswer">未答</div>
                    </div>
                            <div class="options" v-for="(val,inde) in v.optionList" :key="inde">
                                <div class="Selected">{{val}}</div>
                            </div>
                            <div class="absolute"><span style="margin-right:20px">考生答案：<span :style="{'color':v.isRight==0?'#1AC184':'#F66D6E'}">{{v.stuAnswer}}</span></span><span>正确答案：<span style="color:#1AC184">{{v.answer}}</span></span></div>
                        </div>
                    </div>
                        </div>
                        <div  v-show="index == 3">
                            <div class="title" >判断题（共{{judge}}题,合计{{judgeScore}}分）</div>
                            <div class="gutter-box1 ">
                                <div style="position: relative;" v-for="(v,i) in Judgment" :key="i">
                                <div style="display:flex">  {{v.num}}.{{v.content}}   
                                     <div class="right" v-show="v.isRight == 0">正确</div>
                                     <div class="err" v-show="v.isRight == 1">错误</div>
                                     <div class="NotAnswered" v-show="!v.stuAnswer">未答</div>
                                    </div>

                            <div class="options" v-for="(val,inde) in v.optionList" :key="inde">
                                <div class="Selected">{{val}}</div>
                            </div>
                            <div class="absolute"><span style="margin-right:20px">考生答案：<span :style="{'color':v.isRight==0?'#1AC184':'#F66D6E'}">{{v.stuAnswer}}</span></span><span>正确答案：<span style="color:#1AC184">{{v.answer}}</span></span></div>
                        </div>
                    </div>
                        </div>
                </div>
                <div class="page" style="text-align:center;margin-bottom: 30px;">
                        <div :class="{'Previous':true,'green':flag == 1}" @click="index<=1?index==1:index--,flag=1">上一页</div>
                    <div :class="{'Previous':true,'green':flag == 2}" @click="index>=3?index==3:index++,flag=2">下一页</div>
                    </div>
                </div>
                </a-col>
                <a-col class="gutter-row" :span="21" v-show="type == 2">
                <div class="boxL" >
                    <div class="exam" style="display:flex;flex-wrap: wrap;">
                          <div class="rules" >
                            <div class="rulel" v-for="(v,index) in PaperLists" :key="index">
                            <p style="font-size:16px">{{v.name}}</p>
                            <p style="font-size:13px;margin-top: 20px; ">开始时间：{{v.startTime}}</p>
                            <p class="font">结束时间：{{v.endTime}}</p>
                            <p class="font">考试时长：{{v.duration}}</p>
                            <p class="font">得&#x3000;分&#x3000;：{{v.score}}分</p>
                            <div class="start">查看详情</div>
                            <div class="bgimg" v-show="v.score>=60">
                            <img src="../../assets/img/Rectangle 3048(1).png" alt="">
                            <p class="p">已通过</p>  
                            </div>
                            <div class="bgimg" v-show="v.score<60">
                            <img src="../../assets/img/Rectangle 3048(2).png" alt="">
                            <p class="p">未通过</p>  
                            </div>
                        </div>
                          </div>
                        </div>
                    </div>
                </a-col>  
            </a-row>
        </div>
    </div>
</template>
<script>
    export default {
        name: "index",
        data(){
            return{
            type:1,
            index:1,
            flag:2,
            paperId:this.$route.query.paperId,
            testId:this.$route.query.testId,
            Score:"",
            Radio:[],        //单选
            Selection:[],    //多选
            Judgment:[],     //判断

            single: 0,      //单选题数量
            multiple: 0,    //多选题数量
            judge: 0,       //判断题数量

            singleScore: 0, //单选题分数
            multipleScore: 0, //多选题分数
            judgeScore: 0,   //判断题分数
            

            RadioScore:0,//单选题总分
            SelectionScore:0,//多选题总分
            JudgmentScore:0,//判断题总分

            duration:0,  //耗时
            PaperLists:[],
            HistoryPaper:[],
          

            }
        },
        mounted(){
           /*  this.paperId=this.$route.query.paperId,
            this.testId=this.$route.query.testId, */
            console.log(this.paperId,this.testId);
           this.showHistory()
        },
        methods:{
            check(data){
                this.type = data;
                this.$router.push({name:'examination',query:{
                    type:data
                }})
            },
          
            showHistory(){
                this.$http({
                    url: this.$http.adornUrl('/testPaper/showHistory'),
                    method: 'get',
                    params: this.$http.adornParams({
                    testId:this.testId,
                    paperId: this.paperId,
                    }),
                }).then((res) => {

                   res.data.data.content.forEach((v,i)=>{
                        if(v.examType == 0){
                            this.Radio.push(v)
                        }else if(v.examType == 1){
                            this.Selection.push(v)
                        }else if(v.examType == 2){
                            this.Judgment.push(v)
                        }
                    })
            
                   this.single = res.data.data.single        //单选题数量
                    this.multiple = res.data.data.multiple //多选题数量
                    this.judge = res.data.data.judge     //判断题数量

                    this.singleScore = res.data.data.singleScore  //单选题分数
                    this.multipleScore = res.data.data.multipleScore  //多选题分数
                    this.judgeScore = res.data.data.judgeScore  //判断题分数

                   //总分数
                    this.Score = res.data.data.score
                   this.duration = res.data.data.duration
                });
            },
            history1(data){
                this.type = data;
                this.$router.push({name:'examination',query:{
                    type:data
                }})
            }
        }   
    }
</script>

<style scoped>
    .association{
        background: #ffffff;
    }
    .explainL{
        height: 50px;
        line-height: 50px;
        width: 1080px;
        margin: 0 auto;
        text-align: left;
    }
    .routeL{
        cursor: pointer;
    }
    .brief{
        width: 1080px;
        margin: 20px auto 76px;
    }
    .gutter-box{
        background: #ffffff;
        /* border: 1px solid #BDBDBD; */
        padding: 10px;
    }
    .gutter-box1{
        overflow: scroll;
        height: 847px;
        background: #ffffff;
        padding: 10px;
        white-space: pre-line
    }
    .gutter-box1::-webkit-scrollbar {
          display: none;
        }
    .boxL{
        padding: 15px;
    }
    .ul{
        width: 150px;
        height: 185px;
        margin: 10px auto 40px;
        display: flex;
        justify-content:flex-start;
        flex-wrap: wrap;
        overflow: scroll;

    } 
    .ul>li{
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        margin-top: 15px;
        margin-right: 5px;
        border: 1px solid #BDBDBD;
        border-radius: 50%;

    }
    .title{
        height: 40px;
        padding-left: 12px;
        line-height: 40px;
        background: #F9FDFF;
        border-bottom: 1px solid #BDBDBD;
    }
    .options{
        margin: 20px 0;
    }
    .right{
        width: 70px;
        height: 25px;
        background: #1AC184;
        /* margin-left: 50px; */
        position: absolute;
        right: 20px;
        border-radius: 1px;
        text-align: center;
        line-height: 25px;
        color: #ffffff;
    }
    .err{
        width: 70px;
        height: 25px;
        background: #F66D6E;
        /* margin-left: 50px; */
        position: absolute;
        right: 20px;
        border-radius: 1px;
        text-align: center;
        line-height: 25px;
        color: #ffffff;
    }
    .NotAnswered{
        width: 70px;
        height: 25px;
        background: #ddd2d2;
        position: absolute;
        right: 20px;
        border-radius: 1px;
        text-align: center;
        line-height: 25px;
        color: rgb(68 62 62);
    }
    .col{
        background: #1AC184;
        color: white;
    }
    .cols{
        background: #F66D6E;
        color: white;
    }
    .foot{
        height: 30px;
        display: flex;
        justify-content: space-around;
    }
    .circle{
        width: 15px;
        height: 15px;
        border: 1px solid #BDBDBD;
        border-radius: 50%;
    }
  
    .active{
        font-weight: 400;
        color: #0EC2DB;
    }
    .uls{
        margin-top: 10px;
        text-align: center;
       
    }
    .uls>li{
        height: 44px;
        line-height: 44px;
        cursor: pointer;
    }
    .titles{
        height:40px;
        line-height: 40px;
        text-align: center;
        background: #F4F1F4;
    
    }
    .start1{
        width: 100px;
        height: 30px;
        margin: 268px auto;
        cursor: pointer;
        border-radius: 50px;
        line-height: 30px;
        color: white;
        background: #10C1DC;
        text-align:center;
   
    }
    .exam{
        display: flex;
        justify-content: space-between;
    }
    .rules{
        display: flex;
        cursor: pointer;
    }
    .rulel{
        width:280px;
        height: 250px;
        padding: 20px;
        background:#fff;
        cursor: pointer;
        position: relative;
    }
    .font{
        font-size:13px;
        margin-top: 10px
    }
    .start{
        width: 100px;
        height: 30px;
        margin: 0 auto;
        cursor: pointer;
        border-radius: 50px;
        line-height: 30px;
        color: white;
        background: #10C1DC;
        text-align:center;
        margin-top:20px;
    }
    .bgimg{
        width: 59px;
        height: 56px;
        color: white;
        position: absolute;
        top: 0;
        right:0
    }
    .p{
        height: 20px;
        position: absolute;
        top: 12px;
        left: 9px;
    }
    .page{
        width: 200px;
        height: 30px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        /* border: 1px solid #000; */
    }
    .Previous{
        width: 80px;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        /* color: white; */
        border-radius: 3px;
        border: 1px solid #000;
    }
    .green{
        background: #0EC2DC;
        color: white;
        border: none;
    }
    .Selected{
    width: 150px;
    padding: 0 10px;
   }
   .absolute{
    /* width: 200px; */
    position: absolute;
    bottom: 0px;
    left: 40%;
   }
   .back{
    width: 300px;
    margin-left: 150px;
    cursor: pointer;
   }
   .colo{
    background: white;
    color: black;
   }

   ::-webkit-scrollbar {
    display: none;
}
</style>