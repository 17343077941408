<template>
  <div>
    <div class="association">
      <div class="explainL">
        <span>当前位置：</span>
        <span class="routeL">首页></span>
        <span>个人设置></span>
      </div>
    </div>
    <div class="brief">
      <a-row :gutter="20">
        <a-col class="gutter-row" :span="4">
          <div class="gutter-box">
            <ul class="uls">
              <li :class="[this.type == 1 ? 'active' : '']" @click="check('1')">
                <img
                  class="imgXX"
                  :src="[this.type == 1 ? hytl2 : hytl]"
                  alt=""
                />个人资料
              </li>
              <li :class="[this.type == 2 ? 'active' : '']" @click="check('2')">
                <img
                  class="imgXX"
                  :src="[this.type == 2 ? grhy2 : grhy]"
                  alt=""
                />修改密码
              </li>
              <!-- <li :class="[this.type == 3 ? 'active' : '']" @click="check('3')">
                <img
                  class="imgXX"
                  :src="[this.type == 3 ? dwhy2 : dwhy]"
                  alt=""
                />消息设置
              </li> -->
            </ul>
          </div>
        </a-col>
        <a-col class="gutter-row" :span="20">
          <div class="gutter-box boxL" v-show="type == 1">
            <div class="title">
              <h3>个人资料</h3>
            </div>
            <div class="mainPart">
              <div class="partOne">昵称</div>
              <div class="partOne2">
                <el-input
                  v-model="nickName"
                  placeholder="请输入昵称"
                ></el-input>
              </div>
              <div class="partOne">手机号</div>
              <div class="partOne2">
                <el-input v-model="phone" placeholder="请输入手机号"></el-input>
              </div>
              <div class="partOne">性别</div>
              <div class="partOne2">
                <el-select v-model="gender" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="partOne">邮箱</div>
              <div class="partOne2">
                <el-input v-model="email" placeholder="请输入邮箱号"></el-input>
              </div>
              <div class="button1" @click="changeInformAtion()">保存</div>
            </div>
          </div>
          <div class="gutter-box boxL" v-show="type == 2">
            <div class="title">
              <h3>修改密码</h3>
            </div>
            <div class="mainPart">
              <div class="partOne">当前密码</div>
              <div class="partOne2">
                <el-input
                  v-model="oldPass"
                  placeholder="请输入密码"
                  show-password
                ></el-input>
              </div>
              <div class="partOne">新密码</div>
              <div class="partOne2">
                <el-input
                  v-model="newPass"
                  placeholder="请输入密码"
                  show-password
                ></el-input>
              </div>
              <div class="partOne">确定新密码</div>
              <div class="partOne2">
                <el-input
                  v-model="newPassCopy"
                  placeholder="请输入密码"
                  show-password
                ></el-input>
              </div>
              <div class="button1" @click="changePassWord()">保存</div>
            </div>
          </div>
          <!-- <div class="gutter-box boxL" v-show="type == 3">
            <div class="title">
              <h3>消息设置</h3>
            </div>
          </div> -->
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      options: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],
      email: "",
      gender: "",
      nickName: "",
      phone: "",
      oldPass: "",
      newPass: "",
      newPassCopy: "",
      type: 1,
      userid: this.$cookie.get("useridList"),
      userName: this.$cookie.get("username"),
      hytl: require("../../assets/img/hytl.png"),
      hytl2: require("../../assets/img/hytl2.png"),
      grhy: require("../../assets/img/edit.png"),
      grhy2: require("../../assets/img/edit2.png"),
      dwhy: require("../../assets/img/message.png"),
      dwhy2: require("../../assets/img/message2.png"),
    };
  },
  methods: {
    check(data) {
      this.type = data;
    },
    changeInformAtion() {
      if (this.email == "" || this.nickName == "" || this.gender == "") {
        this.$message.error("请输入信息");
      } else {
        this.$http({
          url: this.$http.adornUrl("/api/users/center"),
          method: "put",
          data: this.$http.adornData({
            id:this.userid,
            username:this.username,
            email: this.email,
            nickName: this.nickName,
            gender: this.gender,
            phone: this.phone,
          }),
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    changePassWord() {
      if (this.oldPass == "" || this.newPass == "" || this.newPassCopy == "") {
        this.$message.error("请输入密码");
      } else {
        this.$http({
          url: this.$http.adornUrl("/api/users/updatePass"),
          method: "post",
          data: this.$http.adornData({
            newPass: this.newPass,
            oldPass: this.oldPass,
          }),
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message.sucess("修改成功");
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.button1 {
  width: 76px;
  height: 42px;
  background: #4acfe1;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 42px;
  text-align: center;
  margin-top: 50px;
  margin-left: 20%;
  cursor: pointer;
}
.association {
  background: #ffffff;
}
.explainL {
  height: 50px;
  line-height: 50px;
  width: 1080px;
  margin: 0 auto;
  text-align: left;
}
.routeL {
  cursor: pointer;
}
.brief {
  width: 1080px;
  margin: 20px auto 76px;
}
.gutter-box {
  background: #ffffff;
}
.gutter-box > ul > li {
  height: 44px;
  line-height: 44px;
  cursor: pointer;
}
.active {
  font-weight: 400;
  color: #0ec2db;
}
.boxL {
  padding: 15px;
}
.mainPart {
  width: 80%;
  margin-left: 8%;
  height: 480px;
  margin-top: 30px;
}
.partOne {
  width: 60%;
  height: 30px;

  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
  margin-top: 18px;
}
.partOne2 {
  width: 60%;
  margin-top: 10px;
  height: 40px;

  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.title {
  overflow: hidden;
  border-bottom: 1px solid #e5e5e5;
}
.title > h3 {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: #0ec2db;
  float: left;
  height: 40px;
  display: inline-block;
  border-bottom: 2px solid #0ec2db;
  margin-bottom: 0px;
}
article > p {
  line-height: 24px;
  text-align: left;
  text-indent: 30px;
}
.uls {
  text-align: center;
}
.xh {
  font-size: 18px;
  font-weight: 400;
  color: #0e60db;
}
.jj {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.jjs {
  border-bottom: 1px solid #e5e5e5;
}
.jj h3 {
  text-indent: 24px;
}
.jj p {
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  margin-bottom: 5px;
  text-indent: 24px;
}
.imgR {
  width: 135px;
  height: 178px;
  margin-right: 10px;
}
.imgXX {
  margin-top: -5px;
  margin-right: 5px;
}
</style>