<template>
  <div>
    <div class="association">
      <div class="explainL">
        <span>当前位置：</span>
        <span class="routeL">首页></span>
        <span>消息></span>
      </div>
    </div>
    <div class="brief">
      <a-row :gutter="20">
        <a-col class="gutter-row" :span="4">
          <div class="gutter-box">
            <ul class="uls">
              <li :class="[type == 1 ? 'active' : '']" @click="check('1')">
                <img
                  class="imgXX"
                  :src="[type == 1 ? hytl2 : hytl]"
                  alt=""
                />官方
              </li>
              <li :class="[type == 2 ? 'active' : '']" @click="check('2')">
                <img
                  class="imgXX"
                  :src="[type == 2 ? grhy2 : grhy]"
                  alt=""
                />通知
              </li>
            </ul>
          </div>
        </a-col>
        <a-col class="gutter-row" :span="20">
          <div class="gutter-box boxL" v-show="type == 1">
            <div class="title">
              <h3 :class="[typeT == 1 ? 'active1' : '']" @click="goT('1')">
                全部
              </h3>
              <h3
                class="nextH"
                :class="[typeT == 2 ? 'active1' : '']"
                @click="goT('2')"
              >
                公告
              </h3>
              <h3
                class="nextH"
                :class="[typeT == 3 ? 'active1' : '']"
                @click="goT('3')"
              >
                审核
              </h3>
            </div>
            <div class="mainPart" v-show="typeT == 1">
              <div
                class="newsOne"
                v-for="(item, index) in officalMessage"
                :key="index"
              >
              <div class="wordOne">
                 <span class="answer1" v-if="item.type == 5">[审核] </span>
                <span class="answer1" v-if="item.type == 6">[公告] </span>
                <span class="color66">
                  {{ item.bulletinContent }}
                </span>
              </div>
               
                 <div class="timeWord">{{ item.createTime }}</div>
              </div>
             
            </div>
            <div class="mainPart" v-show="typeT == 2">
              <div
                class="newsOne"
                v-for="(item, index) in message1"
                :key="index"
              >
                 <div class="wordOne">
                <span class="answer1" v-if="item.type == 6">[公告] </span>
                <span class="color66">
                  {{ item.bulletinContent }}
                </span>
              </div>
               
                 <div class="timeWord">{{ item.createTime }}</div>
              </div>
            </div>
            <div class="mainPart" v-show="typeT == 3">
              <div
                class="newsOne"
                v-for="(item, index) in message2"
                :key="index"
              >
                 <div class="wordOne">
                 <span class="answer1" v-if="item.type == 5">[审核] </span>
                <span class="color66">
                  {{ item.bulletinContent }}
                </span>
              </div>
               
                 <div class="timeWord">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
          <div class="gutter-box boxL" v-show="type == 2">
            <div class="title">
              <div class="title">
                <h3
                  :class="[typeT1 == 1 ? 'active1' : '']"
                  @click="goT1('1')"
                >
                  全部
                </h3>
                <h3
                  class="nextH"
                  :class="[typeT1 == 2 ? 'active1' : '']"
                  @click="goT1('2')"
                >
                  回答
                </h3>
                <h3
                  class="nextH"
                  :class="[typeT1 == 3 ? 'active1' : '']"
                  @click="goT1('3')"
                >
                  赞
                </h3>
              </div>
            </div>
            <div class="mainPart" v-show="typeT1 == 1">
              <div
                class="newsOne"
                v-for="(item, index) in normalMessage"
                :key="index"
              >
                <div class="imagePart">
                  <img
                    :src="baseUrl + item.fromAvatar"
                    alt=""
                    srcset=""
                    class="circleImg"
                     v-if="item.fromAvatar"
                  />
                  <img
                      src="../../assets/img/avatar.png"
                      alt=""
                      srcset=""
                      class="circleImg"
                      v-else
                    />
                </div>
                <div class="wordGongs">
                  <div>{{ item.fromName }}</div>
                  <div class="wordGongs1">
                    <span class="answer1" v-if="item.type == 1">回答了</span>
                    <span class="answer1" v-if="item.type == 3">点赞了</span>
                    <span class="answer2"> {{ item.problemTitle }}</span>
                  </div>
                </div>

                <div class="dataWords">{{ item.createTime }}</div>
              </div>
            </div>
             <div class="mainPart" v-show="typeT1 == 2">
              <div
                class="newsOne"
                v-for="(item, index) in message3"
                :key="index"
              >
                <div class="imagePart">
                  <img
                    :src="baseUrl + item.fromAvatar"
                    alt=""
                    srcset=""
                    class="circleImg"
                  />
                </div>
                <div class="wordGongs">
                  <div>{{ item.fromName }}</div>
                  <div class="wordGongs1">
                    <span class="answer1" v-if="item.type == 1">回答了</span>
                    <span class="answer2"> {{ item.problemTitle }}</span>
                  </div>
                </div>

                <div class="dataWords">{{ item.createTime }}</div>
              </div>
            </div>
             <div class="mainPart" v-show="typeT1 == 3">
              <div
                class="newsOne"
                v-for="(item, index) in message4"
                :key="index"
              >
                <div class="imagePart">
                  <img
                    :src="baseUrl + item.fromAvatar"
                    alt=""
                    srcset=""
                    class="circleImg"
                  />
                </div>
                <div class="wordGongs">
                  <div>{{ item.fromName }}</div>
                  <div class="wordGongs1">
                    <span class="answer1" v-if="item.type == 3">点赞了</span>
                    <span class="answer2"> {{ item.problemTitle }}</span>
                  </div>
                </div>

                <div class="dataWords">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
          <div class="gutter-box boxL" v-show="type == 3">
            <div class="title">
              <h3>消息设置</h3>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
  import { getUrl } from "../../utils";

  export default {
  name: "index",
  data() {
    return {
      normalMessage: [],
      message1: [],
      message2: [],
      message3: [],
      message4: [],
      officalMessage: [],
      baseUrl: getUrl(),
      oldPass: "",
      newPass: "",
      newPassCopy: "",
      typeT: "1",
      typeT1: "1",
      type: 1,
      userid: this.$cookie.get("useridList"),
      hytl: require("../../assets/img/lingdang1.png"),
      hytl2: require("../../assets/img/lingdang.png"),
      grhy: require("../../assets/img/xiaoxi.png"),
      grhy2: require("../../assets/img/xiaoxi1.png"),
    };
  },
  created() {
    this.getMessage();
  },
  methods: {
    getMessage() {
      this.$http({
        url: this.$http.adornUrl("/message/getReadByUserId"),
        method: "get",
        params: this.$http.adornParams({
          userId: this.userid,
        }),
      }).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          for (var i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].type == 6) {
              this.officalMessage.push(res.data.data[i]);
              this.message1.push(res.data.data[i]);
            } else if (res.data.data[i].type == 5) {
              res.data.data[i].bulletinContent = res.data.data[i].problemTitle;
              this.officalMessage.push(res.data.data[i]);
              this.message2.push(res.data.data[i]);
            } else if (res.data.data[i].type == 1){
              this.normalMessage.push(res.data.data[i]);
               this.message3.push(res.data.data[i]);
            }else if (res.data.data[i].type == 3){
              this.normalMessage.push(res.data.data[i]);
               this.message4.push(res.data.data[i]);
            }
          }
        } else {
        }
      });
    },
    check(data) {
      this.type = data;
    },
    goT(data) {
      this.typeT = data;
    },
    goT1(data) {
      this.typeT1 = data;
    },
    changePassWord() {
      if (this.oldPass == "" || this.newPass == "" || this.newPassCopy == "") {
        this.$message.error("请输入密码");
      }
    },
  },
};
</script>

<style scoped>
.wordGongs {
  width: 500px;
  margin-left: 20px;
  float: left;

  line-height: 20px;
}
.answer2 {
  color: #333;
}
.dataWords {
  float: left;
  margin-left: 42px;
}
.answer1 {
  color: #666;
}
.wordGongs1 {
  width: 320px;
  margin-left: 0px;
  float: left;
white-space: nowrap;
  line-height: 25px;
  margin-top: 0px;
}
.circleImg {
  width: 36px;
  height: 36px;

  border-radius: 36px;
}
.imagePart {
  width: 40px;
  margin-left: 20px;
  float: left;
}
.button1 {
  width: 76px;
  height: 42px;
  background: #4acfe1;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 42px;
  text-align: center;
  margin-top: 50px;
  margin-left: 20%;
  cursor: pointer;
}
.association {
  background: #ffffff;
}
.explainL {
  height: 50px;
  line-height: 50px;
  width: 1080px;
  margin: 0 auto;
  text-align: left;
}
.routeL {
  cursor: pointer;
}
.brief {
  width: 1080px;
  margin: 20px auto 76px;
}
.gutter-box {
  background: #ffffff;
}
.gutter-box > ul > li {
  height: 44px;
  line-height: 44px;
  cursor: pointer;
}
.active {
  font-weight: 400;
  color: #0ec2db;
}
.boxL {
  padding: 15px;
}

.color66 {
  color: #666;
}
.mainPart {
  width: 98%;
  margin-left: 2%;
  height: 420px;
  margin-top: 20px;
  overflow: auto;
}
.newsOne {
  width: 100%;
  height: 60px;
  line-height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
 float: left;
}
.wordOne{
width: 80%;
float: left;
}
.timeWord{
 width: 20%;
 float: left;
}
.partOne {
  width: 60%;
  height: 30px;

  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
  margin-top: 18px;
}
.partOne2 {
  width: 60%;
  margin-top: 10px;
  height: 40px;

  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.nextH {
  margin-left: 50px;
  cursor: pointer;
}
.title {
  overflow: hidden;
  border-bottom: 1px solid #e5e5e5;
}
.title > h3 {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  float: left;
  height: 40px;
  display: inline-block;

  margin-bottom: 0px;
}
.active1 {
  border-bottom: 2px solid #0ec2db;
  color: #0ec2db;
}
article > p {
  line-height: 24px;
  text-align: left;
  text-indent: 30px;
}
.uls {
  text-align: center;
}
.xh {
  font-size: 18px;
  font-weight: 400;
  color: #0e60db;
}
.jj {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.jjs {
  border-bottom: 1px solid #e5e5e5;
}
.jj h3 {
  text-indent: 24px;
}
.jj p {
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  margin-bottom: 5px;
  text-indent: 24px;
}
.imgR {
  width: 135px;
  height: 178px;
  margin-right: 10px;
}
.imgXX {
  margin-top: -5px;
  margin-right: 5px;
}
</style>