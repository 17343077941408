<template>
    <div>
        <div class="ban">
            <div class="association">
                <div class="explainL">
                    <span>当前位置：</span>
                    <span class="routeL">首页></span>
                    <span>团体方案</span>
                </div>
            </div>
            <div class="lc">团队服务整体解决方案流程</div>
            <div class="dx"></div>
            <div class="lcc">12年心理应用行业经验，打造一站式心理服务体系</div>
            <!--<div class="btns">立即咨询</div>-->
        </div>
        <div class="jjx">
            <img class="imgTT" src="../../assets/img/ttcs.png" alt="">
            <div>
                <div class="text">团体测试</div>
                <div class="text2">线下预约团体服务</div>
            </div>
            <img class="imgJT" src="../../assets/img/jt.png" alt="">
            <img class="imgTT" src="../../assets/img/gazx.png" alt="">
            <div>
                <div class="text">个案咨询</div>
                <div class="text2">筛选个案测评咨询</div>
            </div>
            <img class="imgJT" src="../../assets/img/jt.png" alt="">
            <img class="imgTT" src="../../assets/img/ttfd.png" alt="">
            <div>
                <div class="text">团体辅导</div>
                <div class="text2">进行团体咨询辅导</div>
            </div>
            <img class="imgJT" src="../../assets/img/jt.png" alt="">
            <img class="imgTT" src="../../assets/img/xljt.png" alt="">
            <div>
                <div class="text">心理讲堂</div>
                <div class="text2">讲堂互动解决问题</div>
            </div>
            <img class="imgJT" src="../../assets/img/jt.png" alt="">
            <img class="imgTT" src="../../assets/img/dzfa.png" alt="">
            <div>
                <div class="text">定制方案</div>
                <div class="text2">针对异常定制方案</div>
            </div>
        </div>
        <div class="title">
            专业行业解决方案
        </div>
        <div class="fa">
            <div>
                <img src="../../assets/img/jyhy.png" alt="">
                <div class="textTitle">教育行业方案</div>
            </div>
            <div>
                <img src="../../assets/img/sqqy.png" alt="">
                <div class="textTitle">社区企业方案</div>
            </div>
            <div>
                <img src="../../assets/img/xlzx.png" alt="">
                <div class="textTitle">心理咨询室方案</div>
            </div>
        </div>
        <div class="fa" style="margin-top: -30px">
            <div>
                <img src="../../assets/img/gyhy.png" alt="">
                <div class="textTitle">公益行业方案</div>
            </div>
            <div>
                <img src="../../assets/img/rszp.png" alt="">
                <div class="textTitle">人事招聘方案</div>
            </div>
            <div>
                <img src="../../assets/img/jgdw.png" alt="">
                <div class="textTitle">机关单位方案</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style scoped>
.association{
    background: none;
}
    .ban{
        height: 355px;
        background: linear-gradient(158deg, #1E7DEC 0%, #39BFDD 100%);
    }
    .explainL{
        height: 50px;
        line-height: 50px;
        width: 1080px;
        margin: 0 auto;
        text-align: left;
        color: #ffffff;
    }
    .routeL{
        cursor: pointer;
    }
    .lc{
        font-size: 36px;
        text-align: center;
        font-weight: 400;
        color: #FFFFFF;
    }
    .dx{
        width: 60px;
        margin: 15px auto;
        border: 2px solid #FFFFFF;
    }
    .lcc{
        font-size: 16px;
        text-align: center;
        font-weight: 400;
        color: #FFFFFF;
        margin: 15px 0;
    }
    .btns{
        width: 98px;
        height: 36px;
        background: #4ACFE1;
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        line-height: 36px;
        border: 1px solid #4ACFE1;
        color: #ffffff;
        margin: 0 auto;
        text-align: center;
    }
    .jjx{
        width: 1262px;
        height: 160px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.05);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: -80px auto 0;
        display: flex;
        justify-content: center;
        padding-top: 60px;
    }
    .imgTT{
        width: 44px;
        height: 44px;
        margin: 0 10px;
    }
    .imgJT{
        width: 27px;
        height: 45px;
        margin: 0 10px 0 20px;
    }
    .text{
        font-size: 18px;
        font-weight: 400;
        color: #333333;
    }
    .text2{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
    }
    .title{
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        margin: 50px 0;
        text-align: center;
    }
    .fa{
        display: flex;
        justify-content: center;
        margin-bottom: 4px;
    }
    .fa>div{
        margin: 15px;
    }
    .fa>div>img{
        width: 387px;
        height: 254px;
    }
    .textTitle{
        text-align: center;
        color: #ffffff;
        font-size: 24px;
        font-weight: 400;
        position: relative;
        top: -135px;
    }
</style>