<template>
    <div>
     <div class="association">
             <div class="explainL">
                 <span>当前位置：</span>
                 <span class="routeL">首页 > </span>
                 <span>学术年会 > </span>
                 <span>活动通知 ></span>
             </div>
         </div>
         <div class="cont">
             <a-row :gutter="20">
                 <a-col class="gutter-row" :span="24">
                     <div class="title">安徽省心理卫生协会2021-2022年度先进集体评选活动
                         <p>2023-02-01</p>
                     </div>
                    <div class="fenxiang">
                     <div style="float:right">
                         <span>分享至： &nbsp;</span>
                         <img src="../../assets/img/wbfx.png" alt=""> 
                         <span> &nbsp;微博 &nbsp;&nbsp;&nbsp; </span>
                         <img src="../../assets/img/wxfx.png" alt="">
                         <span> &nbsp;微信</span>
                     </div>
                    </div>
                 </a-col>
                 <a-col>
                     &nbsp;
                     <div style="border-bottom:1px solid #F0EDF0"></div>
                 </a-col>
                 <div class="boxL">
                     <article>
                     <p style="margin-top: 15px;">
                        本次年会将对2021-2022年度在我省心理卫生专业领域，取得突出成绩、作出较大贡献的个人和集体进行表彰。并上报中国心理卫生协会及有关管理部门。</p>
                     <p>参加先进评选申报的个人务必在2022年11月1日之前将论文、申报表及相关材料电子版发送至协会秘书处邮箱ahxh2018@126.com，标题格式为: 2022 年会评优材料+个人名称。</p>  
                 </article>
                 </div>
             </a-row>
            <!-- <div class="application" @click="dialogVisible = true">立即申请</div> -->
            <div>
                <el-dialog
  :visible.sync="dialogVisible"
  :close-on-click-modal="false"
  width="700px"
  :show-close="false"
  center
  >
  <div style="width:100%; text-align: center;font-weight: 600;margin-bottom: 20px;">安徽省心理卫生协会2020-2021年度先进集体申报表</div>
 <table border="1" style="width:100%" cellspacing="0" cellpadding="0" >
   <tr>
    <td colspan="2" class="bg" >单位名称</td>
    <td colspan="2"><input type="text" v-model="inp"></td>
    <td colspan="2" class="bg">成立时间</td>
    <td colspan="2"><input type="text"></td>
   </tr>

   <tr>
    <td colspan="2" class="bg">单位地址</td>
    <td colspan="2"><input type="text"></td>
    <td colspan="2" class="bg">单位联系方式</td>
    <td colspan="2"><input type="text"></td>
   </tr>
 
   <tr>
   <td colspan="2" class="bg">负责人姓名</td>
    <td colspan="2"><input type="text"></td>
    <td colspan="2" class="bg">负责人联系方式</td>
    <td colspan="2"><input type="text"></td>
    </tr>
    <tr>
    <td colspan="2" class="bg">申报类别</td>
    <td colspan="6">  <el-checkbox v-model="checked.checked1">优秀心理服务机构</el-checkbox>  <el-checkbox v-model="checked.checked2">优秀专委会</el-checkbox>  <el-checkbox v-model="checked.checked3">优秀地市协会</el-checkbox></td>
   </tr>
    
    <tr>
        <td colspan="2" rowspan="14" class="bg">
        <div style="width:80px;margin: auto;" >
            突出成绩和先进事迹
        </div>
        </td>
        <td rowspan="14" colspan="6"><input type="text" style="height:100%"></td>
    </tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
 </table>
 <div style="margin-top:10px">备注:突出成绩和先进事迹请附相关佐证材料照片电子版。<span style="color:#5A95D7;cursor: pointer;">  上传附件</span>
</div>
  <span slot="footer" class="dialog-footer">
    
    <el-button type="primary" @click="DialogVisible()">立即提交</el-button>
  </span>
</el-dialog>
            </div>
            <div>
                <el-dialog
                :visible.sync="dialogVisible1"
                :close-on-click-modal="false"
                width="22%"
                center
                >
                <div style="text-align:center">
                    <img  src="../../assets/img/ddsh.png" alt="">
                    <p style="color:#83899F;font-size:18px;margin-top:20px">已提交申请,等待审核中</p>
                    <p style="margin-top:20px">预计审核时间: 3个工作日</p>
                    <p style="margin-top:10px">审核结果会以公告的形式通知，请您耐心等待</p>
                </div>  
                <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="DialogVisible1()">我知道了</el-button>   
                </span>
                </el-dialog>
            </div>
         </div>
    </div>
  </template>
  
  <script>
      export default {
        data() {
      return {
        dialogVisible: false,
        dialogVisible1:false,
        inp:"",
        checked:{
            checked1:false,
            checked2:false,
            checked3:false,
        }
      };
    },
    methods:{
        DialogVisible(){
            this.dialogVisible = false
            this.dialogVisible1 = true
            console.log(this.inp,this.checked);
        },
        DialogVisible1(){
            this.dialogVisible1 = false
            this.$router.push({ name: "academic" });
        }
    }
  };
  </script>
  
  <style scoped>
  *{
     padding: 0;
     margin: 0;
  }
  .association{
         background: #ffffff;
     }
     .explainL{
         height: 50px;
         line-height: 50px;
         width: 1080px;
         margin: 0 auto;
         text-align: left;
     }
     .routeL{
         cursor: pointer;
     }
    .cont{
         width: 1080px;
         margin: 20px auto;
         background: #ffffff;
         padding:50px 20px;
     }
     .title{
         width: 100%;
         font-size: 16px;
         font-weight: 600;
         text-align: center;
     }
     .title>p{
         margin-top: 20px;
         font-size: 12px;
         font-weight: 600;
         color: #7A777B;
     }
     .boxL{
         padding: 0px 40px;
       
     }
     article>p{
         line-height: 24px;
         text-align: left;
         text-indent:30px
         
     }
     .application{
        width: 200px;
        height: 40px;
        background: #FDC023;
        margin: 150px  auto 20px;
        text-align: center;
        line-height: 40px;
        color: white;
        cursor: pointer;
     }
     table {
        text-align: center;
     }
     table tr{
        width: 100%;
        height: 30px;
     }
    table tr td{
        width: 12.5%;
        height: 30px;
    }
    table input{
        width: 100%;
        border: none;
        text-align: center;
    }
    .bg{
        background: #F4F6F8;
    }
   
  </style>
  