<template>
    <div>
     <div class="association">
             <div class="explainL">
                 <span>当前位置：</span>
                 <span class="routeL">首页 > </span>
                 <span>学术年会 > </span>
                 <span>活动通知 ></span>
             </div>
         </div>
         <div class="cont">
             <a-row :gutter="20">
                 <a-col class="gutter-row" :span="24">
                     <div class="title">(中国)华东心理卫生联盟学术交流会暨2021年安徽省心理卫生协会学术年会参会申请
                         <p>2021-11-05</p>
                     </div>
                    <div class="fenxiang">
                     <div style="float:right">
                         <span>分享至： &nbsp;</span>
                         <img src="../../assets/img/wbfx.png" alt=""> 
                         <span> &nbsp;微博 &nbsp;&nbsp;&nbsp; </span>
                         <img src="../../assets/img/wxfx.png" alt="">
                         <span> &nbsp;微信</span>
                     </div>
                    </div>
                 </a-col>
                 <a-col>
                     &nbsp;
                     <div style="border-bottom:1px solid #F0EDF0"></div>
                 </a-col>
                 <div class="boxL">
                     <article>
                     <p style="margin-top: 15px;">为提升心理卫生工作者的业务水平,促进我省心理卫生事业的健康发展，贯彻落实党和政府心理健康工作部署和文件精神,为建设"五大发展”美好安徽作出贡献，贯彻落实国家22部委《关于加强心理健康服务的指导意见》和国家10部委《全国社会心理服务体系建设试点工作方案》精神,安徽省心理卫生协会将召开(中国)华东心理卫生联盟学术交流会暨2021年安徽省心理卫生协会学术年会。本次年会将对2020-2021年度在我省心理卫生专业领域，取得突出成绩、作出较大贡献的个人和集体进行表彰。并上报中国心理卫生协会及有关管理部门。</p>
                     <p>一、参会人员:</p>  
                     <p>1.安徽省心理卫生协会会员、专委会及地市分会成员、华东心理卫生联盟成员等;</p>
                     <p>2.心理治疗师、心理咨询师、社工人员、大中小学校心理学教师及心理健康老师、精神科医护工作者、心理科医生、司法公安机关工作人员、共青团、妇联工作人员及社会各界心理卫生相关工作从业者等;</p>
                    <p>3.有志于加入安徽省心理卫生协会的专业人员;</p>
                    <p>4.全日制在校心理相关专业学生。</p>
                    <p>二、参会回执</p>
                    <p>参会代表请“立即申请”填写《参会回执表》，回执收取截止日为10月20日。</p>
                    <p>三、参会咨询方式</p>
                    <p>联系人: 13956015839崔老师、解老师</p>
                    <p>&#x3000;&#x3000;&#x3000;&nbsp;&nbsp;13637069060黄老师</p>
                    <p>&#x3000;&#x3000;&#x3000;&nbsp;&nbsp;15956705822邱老师</p>
                    <p>会务组邮箱: ahxh2018@126.com</p>
                 </article>
                 </div>
             </a-row>
            <div class="application"  @click="dialogVisible = true">立即申请</div>
            <div>
                <el-dialog
  :visible.sync="dialogVisible"
  :close-on-click-modal="false"
  width="700px"
  :show-close="false"
  center
  >
  <div style="width:69%; text-align: center;font-weight: 600;margin-bottom: 20px;margin:0 auto  10px">(中国)华东心理卫生联盟学术交流会暨2021年安徽省心理卫生协会
学术年会参会回执表</div>
 <table border="1" style="width:100%;table-layout:fixed" cellspacing="0" cellpadding="0" >
    <tr>
    <td colspan="2" class="bg">单位名称</td>
    <td colspan="10" ><input type="text"></td>
   </tr>
   <tr> 
        <td colspan="2" class="bg">通讯地址</td>
        <td colspan="4"><input type="text"></td>
        <td colspan="2" class="bg">邮箱</td>
        <td colspan="4"><input type="text"></td>
   </tr>
   
   <tr>
    <td colspan="2" class="bg">电子邮箱</td>
    <td colspan="10" ><input type="text"></td>
   
   </tr>
   <tr>
    <td colspan="2" class="bg">姓名</td>
    <td colspan="2" class="bg">性别</td>
    <td class="bg" colspan="4">职务/职称</td>
    <td class="bg" colspan="4">手机号码</td>
   
   </tr>
   <tr>
    <td colspan="2"><input type="text"></td>
    <td colspan="2"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text"></td>
    <td colspan="2"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text"></td>
    <td colspan="2"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text"></td>
    <td colspan="2"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text"></td>
    <td colspan="2"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text"></td>
    <td colspan="2"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text"></td>
    <td colspan="2"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text"></td>
    <td colspan="2"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text"></td>
    <td colspan="2"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    </tr>
    <tr>
    <td colspan="2"><input type="text"></td>
    <td colspan="2"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    <td colspan="4"><input type="text"></td>
    </tr>
    <tr>
    <td colspan="3" class="bg">住宿安排</td>
    <td colspan="9">  <el-checkbox v-model="checked.checked1"> 1、自行安排</el-checkbox><el-checkbox v-model="checked.checked3">2、会务组统一安排，需要 <input style="border-bottom:1px solid #000;width: 30px;" type="text">个标间</el-checkbox></td>
   </tr>
   <tr>
    <td colspan="3" class="bg">抵会时间</td>
    <td colspan="3"><input style="border-bottom:1px solid #000;width: 30px;" type="text">月<input style="border-bottom:1px solid #000;width: 30px;" type="text">日</td>
    <td colspan="3" class="bg">离会时间</td>
    <td colspan="3"><input style="border-bottom:1px solid #000;width: 30px;" type="text">月<input style="border-bottom:1px solid #000;width: 30px;" type="text">日</td>
   </tr>
 </table>
 <div style="margin-top:10px">请务必在回执中填写参会代表的手机号码，以便及时收到大会信息变更通知短信。
</div>
  <span slot="footer" class="dialog-footer">
 
    <el-button type="primary" @click="DialogVisible()">立即提交</el-button>
  </span>
            </el-dialog>
            </div>
         </div>
    </div>
  </template>
  
  <script>
      export default {
        data() {
      return {
        dialogVisible: false,
        inp:"",
        checked:{
            checked1:false,
            checked2:false,
            checked3:false,
        }
      };
    },
    methods:{
        DialogVisible(){
            this.dialogVisible = false
            console.log(this.inp,this.checked);
            
        }
    }
  };
  </script>
  
  <style scoped>
  *{
     padding: 0;
     margin: 0;
  }
  .association{
         background: #ffffff;
     }
     .explainL{
         height: 50px;
         line-height: 50px;
         width: 1080px;
         margin: 0 auto;
         text-align: left;
     }
     .routeL{
         cursor: pointer;
     }
    .cont{
         width: 1080px;
         margin: 20px auto;
         background: #ffffff;
         padding:50px 20px;
     }
     .title{
         width: 100%;
         font-size: 16px;
         font-weight: 600;
         text-align: center;
     }
     .title>p{
         margin-top: 20px;
         font-size: 12px;
         font-weight: 600;
         color: #7A777B;
     }
     .boxL{
         padding: 0px 40px;
       
     }
     article>p{
         line-height: 24px;
         text-align: left;
         text-indent:30px
         
     }
     .application{
        width: 200px;
        height: 40px;
        background: #FDC023;
        margin: 40px  auto 20px;
        text-align: center;
        line-height: 40px;
        color: white;
        cursor: pointer;
     }
     table {
        text-align: center;
     }
     table tr{
        width: 100%;
        height: 30px;
     }
    table tr td{
        height: 30px;
    }
    
    table input{
        width: 100%;
        border: none;
        text-align: center;
    }
    .bg{
        background: #F4F6F8;
    }
  </style>
  