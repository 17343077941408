<template>
    <div class="top-banner">
        <div class="banner">
            <a-carousel autoplay>
                <div v-for="(item,index) in dataBan" :key="index" style="cursor: pointer;" @click="detailList(item.id)">
                    <img :src="baseUrl+item.bannerPic" alt="" style="width: 100%;cursor: pointer;">
                </div>
            </a-carousel>
        </div>
        <baywindow></baywindow>
        <div class="cont">
            <a-row :gutter="50">
                <a-col class="gutter-row" :span="12">
                    <div class="gutter-box">
                       
                      <!--   <div class="xueshu" :style="{display:type}">
                            <div style="display:flex;margin-left:150px; " @click="close()"><img class="smallimg" src="../assets/img/关闭.png" alt="" >关闭</div>
                            <img src="../assets/img/xuehsu.png" alt="" @click="fo()"></div> -->
                        <el-carousel :height="`358px`" :interval="5000" arrow="always">
                            <el-carousel-item v-for="(item,index) in dataM" :key="index" style="width: 100%;height: 100%;">
                                <img @click="detailList(item.id)" :src="baseUrl+item.articlePic" alt="" style="cursor: pointer;width: 100%;height: 100%;">
                                <span @click="detailList(item.id)" class="titleV">{{item.title}}</span>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="12">
                    <div class="gutter-box">
                        <div class="title">
                            <h3>协会动态</h3>
                            <div class="more" @click="dynamic()">
                                <span>更多</span>
                                <img src="../assets/img/more.png" alt="">
                            </div>
                        </div>
                        <ul class="articleList">
                            <li v-for="(item,index) in associate" :key="index" @click="detailList(item.id)">
                                <span class="span1">{{item.title}}</span>
                                <span class="span2">{{item.time}}</span>
                            </li>
                        </ul>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="12" style="padding-top: 40px;">
                    <div class="gutter-box">
                        <div class="title">
                            <h3>通知公告</h3>
                            <div class="more" @click="dynamic()">
                                <span>更多</span>
                                <img src="../assets/img/more.png" alt="">
                            </div>
                        </div>
                        <ul class="articleList">
                            <li v-for="(item,index) in notice" :key="index" @click="detailList(item.id)">
                                <p class="ddT"></p>
                                <span class="span1">{{item.title}}</span>
                                <span class="span2">{{item.time}}</span>
                            </li>
                        </ul>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="12" style="padding-top: 40px;">
                    <div class="gutter-box">
                        <div class="title">
                            <h3>学术交流</h3>
                            <div class="more" @click="learning()">
                                <span>更多</span>
                                <img src="../assets/img/more.png" alt="">
                            </div>
                        </div>
                        <ul class="articleList">
                            <li v-for="(item,index) in academic" :key="index" @click="detailList(item.id)">
                                <p class="ddT"></p>
                                <span class="span1">{{item.title}}</span>
                                <span class="span2">{{item.time}}</span>
                            </li>
                        </ul>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
import baywindow from "./Baywindow/baywindow";
    import { getUrl } from "../utils";

    export default {
    data() {
        return {
            type:'block',
            dataBan:[],
            dataM:[],
            associate:[],
            notice:[],
            academic:[],
            page:1,
            size:7,
            baseUrl:getUrl()
        };
    },
    components:{
        baywindow
    },
    mounted() {
        this.getData();
        this.getImg();
        this.getImgX();
        this.$cookie.set('typeA', 1)
    },
    methods: {
        //获取大的轮播图
        getImg(){
            this.$http({
                url: this.$http.adornUrl('/psychologyArticle/listHeadBanner'),
                method: 'get',
            }).then((res) => {
           
                if ( res.data.code == 200) {
                    this.dataBan = res.data.data;
                }else{
                    this.dataBan = [];
                }
            });
        },
        //获取小的轮播图
        getImgX(){
            this.$http({
                url: this.$http.adornUrl('/psychologyArticle/listSmallBanner'),
                method: 'get',
            }).then((res) => {
                if ( res.data.code == 200) {
                    this.dataM = res.data.data;
                }else{
                    this.dataM = [];
                }
            });
        },
        getData(){
            //获取协会动态
            this.$http({
                url: this.$http.adornUrl('/psychologyArticle/list'),
                method: 'get',
                params: this.$http.adornParams({
                    'page': this.page,
                    'size': this.size,
                    'psychologyColumnId':1
                })
            }).then((res) => {
                if ( res.data.code == 200) {
                    this.associate = res.data.data.content;
                }else{
                    this.associate = [];
                }
            });
            //通知公告
            this.$http({
                url: this.$http.adornUrl('/psychologyArticle/list'),
                method: 'get',
                params: this.$http.adornParams({
                    'page': this.page,
                    'size': this.size,
                    'psychologyColumnId':2
                })
            }).then((res) => {
                if ( res.data.code == 200) {
                    this.notice = res.data.data.content;
                }else{
                    this.notice = [];
                }
            });
            //学术交流
            this.$http({
                url: this.$http.adornUrl('/psychologyArticle/list'),
                method: 'get',
                params: this.$http.adornParams({
                    'page': this.page,
                    'size': this.size,
                    'psychologyColumnId':3
                })
            }).then((res) => {
                if ( res.data.code == 200) {
                    this.academic = res.data.data.content;
                }else{
                    this.academic = [];
                }
            });
        },

        learning(){
            this.$router.push({ name: "learning" });
        },
        detailList(id){
            this.$router.push({ path: "/detail/index", query: { id: id } });
        },
        dynamic(){
            this.$router.push({ name: "dynamic" });
        },
        fo(){
            this.$router.push({ name: "academic"})
        },
        close(){
            this.type = 'none'
        }
    }
};
</script>

<style scoped>
    .banner{
        height: 425px;
        width: 100%;
    }
    .cont{
        width: 1200px;
        margin: 50px auto;
        background: #ffffff;
        padding: 30px;
        position: relative;
    }
    .gutter-box{
        height: 358px;
        
    }
    .titleV{
        position: relative;
        top: -30px;
        left: 15px;
        color: #ffffff;
        display: inline-block;
        width: 300px;
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis;/*隐藏后添加省略号*/
        white-space: nowrap;/*强制不换行*/
        cursor: pointer;
    }
    .title{
        height: 40px;
        line-height: 40px;
        overflow: hidden;
        border-bottom: 1px solid rgba(196, 196, 196, 0.6);;
    }
    .title>h3{
        font-size: 22px;
        font-weight: 400;
        color: #0E60DB;
        display: inline-block;
        float: left;
        height: 40px;
        border-bottom: 2px solid #0E60DB;
    }
    .more{
        display: inline-block;
        float: right;
        cursor: pointer;
       
    }
    .articleList{
        height: 90%;
    }
    .articleList>li{
        height: 42px;
        line-height: 42px;
        cursor: pointer;
        border-bottom: 1px dashed #E5E5E5;
    }
    .span1{
        display: inline-block;
        width: 70%;
        text-align: left;
        float: left;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        margin-left: 5px;
    }
    .span2{
        display: inline-block;
        float: right;
    }
    .ddT{
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #0E60DB;
        border-radius: 50%;
        position: relative;
        top:16px;
        float: left;
    }
    .gutter-box >>> .el-carousel__indicators{
        display: none;
    }
    .gutter-box >>> .el-carousel__arrow{
        border-radius: 0;
        top: 94%;
        width: 24px;
        height: 24px;
        background-color: #5E9DFB;
    }
    .gutter-box >>> .el-carousel__arrow:hover{
        background-color: #0E60DB;
    }
    .gutter-box >>> .el-carousel__arrow--left{
        left: 85%;
    }
    .xueshu{
        position: absolute;
        margin-left: -233px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
       
    }
    .smallimg{
        width: 15px;
        height: 15px;
        margin-top: 3px;
    }
</style>
