<template>
    <div>
        <div class="LoginX">
            <p class="hy">欢迎注册</p>
            <el-form :model="formState" ref="formState" :rules="dataRule" class="demo-dynamic">
                <el-form-item
                        prop="username"
                >
                    <el-input prefix-icon="el-icon-user" v-model="formState.username" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item
                        prop="phone"
                >
                    <el-input prefix-icon="el-icon-mobile-phone" v-model="formState.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item
                        prop="validateCode">
                    <div class="yzm">
                        <el-input prefix-icon="el-icon-lock" v-model="formState.validateCode" placeholder="请输入验证码"></el-input>
                        <span class="ant-form-text" @click="verificate()" v-show="isSend">发送验证码</span>
                        <span class="ant-form-text" v-show="!isSend">{{sendmsg}}</span>
                    </div>
                </el-form-item>
                <el-form-item
                        prop="password"
                >
                    <el-input prefix-icon="el-icon-lock" type="password" v-model="formState.password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('formState')">注册</el-button>
                </el-form-item>
                <div class="login-form-wrap">
                    <p class="login-form-forgot">
                        <span>已有账号？</span>
                        <span style="color: #0EC2DB;" @click="Login()">请直接登录</span>
                    </p>
                </div>

            </el-form>
        </div>
        <img class="bmg" src="../../assets/img/login.png" alt="">
    </div>
</template>

<script>
    import { isMobile } from '../../utils/validate';
    export default {
        name: "login",  
        data(){
            return{
                formState :{
                    'username': '',
                    'password': '',
                    'phone': '',
                    'validateCode': '',
                },
                dataRule:{
                    username: [
                        { required: true, message: '请输入账号'}
                    ],
                    phone: [
                        { required: true, message: '请输入手机号'}
                    ],
                    password: [
                        { required: true, message: '请输入密码'}
                    ],
                    validateCode: [
                        { required: true, message: '请输入验证码'}
                    ],
                },
                value:'',
                isSend:true,
                sendmsg:'发送验证码',
            }
        }, methods: {
            //登录跳转
            Login(){
                this.$router.push({ name: "login" });
            },
            //获取验证码
            verificate(){
                if (!this.formState.phone) {
                    this.$message.error("请填写手机号后获取验证码！");
                } else if (isMobile(this.formState.phone) == false) {
                    this.$message.error("请填写正确的手机号后获取验证码！");
                } else {
                    this.$http({
                        url: this.$http.adornUrl('/auth/registerSendSms'),
                        method: 'get',
                        params: this.$http.adornParams({
                            'phone': this.formState.phone,
                        })
                    }).then((res) => {
                        if ( res.data.code == 200) {
                            this.$message.success(res.data.msg);
                            this.isSend = false;
                            let timer = 60;
                            this.sendmsg = timer + "s";
                            this.timeFun = setInterval(() => {
                                --timer;
                                this.sendmsg = timer + "s";
                                if (timer == 0) {
                                    this.isSend = true;
                                    this.sendmsg = "重新发送";
                                    clearInterval(this.timeFun);
                                }
                            }, 1000);
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            },
            //注册
            submitForm() {
                this.$refs['formState'].validate((valid) => {
                    if (valid) {
                        this.$http({
                            url: this.$http.adornUrl('/auth/register'),
                            method: 'post',
                            data: this.$http.adornData({
                                'username': this.formState.username,
                                'password': this.formState.password,
                                'phone': this.formState.phone,
                                'validateCode': this.formState.validateCode,
                            })
                        }).then((res) => {
                            if ( res.data.code == 200) {
                                this.$message.success('注册成功，请登录！');
                                this.$router.push({ name: "login" });
                            }else{
                                this.$message.error(res.data.msg);
                            }
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .LoginX{
        width: 443px;
        height: 430px;
        margin: 98px auto 150px;
        background: #FFFFFF;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        color: #333333;
        padding: 30px 5%;
        position: relative;
    }
    .bmg{
        width: 100%;
        margin-top: -570px;
    }
    .hy{
        height: 21px;
        line-height: 21px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        text-align: center;
    }
    .yzm{
        position: relative;
    }
    .yzm>div{
        display: inline-block;
        width: 60%!important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .yzm>span{
        width: 40%;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        border: 1px solid #0EC2DB;
        background: #0EC2DB;
        color: #ffffff;
        cursor: pointer;
        text-align: center;
        border-radius: 3px;
    }
    .el-button{
        width: 100%;
    }
    .login-form-wrap{
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
        color: #666666!important;
    }
</style>